<template>
   <div class="relative min-h-40 w-full pt-9 grid grid-cols-4 bg-white rounded flex gap-4 px-7
                py-2 mb-4 shadow-md justify-center items-center dark:bg-gray-800 overflow-hidden"
         :class="[ hidden ? 'hidden' : '' ]"
                >

        <XIcon @click="$emit('delete');" 
                class="absolute w-6 z-10 right-1 top-1 cursor-pointer transition-all transform hover:scale-90" />

        <div v-if="fileToBig || formatError" class="absolute w-full h-full border-2 border-red-800">
            <div class="w-full h-full flex justify-center  items-end bg-red-600 bg-opacity-10">
                <div class="relative py-5 w-full h-1/3 opacity-100 bg-gray-100 dark:bg-gray-300"> 
                    <p class="text-2xl text-red-800">{{ errorMsg }}</p>
                    <p class="text-sm text-red-800">File will not be saved</p>
                    <input @change="fileChange($event.target.name, $event.target.files)" 
                        ref="inp" type="file" class="hidden" />

                    <div @click="$refs.inp.click()" 
                         class="absolute flex items-center justify-center w-32 top-0 cursor-pointer
                         right-0 h-full text-md p-2 bg-red-900 rounded-xl border-8 border-gray-100
                         text-white dark:text-black dark:border-gray-300">
                        Change file
                    </div>
                </div>
            </div>
        </div>

        <div class="w-full col-span-1 h-32 min-h-full"><img :src="image.preview" class="object-cover w-full rounded  h-full min-h-full"/></div>
        <div class="col-span-3 grid grid-cols-2 gap-3">
            <SmallTextInput @update="$emit('update',idx,'title',$event)" class="col-span-1"  heading="Title" />

            <DateInput @update:value="$emit('update',idx,'date',$event)" class="col-span-1" heading="Date" />

            <SmallTextInput @update:value="$emit('update',idx,'location',$event)" class="col-span-1" heading="Location" />

            <SmallTextInput @update:value="$emit('update',idx,'photographer',$event)" class="col-span-1" heading="Photographer" />
        </div>
        <button class="col-span-4 border-2 flex items-center justify-between rounded-lg p-2"
                @click="captionOn=!captionOn">
            Caption 
            <ChevronDownIcon class="h-4 ml-1" :class="[ captionOn ? 'transform transition-all rotate-180' : '']" />
        </button>
        <slide-up-down v-model="captionOn" class="col-span-4" :duration="400">
            <LargeTextInput @update:value="$emit('update',idx,'caption', $event)" class="col-span-4 " heading="" />
        </slide-up-down>
    </div> 
</template>

<script>
import { ref, watch, computed } from 'vue'
import { ChevronDownIcon, XIcon } from '@heroicons/vue/outline/esm'
import SlideUpDown from 'vue3-slide-up-down'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import LargeTextInput from '@/components/inputs/LargeTextInput'
import DateInput from '@/components/inputs/DateInput'
export default {
    name: "MediaImageSlot",
     props: {
        image: Object,
        idx: Number,
    },
    components: { SmallTextInput, ChevronDownIcon, XIcon, DateInput, LargeTextInput, SlideUpDown },
    setup( props, { emit }) {
        const captionOn = ref(false);
        const fileToBig = ref();
        const formatError = ref();
        const hidden = ref(false);
        
        function updateImage(){
            fileToBig.value = props.image?.meta?.fileToBig ?? null;
            formatError.value = !props.image?.meta?.formatAllowed ?? null;
        }

        watch(props.image, () => {
            updateImage();
        })

        return { 
            captionOn,
            formatError,
            hidden,
            fileToBig,
            fileChange(_, files) {
                if (files.length < 1) return;
                emit('fileChange', files[0])
            },
            errorMsg: computed(() => formatError.value ? "Unsupported format" : "Image to large"),
        }
    }
}
</script>