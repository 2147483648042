<template>
  <modal
    :modalActive="editActive"
    :duration="400"
    @close="$emit('onClose', false)"
  >
    <div class="mb-9 p-4 rounded-lg bg-primary dark:bg-primaryDark text-gray-600 dark:text-gray-200">
      <p class="text-2xl text-left ml-2 font-medium mb-4">Edit Skills</p>
      <div class="w-full">
        <MultiFieldInput
          :suggestions="skillSuggestion"
          :elements="skills"
          @onChange="searchSkills"
          @addElement="addSkill"
          @removeElement="removeSkill"
        />

        <SaveCancelForm
          class="col-span-2 mt-4"
          @onSave="submitSkill"
          @onCancel="$emit('onClose', false)"
          justifyOrientation="end"
          :display="true"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/widgets/Modal";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import MultiFieldInput from "@/components/inputs/MultiFieldInput";
import { computed } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default {
  name: "OverviewSkillsEdit",
  components: {
    Modal,
    SaveCancelForm,
    MultiFieldInput,
  },
  data() {
    return {
      editActive: true,
      currentSkill: "",
    };
  },
  props: {
    skills: {
      type: Array,
      required: true,
    },
  },
  methods: {
    searchSkills(val) {
      this.currentSkill = val;
      this.$store.dispatch("fetchProfileSkills", val);
    },
    async submitSkill() {
      if (this.currentSkill != "") {
        await this.$store.dispatch("addUserSkill", this.currentSkill);
        await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
      }
      this.$emit("onClose", false);
    },
    async addSkill(title) {
      this.currentSkill = ''
      await this.$store.dispatch("addUserSkill", title);
      await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
    },
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    return {
      skillSuggestion: computed(() =>
        store.getters.getProfileSkillOptions.slice(0, 5)
      ),
      async removeSkill(title) {
        console.log(title);
        await store.dispatch("removeUserSkill", title);
        await store.dispatch("fetchProfile", store.getters.getId);
      },
    };
  },
};
</script>
