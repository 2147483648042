<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
         </div>
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
      <ProfileSidebar
        class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
        :subNav="true"
      />
    </div>
    <div
      class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
    >
  <div class="rounded-lg bg-white dark:bg-primaryDark dark:text-white px-5 sm:px-12 py-9">
    <div class="flex items-center gap-2">
      <font-awesome-icon :icon="['fas', 'folder-plus']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0  h-5 md:h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
       <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">Edit Medical Information</h1>
   </div>
    <div class="flex items-center dark:text-white mt-3">
      
      <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
    </div>
    <div id="inputList" class="" v-if="!isMobile">
      <div class=" justify-between col-span-full mt-14">
        <h2 class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Emergency Contacts</h2>
        <div class="md:flex col-span-full itens-center mt-2 mb-2">
        <p class="flex-1 flex col-span-full items-center text-sm">Please enter information for your emergency contacts. should they need to be contacted. </p>
        <button type="button" @click="editMedicalContact(null)" class="flex flex-row items-center mr-0 px-3 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Contact</button>
        </div>
      </div>
      
    <MedicalTable @onSelect="editMedicalContact" header="Emergency Contacts" :values=contacts :fields="['name', 'relationship', 'country', 'phoneNumber']" />
    <MedicalContactModal v-if="editMedicalContactOn"
                         :contact="medicalContact"
                         @onSave="saveMedicalContact"
                         @onCancel="cancelEditMedicalContact"
                         @onDelete="deleteMedicalContact"
                         @update:name="medicalContact.name=$event"
                         @update:relationship="medicalContact.relationship=$event"
                         @update:country="medicalContact.country=$event"
                         @update:phoneNumber="medicalContact.phoneNumber=$event"
    />
    </div>
    <app-accordion class="mt-8 custom_people_accordion" v-if="isMobile">
          <template v-slot:title>
            <div class="flex items-center">
              <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
                Emergency Contacts
              </p>
              
            </div>
          </template>
          <template v-slot:content>
            <div class="col-span-full itens-center mt-2 mb-2">
        <p class="flex-1 flex col-span-full items-center text-sm">Please enter information for your emergency contacts. should they need to be contacted. </p>
        <button type="button" @click="editMedicalContact(null)" class="flex flex-row items-center mr-0 px-3 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Contact</button>
        </div>
            <MedicalTable @onSelect="editMedicalContact" header="Emergency Contacts" :values=contacts :fields="['name', 'relationship', 'country', 'phoneNumber']" />
    <MedicalContactModal v-if="editMedicalContactOn"
                         :contact="medicalContact"
                         @onSave="saveMedicalContact"
                         @onCancel="cancelEditMedicalContact"
                         @onDelete="deleteMedicalContact"
                         @update:name="medicalContact.name=$event"
                         @update:relationship="medicalContact.relationship=$event"
                         @update:country="medicalContact.country=$event"
                         @update:phoneNumber="medicalContact.phoneNumber=$event"
    />
          </template>
        </app-accordion>
    
    
    <div id="inputList" class="" v-if="!isMobile">
      <div class=" justify-between col-span-full mt-10">
        <h2 class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Medical Condition</h2>
        <div class="flex col-span-full itens-center mt-2 mb-2">
        <p class="flex-1 flex col-span-full items-center text-sm">Please add medical conditions such as allergies and diseases.</p>
        <button type="button" @click="editMedicalCondition(null)" class="flex flex-row items-center mr-0 px-3 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Condition</button>
        </div>
    
      </div>
      
    <MedicalTable @onSelect="editMedicalCondition" header="Emergency Contacts" :values=conditions :fields="['name', 'severity', 'medication', 'doseSize']" />
    <MedicalConditionModal v-if="editMedicalConditionOn"
                         :contact="medicalCondition"
                         @onSave="saveMedicalCondition"
                         @onCancel="cancelEditMedicalCondition"
                         @onDelete="deleteMedicalCondition"
                         @update:name="medicalCondition.name=$event"
                         @update:severity="medicalCondition.severity=$event"
                         @update:medication="medicalCondition.medication=$event"
                         @update:doseSize="medicalCondition.doseSize=$event"
                         @update:frequencyOfUse="medicalCondition.frequencyOfUse=$event"            
    />
    </div>
    <app-accordion class="mt-8 custom_people_accordion" v-if="isMobile">
          <template v-slot:title>
            <div class="flex items-center">
              <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
                Medical Condition
              </p>
              
            </div>
            
          </template>
          <template v-slot:content>
            <div class=" col-span-full itens-center mt-2 mb-2">
        <p class="flex-1 flex col-span-full items-center text-sm">Please add medical conditions such as allergies and diseases.</p>
        <button type="button" @click="editMedicalCondition(null)" class="flex flex-row items-center mr-0 px-3 py-3  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Condition</button>
        </div>
            <MedicalTable @onSelect="editMedicalConditionOn" header="Emergency Contacts" :values=conditions :fields="['name', 'severity', 'medication', 'doseSize']" />
    <MedicalConditionModal v-if="editMedicalConditionOn"
                         :contact="medicalCondition"
                         @onSave="saveMedicalCondition"
                         @onCancel="cancelEditMedicalCondition"
                         @onDelete="deleteMedicalCondition"
                         @update:name="medicalCondition.name=$event"
                         @update:severity="medicalCondition.severity=$event"
                         @update:medication="medicalCondition.medication=$event"
                         @update:doseSize="medicalCondition.doseSize=$event"
                         @update:frequencyOfUse="medicalCondition.frequencyOfUse=$event"            
    />
          </template>
        </app-accordion>
    <div class="w-full flex justify-end mt-6">
      <transition name='fade' mode="out-in">
        <SaveCancelForm  />
      </transition>
    </div>
  </div>
  </div></div></div></main></div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onUpdated, reactive, ref} from "vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import MedicalTable from "@/components/profile/components/medicalTable/MedicalTable";
import MedicalContactModal from "@/components/profile/components/medicalTable/MedicalContactModal";
import MedicalConditionModal from "@/components/profile/components/medicalTable/MedicalConditionModal";
import registerEvents from '../../../../utils/DisableSearchBoxInInputs';
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import { createToaster } from "@meforma/vue-toaster";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";

// TODO Needs input verification
export default {
  name: "Medical",
  components: {MedicalContactModal,
    MedicalConditionModal,
    MedicalTable,
    SaveCancelForm, 
    AppAccordion,
    ExpandableBlockContent,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton
  },
  setup(){
    const store = useStore()
    const { width } = useBreakpoints();
    store.dispatch('fetchUserMedicalContacts')
    store.dispatch('fetchUserMedicalConditions')
    store.dispatch('fetchMedicalContactRelationshipTypes')
    const openMobile = ref(false);
      onUpdated(() => {
        setTimeout(registerEvents, 500);
      })
    let medicalContact = reactive({});
    let medicalCondition = reactive({});
    const editMedicalContactOn = ref(false);
    const editMedicalConditionOn = ref(false);

    function cancelEditMedicalContact(){
      delete medicalContact.name
      delete medicalContact.relationship
      delete medicalContact.country
      delete medicalContact.phoneNumber
      delete medicalContact.id
      editMedicalContactOn.value = false;
    }

    function cancelEditMedicalCondition(){
      delete medicalCondition.name
      delete medicalCondition.severity
      delete medicalCondition.medication
      delete medicalCondition.doseSize
      delete medicalCondition.frequencyOfUse
      delete medicalCondition.id
      editMedicalConditionOn.value = false;
    }
    return {
      openMobile,
      cancelEditMedicalContact,
      cancelEditMedicalCondition,
      medicalContact,
      medicalCondition,
      editMedicalContactOn,
      editMedicalConditionOn,
      contacts: computed(() => store.getters.getUserMedicalContacts),
      conditions:computed(() => store.getters.getUserMedicalConditions),
      isMobile: computed(() => width.value < 1024),
      editMedicalContact(contact){
        if (contact === null) {
          Object.assign(medicalContact, {
            name: "",
            relationship: "",
            country: "",
            phoneNumber: ""
          })
        } else {
          Object.assign(medicalContact, {
            name: contact.name,
            relationship: contact.relationship,
            country: contact.country,
            phoneNumber: contact.phoneNumber,
            id: contact.id
          })
        }
        editMedicalContactOn.value = true;
      },
      editMedicalCondition(condition){
        if (condition === null) {
          Object.assign(medicalCondition, {
            name: "",
            severity:"",
            medication:"",
            doseSize:"",
            frequencyOfUse:"",
          })
        } else {
          Object.assign(medicalCondition, {
            name: condition.name,
            severity: condition.severity,
            medication: condition.medication,
            doseSize: condition.doseSize,
            frequencyOfUse:condition.frequencyOfUse,
            id: condition.id
          })
        }
        editMedicalConditionOn.value = true;
      },
      async saveMedicalContact(){
        const toast = createToaster({position:"top-right"});
        // If editing pre-existing medical contact
        if (Object.prototype.hasOwnProperty.call(medicalContact, "id")) {
          await store.dispatch('editOrSaveMedicalContact', {
            name: medicalContact.name,
            relationship: medicalContact.relationship,
            country: medicalContact.country,
            phoneNumber: medicalContact.phoneNumber,
            id: medicalContact.id
          })
          toast.success(`Medical contact has been updated successfully`);
        }
        else {
          await store.dispatch('editOrSaveMedicalContact', {
            name: medicalContact.name,
            relationship: medicalContact.relationship,
            country: medicalContact.country,
            phoneNumber: medicalContact.phoneNumber,
            profileId: store.getters.getId
          })
          toast.success(`Medical contact has been created successfully`);
        }
        cancelEditMedicalContact();
      },
      async deleteMedicalContact(id){
        const toast = createToaster({position:"top-right"});
        await store.dispatch('deleteMedicalContact',id)
        cancelEditMedicalContact();
        toast.success(`Medical contact has been deleted successfully`);
      }
      ,async saveMedicalCondition(){
        // If editing pre-existing medical contact
        const toast = createToaster({position:"top-right"});
        if (Object.prototype.hasOwnProperty.call(medicalCondition, "id")) {
          await store.dispatch('updateMedicalContact', {
            id: medicalCondition.id,
            name: medicalCondition.name,
            severity: medicalCondition.severity,
            medication: medicalCondition.medication,
            doseSize: medicalCondition.doseSize,
            frequencyOfUse:medicalCondition.frequencyOfUse,
            
          })
          toast.success(`Medical contact has been updated successfully`);
        }
        else {
          await store.dispatch('SaveMedicalCondition', {
            name: medicalCondition.name,
            severity: medicalCondition.severity,
            medication: medicalCondition.medication,
            doseSize: medicalCondition.doseSize,
            frequencyOfUse:medicalCondition.frequencyOfUse,
            // profileId: store.getters.getId
          })
          toast.success(`Medical contact has been created successfully`);
        }
        store.dispatch('fetchUserMedicalConditions')
        cancelEditMedicalCondition();
      },
      async deleteMedicalCondition(id){
        const toast = createToaster({position:"top-right"});
        await store.dispatch('deleteMedicalCondition',id)
        cancelEditMedicalCondition();
        toast.success(`Medical condition has been deleted successfully`);
      }
    }
  },
  computed: {
      pageContent: function () {
        return 'Registering your medical information is intended for your safety in case of an emergency and will only be made available to your supervisors or managers, e.g. company manager or stage manager. Future functionality will allow you to see how your information is accessed and control it more thoroughly.'
      },
    },
}
</script>
