<template>
<div class="shadow-md bg-white rounded-lg py-1"  >
  <h4 class="text-lg text-black dark:text-white font-medium items-center pb-2 pl-4 border-b border-gray-200 text-gray-700 dark:text-white font-medium items-center py-2 pl-4 border-b border-gray-200 text-gray-700 dark:text-white font-medium items-center py-2 pl-4 border-b border-gray-200">Profile Information</h4>
  <ul class="relative edit-menu" >
    <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark "  :to="{ name: 'editProfileContact', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">
          <font-awesome-icon :icon="['far', 'address-book']"  class="text-grey-400 text-md dark:text-white dark:hover:text-black  font-normal text-black  flex  items-center py-1 px-0 h-4 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> 
          Contact</router-link>
    <router-link  active-class="text-grey-500 hover:bg-gray-50  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileSkill', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"><font-awesome-icon :icon="['fas', 'wrench']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Skills</router-link>
    <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfilePhysical', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"><font-awesome-icon :icon="['fas', 'person']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Physical</router-link>
    <router-link  active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark" :to="{ name: 'editProfileDiversity', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"><font-awesome-icon :icon="['fab', 'accessible-icon']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Diversity</router-link>
  <router-link  :to="{ name: 'editProfileMedical', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"><font-awesome-icon :icon="['fas', 'folder-plus']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Medical</router-link>
    <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfileTravel', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white"><font-awesome-icon :icon="['fas', 'plane-departure']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Travel</router-link>
   <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfileProgramme', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">
          <font-awesome-icon :icon="['fas', 'book-open']"  class="text-grey-400 text-md dark:text-white  font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> 
          Programme
          </router-link>
    <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editProfileAwards', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">  <font-awesome-icon :icon="['fas', 'trophy']"  class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Awards & nominations</router-link>
          <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editAgents', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">  <font-awesome-icon :icon="['fas', 'user-secret']"  class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Agents</router-link>
          <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editWorkEducation', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">  <font-awesome-icon :icon="['fas', 'laptop-file']"  class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Work & Education</router-link>  
          <router-link active-class="text-grey-500 hover:bg-white  bg-gray-100 dark:bg-primaryDark"  :to="{ name: 'editMeasurements', params: { id: id } }"
          class="pl-4 h-12 flex text-left justify-left gap-2 hover:text-gray-900 hover:bg-gray-50 hover:text-gray-900 px-3 py-2 flex items-center text-sm font-medium dark:text-white">  <font-awesome-icon :icon="['fas', 'weight-scale']"  class="text-grey-400 text-md dark:text-white dark-hover:text-black font-normal text-black  flex  items-center py-1 px-0 h-5 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" /> Measurements</router-link>
        </ul> 
</div>
</template>
<script>
import { useStore } from 'vuex'
import { computed, } from "vue";
export default {
  name: "ProfileSidebar",
  // components: { },
  setup(){
    const store = useStore()

    return {
  
      id: computed(() => store.getters.getId),
  
    }
  }
}
</script>
<style>
.dark-mode .edit-menu a:hover svg{
    color: #1E1E1E;
}
</style>