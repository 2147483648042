import {computed, reactive} from "vue";


export default function FormBuilder(fields){
    /** Builds a reactive form from computed properties that provides extra functionality.

        Input: { key: store.getters.<someGetter> }

        Every property is accessed through <key>.value

        methods:
            - getForm: Provides a regular key:value object with all form properties that have been edited
            - hasEditedValue: Returns true if a single value in the form has been edited
            - resetForm: Resets the form to its original state
     **/
    const form = reactive({
        getForm(){
            // Returns the form as a regular object with key:value pairs
            const res = {}
            for(let key in form){
                if(form[key].edited){
                    res[key] = form[key].value
                }
            }
            return res
        },
        hasEditedValue: computed(() => {
            // Returns true if a single value in the form has been edited. Otherwise returns false
            for(let key in form){
                if (typeof form[key] !== 'function' && form[key] !== undefined && form[key].edited) {
                    return true;
                }
            }
            return false;
        }),
        resetForm(){
            // Resets the original state of the form
            for(let key in form){
                if (typeof form[key] !== 'function' && form[key] !== undefined && form[key].edited && form[key].stateValue !== undefined) {
                    form[key].value = form[key].stateValue;
                }
            }
        },
        _buildForm(fields){
            // Build form from scratch
            Object.keys(fields).forEach(key => {
                form[key] = {
                    value: fields[key],
                    edited: computed(() => form[key].value !== fields[key]),
                    stateValue: computed(() => fields[key]),
                }
            })
        },
        rebuildForm(newFields){
            // Update field getters
            form._buildForm(newFields)
        }
    })

    form._buildForm(fields)
    return form;
}

/** Original Form **/
// const form = reactive({
//   height: {
//     value: store.getters.getUserHeight,
//     edited: computed(() => form.height.value !== store.getters.getUserHeight),
//     stateValue: computed(() => store.getters.getUserHeight)
//   },
//   eyeColour:{
//     value: store.getters.getUserEyeColour,
//     edited: computed(() => form.eyeColour.value !== store.getters.getUserEyeColour),
//     stateValue: computed(() => store.getters.getUserEyeColour)
//   },
//   weight: {
//     value: store.getters.getUserWeight,
//     edited: computed(() => form.weight.value !== store.getters.getUserWeight),
//     stateValue: computed(() => store.getters.getUserWeight)
//   },
//   piercings: {
//     value: store.getters.getUserPiercings,
//     edited: computed(() => form.piercings.value !== store.getters.getUserPiercings),
//     stateValue: computed(() => store.getters.getUserPiercings)
//   },
//   bodyType: {
//     value: store.getters.getUserBodyType,
//     edited: computed(() => form.bodyType.value !== store.getters.getUserBodyType),
//     stateValue: computed(() => store.getters.getUserBodyType)
//   },
//   tattoos: {
//     value: store.getters.getUserTattoos,
//     edited: computed(() => form.tattoos.value !== store.getters.getUserTattoos),
//     stateValue: computed(() => store.getters.getUserTattoos)
//   },
//   shoeSize: {
//     value: store.getters.getUserShoeSize,
//     edited: computed(() => form.shoeSize.value !== store.getters.getUserShoeSize),
//     stateValue: computed(() => store.getters.getUserShoeSize)
//   },
//   voiceType: {
//     value: store.getters.getUserVoiceType,
//     edited: computed(() => form.voiceType.value !== store.getters.getUserVoiceType),
//     stateValue: computed(() => store.getters.getUserVoiceType)
//   },
//   hairColour: {
//     value: store.getters.getUserHairColour,
//     edited: computed(() => form.hairColour.value !== store.getters.getUserHairColour),
//     stateValue: computed(() => store.getters.getUserHairColour)
//   },
//   vocalRangeLowest: {
//     value: store.getters.getUserVocalRangeLowest,
//     edited: computed(() => form.vocalRangeLowest.value !== store.getters.getUserVocalRangeLowest),
//     stateValue: computed(() => store.getters.getUserVocalRangeLowest)
//   },
//   hairLength: {
//     value: store.getters.getUserHairLength,
//     edited: computed(() => form.hairLength.value !== store.getters.getUserHairLength),
//     stateValue: computed(() => store.getters.getUserHairLength)
//   },
//   vocalRangeHighest: {
//     value: store.getters.getUserVocalRangeHighest,
//     edited: computed(() => form.vocalRangeHighest.value !== store.getters.getUserVocalRangeHighest),
//     stateValue: computed(() => store.getters.getUserVocalRangeHighest)
//   },
//   getForm: function(){
//     // Returns the form as a regular object with key:value pairs
//     const res = {}
//     for(let key in form){
//       if(form[key].edited){
//         res[key] = form[key].value
//       }
//     }
//     return res
//   },
//   hasEditedValue: computed(() => {
//     // Returns true if a single value in the form has been edited. Otherwise returns false
//     for(let key in form){
//       if (typeof form[key] !== 'function' && form[key] !== undefined && form[key].edited) {
//         return true
//       }
//     }
//     return false;
//   }),
//   resetForm(){
//     // Resets the original state of the form
//     for(let key in form){
//       if (typeof form[key] !== 'function' && form[key] !== undefined && form[key].edited && form[key].stateValue !== undefined) {
//         form[key].value = form[key].stateValue
//       }
//     }
//   }
// })