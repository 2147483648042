<template>
  <div>
  <teleport to="#root">
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" aria-hidden="true" @click="closeModal"></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
        overflow-hidden shadow-xl transform transition-all  sm:align-middle
       max-w-xl md:max-w-3xl lg:max-w-5xl sm:w-full sm:p-6 dark:bg-primaryDark dark:text-white    ">
          <div>
            <div class="mt-3 text-center sm:mt-5 " >
              <h3 class="text-lg leading-6 font-medium text-left text-gray-900 dark:text-gray-200mb-5 md:mb-10  " id="modal-title">
                Awards & Nominations
              </h3>
       <div class="md:flex items-center  gap-8" :class="(editMode)?'':''">
            <SmallTextInput :value="contact.production" @update:value="$emit('update:production', $event)" class="col-span-2 w-full" heading="Production"/>
            <SmallTextInput :value="contact.award" @update:value="$emit('update:award', $event)" class="mt-2 md:mt-0 col-span-2 w-full" heading="Award" />
            <SmallTextInput :value="contact.category" @update:value="$emit('update:category', $event)" class="mt-2 md:mt-0  col-span-2 w-full" heading="Category" />
            <SelectInput :value="formatted(contact.date)" @update:value="$emit('update:date', $event)" class="mt-2 md:mt-0 col-span-2 w-full" heading="Year" :options="years" />
           
              <div class="mt-2 md:mt-0  flex flex-col justify-between items-baseline md:items-center col-span-2">
                <div class="flex gap-1">
                  <label class="block text-sm font-medium text-gray-700 dark:text-white ">Won </label>
                </div>
                <div class="md:p-1.5"><input
                  @click="$emit('update:won', !contact.won)"
                :value="contact.won" 
                @update:value="$emit('update:won', $event)" 
                class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 place-self-center mx-2"
                type="checkbox"
                :checked="(contact.won?true:false)"
              />  
              </div>
            </div>
            <div v-if="editMode"  class="w-5 md:w-32   cursor-pointer md:pt-5 md:pb-5 edit_button text-gray-600 dark:text-gray-200 mt-2" @click="$emit('onDelete', contact.id)">
              <TrashIcon class="" />
            </div>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-6 flex justify-between  flex-wrap gap-10">
     
      <SaveCancelForm :display="true" justifyOrientation='end' @onSave="$emit('onSave')" @onCancel="$emit('onCancel')" />
    </div>
  </div>
</div>
</div>
</teleport>
</div>
</template>
<script>
import {useStore} from "vuex";
import {ref,computed} from "vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
import { TrashIcon } from '@heroicons/vue/outline';
export default {
  name: "AwardModal",
  components: {SelectInput, SmallTextInput, SaveCancelForm,TrashIcon},
  props: {
    contact: { type: Object, required: true },
    backdrop: {
      required: false,
      default: true,
    },
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("onCancel");
      }
    });
  },
  setup(props) {
    const store = useStore();
    const editMode = ref(props.contact.id !== undefined); // If contact has id prop then it can be deleted
    return {
      editMode,
      store,
      years: computed(() => Array.from(new Array(123), (x, i) => i + 1900).reverse()),
      formatted(year) {
        var start = new Date(year);
        let dates= start.getFullYear();
        return dates;
      }
      
    }
  } ,
   methods: {
    closeModal() {
      if (this.backdrop) this.$emit("onCancel");
    },
  },
}

</script>