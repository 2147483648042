<template>
  <div class="w-full items-start pb-6">
    <StatusOnlineIcon class="w-20 pl-10 pr-4 h-10 text-gray-500" v-if="iconHide" />
    <div class="flex flex-col w-12/12  gap-1">
      <div class="flex flex-col" v-for="(social, idx) in state.socials" :key="social.id">
          <div class="block sm:flex ">
           <SocialsInput 
                @change="checkSocial(social.network,social.id)"
                class=""
                @update:username="(val) => editSocial(val, idx, 'username')" 
                @update:network="(val) => editSocial(val, idx, 'network')"  
                :social="social"/>

            <div class="flex order-last">
              <TrashIcon 
                v-if="state.socials.length > 1" 
                @click="deleteSocial(idx)" 
                class="w-6 md:ml-2 cursor-pointer text-gray-500 dark:text-white"
                />
            </div>
          </div>
          <p 
            class="text-sm p-2 pl-0 text-gray-500"
            v-if="social.network === 'Snapchat' || social.network === 'Skype'">
          Please note: Due to technical reasons the {{social.network}} 
            icon on your Showdeck profile will not link to your 
            {{social.network}} profile, instead your username will appear as
            you hover over the icon. Your Snapchat username will 
            still be able to appear with your contact information and vCard as applicable.
          </p>
      </div>
    
    </div>
    <p @click="addSocial" class="cursor-pointer grey-slate-text pt-2">+ Add new line</p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import {computed, reactive} from "vue";
import { StatusOnlineIcon, TrashIcon} from '@heroicons/vue/outline/esm'
import SocialsInput from "@/components/inputs/SocialsInput";
import { createToaster } from "@meforma/vue-toaster";
export default {
  name: "SocialForm",
  components: {SocialsInput, StatusOnlineIcon, TrashIcon },
  props:{
    iconHide:{type:Boolean,default:true}
  },
  setup() {
    const store = useStore()
    const state = reactive({
      socials:computed(() => store.getters.getSocials)
    })

    if (state.socials.length === 0) store.commit('addNewSocial')

    return {
      state,
      socialTypes: computed(() => store.getters.getSocialTypes),
      socialLink:computed(() => store.getters.getSocials),
      addSocial() {
        store.commit('addNewSocial')
      },
      editSocial(value, idx, key){
        store.commit('editSocialByIdx', {value, key, idx})
      },
      async deleteSocial(idx){
        try {
          const social = store.getters.getSocialByIdx(idx)
          if(social.id) {
            await store.dispatch('deleteSocial', social.id)
          }
          store.commit('removeSocialByIdx', idx)
        } catch (e) {
          console.error(e)
        }
      },

    }
  },
  methods: {
    checkSocial(val,id){
      const toast = createToaster({position:"top-right"});
     
      let socials=this.socialLink;
      let checkCurrentVal=socials.filter(x=>x.network === val);

      if(checkCurrentVal.length !==1 && id !== undefined || checkCurrentVal.length !==1 && id === undefined)
      toast.error(`This social media already exists`);
      return false
     
    }
  },
}
</script>
