<template>

  <div class="w-full flex items-start pb-4 ">
    <LocationMarkerIcon v-show="iconHide" class="w-20 pr-4 pl-10 h-10 my-2 text-gray-500 dark:text-white"/>
    <div class="w-full flex flex-col ">

        <div class="w-full" v-for="(address, idx) in state.addresses" :key="address.id">
          <div class="items-center flex md:gap-y-0 gap-y-1.5 md:gap-x-10 flex-wrap md:flex-nowrap md:w-4/5">
          <div class="items-center w-full md:w-6/12" :ref="'addrForm-'+idx">
            <SmallTextInput class="w-full  " heading="Street address" :value="address.address" @update:value="address.address=$event"/>
          </div>
          <div class="items-center w-full md:w-6/12">
            <SmallTextInput class="w-full my-1" heading="Street address line 2"  :value="address.address2" @update:value="address.address2=$event" />
          </div>
       
        </div>
          <!-- <div class="flex items-center w-6/12">
          </div> -->
          <div class="items-center flex md:gap-y-0 gap-y-1.5 md:gap-x-10 flex-wrap md:flex-nowrap md:w-4/5">
          <div class="items-center w-full md:w-6/12">
            <SmallTextInput class=" my-1" heading="City" :value="address.city" @update:value="address.city=$event" />
            <!-- <SmallTextInput class="w-full lg:w-4/12 my-1" heading="Postal code" :value="address.postcode" @update:value="address.postcode=$event" /> -->
            <p class="text-red-400 mx-0" v-if="address.errors && address.errors.city.hasError">{{address.errors.city.message}}</p>
          </div>
          <div class="items-center w-full md:w-6/12">
            <!-- <SmallTextInput class="w-full lg:w-4/12 my-1" heading="City" :value="address.city" @update:value="address.city=$event" /> -->
            <SmallTextInput class="my-1" heading="Postal code" :value="address.postcode" @update:value="address.postcode=$event" />
            <!-- <p class="text-red-400 mx-2 " v-if="address.errors && address.errors.postcode.hasError">{{address.errors.postcode.message}}</p> -->
          </div>
       
          </div>
         <div class="md:flex items-end">
          <div class="items-center flex md:gap-y-0 gap-y-1.5 md:gap-x-10 flex-wrap md:flex-nowrap md:w-4/5">
            <div class="items-center w-full md:w-6/12">
              <SmallTextInput class="my-1" heading="State" :value="address.state" @update:value="address.state=$event" />
            </div>
            <div class="items-center w-full md:w-6/12">
      <!--        <SmallTextInput class="w-2/4 my-1" heading="Country" :value="address.country" @update:value="address.country=$event" />-->
              <SelectInput class="mt-1 my-1" heading="Country" :options="countries" :value="address.country" @update:value="address.country=$event"></SelectInput>
              <p class="text-red-400 mx-0" v-if="address.errors && address.errors.country.hasError"> {{address.errors.country.message}}</p>
            </div>
          </div>
          <div class=" order-last">
              <TrashIcon v-if="state.addresses.length > 1" @click="deleteAddress(idx)" class="w-6 h-12 md:ml-2 text-gray-500 cursor-pointer dark:text-white"/>
            </div>
          </div>  
        
        </div>
        <p @click="addAddress" class="cursor-pointer grey-slate-text mt-1 lg:mt-0 pt-2">+ Add new line</p>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive } from "vue";
import { LocationMarkerIcon, TrashIcon} from "@heroicons/vue/outline/esm";
import { required } from '@vuelidate/validators'
import { addressValidator } from '@/utils/VuelidateValidators'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import useVuelidate from "@vuelidate/core";
import SelectInput from "@/components/inputs/SelectInput";
export default {
  name: "AddressInfoForm",
  components: {SelectInput, SmallTextInput, LocationMarkerIcon, TrashIcon},
  props: {
    iconHide:{type:Boolean,default:true}
  },
  setup() {
    const store = useStore()
    const state = reactive({
      addresses: computed(() => store.getters.getAddresses)
    })

    if (state.addresses.length < 1) store.commit('addNewAddress')

    const rules = {
      addresses: {
        validAddress: addressValidator({
          country: { required },
          city: { required },
    
        })
      }
    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    return {
      state,
      v$,
      countries: computed(() => store.getters.getCountries),
      addAddress() { store.commit('addNewAddress')},
      async deleteAddress(idx){
        try{
          const address = store.getters.getAddressByIdx(idx)
          if(address.id) {
            await store.dispatch('deleteAddress', address.id)
          }
          store.commit('removeAddressByIdx', idx)

        } catch (e) {
          console.error(e)
        }
      }
    }
  },
}
</script>
