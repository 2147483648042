<template>
  <div>
  <teleport to="#root">
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity" aria-hidden="true" @click="closeModal"></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
        overflow-hidden shadow-xl transform transition-all  sm:align-middle
       max-w-xl md:max-w-3xl lg:max-w-5xl sm:w-full sm:p-6 dark:bg-primaryDark dark:text-white  ">
          <div>
            <div class="mt-3 text-center sm:mt-5 " >
              <h3 class="text-lg leading-6 font-medium text-left text-gray-900 dark:text-gray-200 mb-5 md:mb-10 " id="modal-title">
                Add allergy / Condition
              </h3>
               <div class="md:flex items-center  gap-8">
                <SmallTextInput :value="contact.name" @update:value="$emit('update:name', $event)"  heading="Name" class="col-span-2 w-full"/>
                <SelectInput :value="contact.severity" @update:value="$emit('update:severity', $event)" heading="Severity" :options="severityTypes" class="mt-2 md:mt-0 col-span-2 w-full" />
                <SmallTextInput :value="contact.medication" @update:value="$emit('update:medication', $event)" heading="Medication" class="mt-2 md:mt-0 col-span-2 w-full" />
                <SmallTextInput :value="contact.doseSize" @update:value="$emit('update:doseSize', $event)" heading="Dose size" class="mt-2 md:mt-0 col-span-2 w-full" />
                <SmallTextInput :value="contact.frequencyOfUse" @update:value="$emit('update:frequencyOfUse', $event)" heading="Frequency of use" class="mt-2 md:mt-0 col-span-2 w-full" />
                <div v-if="editMode"  class="w-5 md:w-32   cursor-pointer md:pt-5 md:pb-5 edit_button text-gray-600 dark:text-gray-200 mt-2" @click="$emit('onDelete', contact.id)">
                  <TrashIcon class="w-5" />
                 </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 flex justify-between  flex-wrap gap-10">
           
            <SaveCancelForm :display="true" justifyOrientation='end' @onSave="$emit('onSave')" @onCancel="$emit('onCancel')" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
  </div>
</template>
<script>
import {ref} from "vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
import { TrashIcon } from '@heroicons/vue/outline';
export default {
  name: "MedicalConditionModal",
  components: {SelectInput, SmallTextInput, SaveCancelForm, TrashIcon},
  props: {
    contact: { type: Object, required: true },
    backdrop: {
      required: false,
      default: true,
    },
   
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("onCancel");
      }
    });
  },
  setup(props) {  
     const editMode = ref(props.contact.id !== undefined); // If contact has id prop then it can be deleted // const editMode = ref(props.contact.id != undefined); // If contact has id prop then it can be deleted
    return {
      editMode,
      severityTypes:["MILD","MODERATE","SEVERE"],
    }
  },
  methods: {
    closeModal() {
      if (this.backdrop) this.$emit("onCancel");
    },
  },
}
</script>