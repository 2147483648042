<template>
  <modal
    :modalActive="editActive"
    :duration="400"
    version="3"
    @close="$emit('onClose', false)"
  >
    <div id="education" class="no-scroll-bar rounded-lg bg-primary dark:bg-primaryDark text-gray-600 dark:text-gray-200 p-5 md:p-12 overflow-y-scroll overflow-hidden h-auto max-h-100 lg:max-h-auto">
      <div class="flex mb-4 items-center">
        <AcademicCapIcon class="w-8 mr-2" />
        <p class="text-2xl text-left ml-2 font-medium">Edit Education</p>
      </div>
      <EducationForm
        @onSubmit="pushNewEducations"
        @onDelete="deleteEducation"
        :educations="localEducations"
        :submit="submit"
      />
      <SaveCancelForm
        class="col-span-2"
        @onSave="submitEducations"
        @onCancel="$emit('onClose', false)"
        justifyOrientation="end"
        :display="true"
      />
    </div>
  </modal>
</template>

<script>
import { AcademicCapIcon } from "@heroicons/vue/outline/esm";
import Modal from "../../../widgets/Modal";
import EducationForm from "../../../forms/EducationForm";
import SaveCancelForm from "../../../forms/SaveCancelForm";
export default {
  name: "EducationEdit",
  components: {
    Modal,
    AcademicCapIcon,
    EducationForm,
    SaveCancelForm,
  },
  data() {
    return {
      localEducations: this.educations,
      editActive: true,
      submit: false,
    };
  },
  props: {
    educations: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async pushNewEducations(payload) {
      this.submit = true;
      payload.map((exp) => {
        this.localEducations.push(exp);
      });
      await this.submitEducations();
    },
    async submitEducations() {
      this.submit = true;
      this.localEducations.map(async (edu) => {
        if (edu.id === undefined || edu.id === null) {
          console.log(edu);
          await this.$store.dispatch("createEducation", {
            ...edu,
            profileId: this.$store.getters.getId,
          });
        } else {
          await this.$store.dispatch("editEducation", edu);
        }
      });
      await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
      this.editActive = false;
      this.$emit("onClose", false);
    },
    async deleteEducation(id) {
      const result = confirm("Are you sure you want to delete?");
      if (result) {
        await this.$store.dispatch("editEducation", { id, delete: true });
        await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
        this.$emit("onClose", false);
      }
    },
  },
};
</script>
