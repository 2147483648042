<template>
    <div class="mb-8">
      <main>
        <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
      </div>
        <div
          class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
        >
          <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
            <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
        <ProfileSidebar
          class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
          :subNav="true"
        />
      </div>
      <div
        class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
      >
    <div class="rounded-lg bg-white dark:bg-primaryDark dark:text-white px-5 sm:px-12 py-9">
        <div class="flex items-center gap-2">
      <font-awesome-icon :icon="['fas', 'book-open']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
      
        <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">Programme </h1>
        
        </div>
        <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
             
        <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
      </div>
        
        <div class=" justify-between col-span-full mt-14" v-if="!isMobile">
            <LoadingSpinner v-if="isLoading" />
            <div class="items-center justify-between xs:flex col-span-full itens-center mt-2 mb-2">
                <h2 class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Headshots</h2>
               <button type="button"  @click="$refs.file.click()" class="flex float-right xs:float-unset flex-row items-center mr-0 px-3  py-2.5  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Headshots</button>
            </div>
        </div>
        <div class="py-2 mb-2" v-if="!isMobile">
            <div class="w-full grid-cols-2 grid sm:grid-cols-3 md:grid-cols-5 gap-10">
            <div
                v-for="img of album"
                :key="img.id"
                class="col-span-1 relative object-cover h-full"
            >
                <img
                    class="relative object-cover w-full h-auto aspect-square object-cover"
                    :src="img.image"
                    
                />
                <div class="absolute object-cover top-0 flex justify-end  w-full" >
                    <div class="flex gap-1 text-white bg-custom-grey p-1 dark:text-white" >
                        <PencilIcon    class="w-4 cursor-pointer" />
                        <TrashIcon   @click="onDelete(img.uuid,'Headshots')" class="w-4 cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>
        <input @change="fileChange($event.target.name, $event.target.files)"
               name="file-input" ref="file" type="file" class="hidden" multiple/></div>

        <!--------Mobile view Headshots -------->
        <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p class="whitespace-nowrap pr-2 mt-0 font-semibold text-lg">
                      Headshots
                    </p>
                    <!-- <div class="h-px w-full bg-gray-400"></div> -->
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mt-2">
                    <div class="items-center justify-between xs:flex col-span-full itens-center mt-2 mb-2">
                <h2 class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Headshots</h2>
               <button type="button"  @click="$refs.file.click()" class="flex float-right xs:float-unset flex-row items-center mr-2 px-3 pr-5 py-2.5  shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer">+ Add Headshots</button>
            </div>
                    <div class="py-2 mb-2" >
            <div class="w-full grid-cols-2 grid sm:grid-cols-3 md:grid-cols-5 gap-10">
            <div
                v-for="img of album"
                :key="img.id"
                class="col-span-1 relative object-cover h-full"
            >
                <img
                    class="relative object-cover w-full h-auto aspect-square object-cover"
                    :src="img.image"
                    
                />
                <div class="absolute object-cover top-0 flex justify-end  w-full" >
                    <div class="flex gap-1 text-white bg-custom-grey p-1 dark:text-white" >
                        <PencilIcon    class="w-4 cursor-pointer" />
                        <TrashIcon   @click="onDelete(img.uuid,'Headshots')" class="w-4 cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>
        <input @change="fileChange($event.target.name, $event.target.files)"
               name="file-input" ref="file" type="file" class="hidden" multiple/></div>

       
                  </div>
                </template>
              </app-accordion>
  <!--------End Mobile view Headshots -------->

        <div class=" justify-between col-span-full mt-14" v-if="!isMobile">

          <div class="flex col-span-full itens-center mt-2 mb-2">
              <h2 class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Bios</h2>
          </div>
        </div> 
        <div class="py-2" v-if="!isMobile"> 
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  50 words </p>
        
        <LargeTextInput
            :value="state.version1"
            @update:value="state.version1=$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
        <div class="py-2" v-if="!isMobile">
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  150 words </p>
        <LargeTextInput
            :value="state.version2"
            @update:value="state.version2=$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
        <div class="py-2" v-if="!isMobile">
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  300 words </p>
        <LargeTextInput
            :value="state.version3"
            @update:value="state.version3 =$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
  <!--------Start Mobile view Bios -------->
        <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p class="whitespace-nowrap pr-2 mt-0 font-semibold text-lg">
                      Bios
                    </p>
                    <!-- <div class="h-px w-full bg-gray-400"></div> -->
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mt-2">
                    <div class="py-2" v-if="isMobile"> 
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  50 words </p>
        
        <LargeTextInput
            :value="state.version1"
            @update:value="state.version1=$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
        <div class="py-2" v-if="isMobile">
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  150 words </p>
        <LargeTextInput
            :value="state.version2"
            @update:value="state.version2=$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
        <div class="py-2" v-if="isMobile">
        <p class="flex-1 flex font-medium icon-color col-span-full items-center text-sm mt-9 dark:text-white">Max  300 words </p>
        <LargeTextInput
            :value="state.version3"
            @update:value="state.version3 =$event"
            :counting="true"
            rows="5"
            @click="edited=true"
        />
        </div>
                  </div>
                </template>
              </app-accordion>
  <!--------End Mobile view Bios -------->
        <div class="w-full py-5">
            <SaveCancelForm
                 version="2"
                :display="true"
                justify-orientation="end"
                @onCancel="onCancel"
                @onSave="onSave"
            />
        </div>
    </div>
    </div>
    </div></div></main></div>
</template>

<script>
import { computed, ref,reactive } from 'vue'
import { useStore } from 'vuex'
import LargeTextInput from "@/components/inputs/LargeTextInput";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import { PhotoAlbumNames } from '@/constants'
import { createToaster } from "@meforma/vue-toaster";
import { PencilIcon,TrashIcon } from "@heroicons/vue/outline/esm"
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

export default {
    name: "ProgrammeBio",
    components:{
        LargeTextInput,
        SaveCancelForm,
        ExpandableBlockContent,
        LoadingSpinner,
        TrashIcon,
        PencilIcon,
        ProfileSidebar,
        ProfileHeader,
        EditProfileHeaderButton,
        AppAccordion
    },
      setup() {
        const store = useStore()
        const version = ref(1)
        const edited = ref(false)
        const { width } = useBreakpoints();
        const openMobile = ref(false);
       
        store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)

        const state = reactive({
            version1:store.getters.getUserProgrammeBiog.version1,
            version2:store.getters.getUserProgrammeBiog.version2,
            version3:store.getters.getUserProgrammeBiog.version3,
            version4:store.getters.getUserProgrammeBiog.version4,
            });

        return {
            openMobile,
            version,
            edited,
            state,
            biogs:computed(() => store.getters.getUserProgrammeBiog),
            isMobile: computed(() => width.value < 1024),
            mutateValue: data => {
                edited.value = true
                store.commit("setSingleUserProgrammeBiog", {version: version.value, data})
            },
            onSave: () => {
                console.log(state)
                const toast = createToaster({position:"top-right"});
               store.dispatch("editUserProgrammeBiog", {"version1":state.version1,"version2":state.version2,"version3":state.version3})
                edited.value = false
                toast.success(`Bio has been updated successfully`);
            },
            onCancel:() => {
                store.dispatch("fetchUserProgrammeBiog")
                edited.value = false
            },
      
            isLoading: computed(() => store.getters.isLoading),
            album: computed(() => store.getters.getUserAlbum(PhotoAlbumNames.Headshots)),
            async fileChange(_, files) {
                const toast = createToaster({position:"top-right"});
                await store.dispatch("createMediaImage",
                    {file: files[0],
                             profileId: store.getters.getId,
                             title:Math.random().toString(36).slice(2),
                             album: "Headshots"} )

                await store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)
                toast.success(`Headshots images has been added successfully`);
            },
            async onDelete(uuid,albums) {
                const toast = createToaster({position:"top-right"});
                await store.dispatch("deleteMediaImage",{uuid,albums})
                await store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)
                toast.success(`Headshots images has been deleted successfully`);
            }
        }
    },
    computed: {
      pageContent: function () {
        return 'Registering your programme information allows for it to be collated with ease. Producers, marketing, company manager or others responsible for creating the programme will be able to request it '
      },
    },
}
</script>