<template>
   <div class="mb-8">
    <main>
    
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
         </div>
        <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7">
  <div class="grid grid-cols-12 gap-3 mt-3  lg:px-0 lg:mx-10">
  
    <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
    
      <ProfileSidebar
        class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
        :subNav="true"
      />
   
    </div>
    
    <div
      class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
    >



  <div
    class="bg-white rounded-lg dark:bg-primaryDark dark:text-gray-200 px-5 sm:px-12 py-9 general"
    ref="top"
  >
 
    <div class=""> 
    
      <div class="flex items-center gap-2">
      <font-awesome-icon :icon="['far', 'address-book']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0 h-5 md:h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
      <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">Contact Information</h1>
      </div>
      <div class="flex flex-row my-2.5 items-start dark:text-white md:mt-6 md:mb-4">
        <!-- <ExclamationCircleIcon class=" w-10 my-1" /> -->
      
        <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
      </div>
      <UserInfoForm class="bg-white dark:bg-primaryDark" />
      <div class="mt-0 lg:mt-4 bg-white dark:bg-primaryDark grid-cols-x-contact">
        <div v-if="!isMobile">
          <div class="flex items-center">
            <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
              Contact information
            </p>
       
          </div>
          <ContactInfoDynamicForm
            @add-new-row="addNewPhoneRow"
            @delete-row="onPhoneDelete"
            @onInput="onPhoneChange"
            :items="state.phoneNumbers"
            :keys="phoneKeys"
            :errors="v$.phoneNumbers.$errors"
            :labels="['Phone number', 'Phone label']"
            title="Phone Number"
          >
          </ContactInfoDynamicForm>
          <ContactInfoDynamicForm
            @add-new-row="addNewEmailRow"
            @delete-row="onEmailDelete"
            @onInput="onEmailChange"
            :items="state.emails"
            :keys="emailKeys"
            :errors="v$.emails.$errors"
            :labels="['Email address', 'Email label']"
            title="Email"
          >
          </ContactInfoDynamicForm>
      
          <ContactInfoDynamicForm
            @add-new-row="addNewWebsiteRow"
            @delete-row="onWebsiteDelete"
            @onInput="onWebsiteChange"
            :items="state.websites"
            :keys="websiteKeys"
            :errors="v$.websites.$errors"
            :labels="['Website address', 'Website label']"
            title="Website"
          >
            <!-- <GlobeAltIcon class="w-20 pl-10 transform text-gray-500 pr-4 px-5 dark:text-white" /> -->
          </ContactInfoDynamicForm>
        </div>
        <app-accordion class="mt-8 custom_people_accordion" v-if="isMobile">
          <template v-slot:title>
            <div class="flex items-center">
              <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">
                Contact information
              </p>
              
            </div>
          </template>
          <template v-slot:content>
            <div class="flex gap-2 md:hidden mt-2 mb-2 font-medium text-gray-700 dark:text-white">
              <p class="text-sm font-medium text-gray-700 dark:text-white font-sm ">Phone number</p>
              <!-- <QuestionMarkCircleIcon class="w-4" /> -->
            </div>
            <ContactInfoDynamicForm
              @add-new-row="addNewPhoneRow"
              @delete-row="onPhoneDelete"
              @onInput="onPhoneChange"
              :items="state.phoneNumbers"       
              :keys="phoneKeys"
              :errors="v$.phoneNumbers.$errors"
              :labels="['Phone number', 'Phone label']"
              title="Phone Number"
              class=""
            >
              <!-- <PhoneIcon class="w-20 pl-10 transform text-gray-500 pr-4 px-5 dark:text-white" /> -->
            </ContactInfoDynamicForm>
            <div class="flex gap-2 md:hidden mt-2 mb-2 font-medium text-gray-700 dark:text-white">
              <p class="mx-0 font-sm ">Email addresses</p>
              <!-- <QuestionMarkCircleIcon class="w-4" /> -->
            </div>
            <ContactInfoDynamicForm
              @add-new-row="addNewEmailRow"
              @delete-row="onEmailDelete"
              @onInput="onEmailChange"
              :items="state.emails"
              :keys="emailKeys"
              :errors="v$.emails.$errors"
              :labels="['Email address', 'Email label']"
              title="Email"
            >
              <!-- <MailIcon class="w-20 pl-10 transform text-gray-500 pr-4 px-5 dark:text-white" /> -->
            </ContactInfoDynamicForm>
            <div class="flex gap-2 md:hidden mt-2 mb-2 font-medium text-gray-700 dark:text-white">
            <p class="mx-0 font-sm ">Web addresses</p>
            <!-- <QuestionMarkCircleIcon class="w-4" /> -->
        </div>
            <ContactInfoDynamicForm
              @add-new-row="addNewWebsiteRow"
              @delete-row="onWebsiteDelete"
              @onInput="onWebsiteChange"
              :items="state.websites"
              :keys="websiteKeys"
              :errors="v$.websites.$errors"
              :labels="['Website address', 'Website label']"
              title="Website"
            >
              <!-- <GlobeAltIcon class="w-20 pl-10 transform text-gray-500 pr-4 px-5 dark:text-white" /> -->
            </ContactInfoDynamicForm>
          </template>
        </app-accordion>
      </div>
      
      <div class=" gap-2">
        <div v-if="!isMobile">
          <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Addresses</p>
          <AddressInfoForm class="mt-2 bg-white dark:bg-primaryDark" :iconHide="false" />
      </div>
      <app-accordion class="mt-8" v-if="isMobile">
        <template v-slot:title>
          <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Addresses</p>
          
        </template>
        <template v-slot:content>
          <AddressInfoForm class="mt-2 bg-white dark:bg-primaryDark" :iconHide="false" />
        </template>
      </app-accordion>
      </div>
      <!-- <div class="flex items-center mb-4">
        <p class=" whitespace-nowrap pl-10 pr-2 py-2 font-semibold"></p>
        <div id="line" class="h-px w-full mr-12 bg-gray-400 "></div>
      </div> -->
      <div class="social_accordian">
          <div v-if="!isMobile">
            <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Social Media Handles</p>
            <SocialForm class="mt-2 bg-white dark:bg-primaryDark" :iconHide="false"/>
          </div>
          <app-accordion class="mt-8 " v-if="isMobile">
            <template v-slot:title>
              <p class="whitespace-nowrap pr-2 font-semibold text-lg dark:text-white">Social Media Handles</p>
            </template>
            <template v-slot:content>
              <SocialForm class="mt-2 bg-white dark:bg-primaryDark" :iconHide="false"/>
            </template>
        </app-accordion>
      </div>
   
      <div class="flex justify-end w-full px-4">
        <SaveCancelForm
               version="2"
               :display="true"
               justify-orientation="end"
                @onCancel="onCancel"
                @onSave="onSave"
            />
      </div>
      <div class="py-1"></div>
    </div>
  </div>
  </div>
  </div></div>
  </main>
  </div>
  
</template>

<script>
import { useStore } from "vuex";
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
// import {  GlobeAltIcon, MailIcon} from "@heroicons/vue/outline/esm";
// import { QuestionMarkCircleIcon } from "@heroicons/vue/solid/esm";
import { websiteValidator, emailValidator } from "@/utils/VuelidateValidators";
// import { notify } from "@kyvg/vue3-notification";
import { email, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import UserInfoForm from "@/components/forms/UserInfoForm";
import ContactInfoDynamicForm from "@/components/forms/ContactInfoDynamicForm";
import AddressInfoForm from "@/components/forms/AddressInfoForm";
import SocialForm from "@/components/forms/SocialForm";
// import ToggleSwitch from "@/components/inputs/ToggleSwitch";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import { createToaster } from "@meforma/vue-toaster";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
export default {
  name: "Contact",
  components: {
    SocialForm,
    AddressInfoForm,
    UserInfoForm,
    ContactInfoDynamicForm,
    // QuestionMarkCircleIcon,
    // ToggleSwitch,
    AppAccordion,
    ExpandableBlockContent,
    SaveCancelForm,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { width } = useBreakpoints();
    const professionalInfoActive = ref(false);
    const editProfileState = computed(() => store.getters.getProfileEditState);
    const openMobile = ref(false);
    const enableVcard = ref(false);
    enableVcard.value = store.getters.getEnableVcard;
    const state = reactive({
      emails: computed(() => store.getters.getEmails),
      websites: computed(() => store.getters.getUserWebsites),
      phoneNumbers: computed(() => store.getters.getPhoneNumbers),
    });

    const rules = {
      emails: {
        hasMail: emailValidator({
          email: { email },
        }),
      },
      websites: {
        hasUrl: websiteValidator({
          url: { required },
        }),
      },
      phoneNumbers: {},
    };
    const v$ = useVuelidate(rules, state, { $lazy: true });
    return {
      v$,
      state,
      router,
      store,
      openMobile,
      professionalInfoActive,
      enableVcard,
      editProfileState,
      basicInfo: computed(() => store.getters.basicInfo),
      isMobile: computed(() => width.value < 1024),
      onVcardClick() {
        enableVcard.value = !enableVcard.value;
        store.dispatch("editProfileOverviewSettings", { enableVcard: enableVcard.value });
        store.commit("setEnableVcard", enableVcard.value);
      },

      // ==========JOB SUGGESTIONS==========
      searchJobs(val) {
        store.dispatch("fetchProfileJobs", val);
      },
      jobSuggestion: computed(() => store.getters.getProfileJobs.slice(0, 5)),
      // ==========SKILL SUGGESTIONS==========
      searchSkills(val) {
        store.dispatch("fetchProfileSkills", val);
      },
      skillSuggestion: computed(() => store.getters.getProfileSkillOptions.slice(0, 5)),
      // ==========SKILLS==========
      skills: computed(() => store.getters.getUserSkills),
      addSkill(title) {
        store.dispatch("addUserSkill", title);
      },
      removeSkill(title) {
        store.dispatch("removeUserSkill", title);
      },
      // ==========SECONDARY JOBS==========
      secondaryJobs: computed(() =>
        store.getters.getUserSecondaryJobs.map((job) => job.title)
      ),
      addSecondaryJob(title) {
        store.dispatch("createSecondaryJob", title);
      },
      deleteSecondaryJob(title) {
        store.dispatch("deleteSecondaryJob", title);
      },
      // ==========WEBSITE==========
      addNewWebsiteRow: () => store.commit("addNewWebsite"),
      async onWebsiteChange(value, key, idx) {
        store.commit("editWebsiteByIdx", { value, key, idx });
      },
      async onWebsiteDelete(idx) {
        const result = confirm("Are you sure you want to delete?");
        try {
          const website = store.getters.getWebsiteByIdx(idx);
          if (website.id && result) {
            await store.dispatch("deleteWebsite", website.id);
          }
          store.commit("removeWebsiteByIdx", idx);
        } catch (e) {
          console.error(e);
        }
      },
      websiteKeys: computed(() => ["url", "title"]),

      // ==========EMAIL==========
      addNewEmailRow: () => store.commit("addNewEmail"),
      async onEmailChange(value, key, idx) {
        store.commit("editEmailByIdx", { value, key, idx });
      },
      async onEmailDelete(idx) {
        const result = confirm("Are you sure you want to delete?");
        try {
          const email = store.getters.getEmailByIdx(idx);
          if (email.id && result) {
            await store.dispatch("deleteEmail", email.id);
          }
          store.commit("removeEmailByIdx", idx);
        } catch (e) {
          console.error(e);
        }
      },
      emailKeys: computed(() => ["email", "label"]),

      // ==========PHONE==========
      addNewPhoneRow: () => store.commit("addNewPhoneNumber"),
      async onPhoneChange(value, key, idx) {
        store.commit("editPhoneByIdx", { value, key, idx });
      },
      async onPhoneDelete(idx) {
        const result = confirm("Are you sure you want to delete?");
        try {
          const phone = store.getters.getPhoneByIdx(idx);
          if (phone.id && result) {
            await store.dispatch("deletePhoneNumber", phone.id);
          }
          store.commit("removePhoneByIdx", idx);
        } catch (e) {
          console.error(e);
        }
      },
      phoneKeys: computed(() => ["phoneNumber", "label"]),

      // ==========ADDRESS==========
      addNewAddressRow: () => store.commit("addNewAddress"),
      async onAddressChange(value, key, idx) {
        console.log("TO BE IMPLEMENTED", value, key, idx);
      },
      async onAddressDelete(idx) {
        console.log("TO BE IMPLEMENTED", idx);
      },
      addressItems: computed(() => store.getters.addresses),
      addressKeys: computed(() =>
        store.getters.addresses.length > 0
          ? Object.keys(store.getters.addresses[0]).filter((key) => key !== "id")
          : []
      ),
    };
  },
  methods: {
    async onSave() {
      const toast = createToaster({position:"top-right"});
      this.v$.$touch();
      if (this.v$.$error) {
        window.scrollTo(0, this.$refs.top.offsetTop);
        toast.error(`Some required fields were not provided`);
        return false
      }
      this.store.commit("removeEmailErrorProperty");
      this.store.commit("removeWebsiteErrorProperty");
      this.store.commit("removeAddressErrorProperty");
      // await this.store.dispatch("saveLanguages");
      await this.store.dispatch("saveBasicInfo");
      await this.store.dispatch("fetchProfileHeader", this.store.getters.getId);
      // notify({
      //   text: "General info saved successfully 🎉",
      //   type: "success",
      //   duration: 2000,
      // });
      toast.success(`General info has been updated successfully`);
      //window.location.reload();
    },
    async onCancel(){
      return false
    }
  },
  computed: {
      pageContent: function () {
        return 'Fill in all your basic information, such as name, stage name, contact info etc., in the same way you would in a mobile phone contacts directory. These will be displayed on contacts sheets, in your header and your vCard (a downloadable file for saving in your phone’s or computer’s contact directory). Future functionality will allow more detailed control of what information is displayed where and to whom (e.g. all users, collaborators, managers or select users).'
      },
    },
};
</script>
