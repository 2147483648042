<template>
  <div class="mb-8">
    <div class="grid grid-cols-10">
      <div class="w-full grid col-span-10 bg-white pt-8 dark:bg-primaryDark">
        <ProfileHeader :editMode="true">
        <ProfileNavbar version="2" 
          class="dark:text-white col-start-0 relative col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-7" 
          @change="changeComponent" :navigation="navigation" :isIconVisible="true"
        >
         
         <!-- adding new button in edit profile state -->
         <div
            v-if="showEditButton()"
            class="text-right top-0 absolute lg:relative sm:inherit right-0 float-right pr-4 md:pr-0"
          >
            <button    
              class="text-gray-700 px-5 py-1 border-2 font-medium hover:bg-gray-200 rounded border-gray-500 mb-2"
              :class="[isBtnClicked ? 'bg-gray-100' : 'bg-gray-300']"
              @click="toggleComponentEditState"
            >
              {{ currentButton }}
            </button>
          </div>
        </ProfileNavbar>
        </ProfileHeader>
      </div>  
      <div class=" col-start-0 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-7">
        <transition name="fade">
          <component class="mt-8" :is="currentComponent" :editState="isBtnClicked"/>
        </transition>
      </div>

    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {computed, ref} from "vue";
import { useRouter } from 'vue-router'
import localStorage from 'store2'

import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import General from '@/components/profile/edit/pages/General'
import Index from '@/components/profile/edit/pages/Index'
import Diversity from '@/components/profile/edit/pages/Diversity'
import Medical from '@/components/profile/edit/pages/Medical'
import Physical from '@/components/profile/edit/pages/Physical'
import Travel from '@/components/profile/edit/pages/Travel'
import Experience from '@/components/profile/edit/pages/Experience'
import Professional from '@/components/profile/edit/pages/Professional'
import Photos from '@/components/profile/edit/pages/Photos'
import Videos from '@/components/profile/edit/pages/Videos'
import Audio from '@/components/profile/edit/pages/Audio'
import Documents from '@/components/profile/edit/pages/Documents'


const navigation = ref([
  { name: 'Overview', onClick:function(){onNavClick(this, navigation.value)}, component: 'Index', icon: 'EyeIcon', current: true },
  { name: 'Productions', onClick:function(){onNavClick(this, navigation.value)}, component: 'Experience', icon: 'EyeIcon',  current: false },
  { name: 'Professional', onClick:function(){onNavClick(this, navigation.value)}, component: 'Professional', icon: 'EyeOffIcon', current: false },
  { name: 'Photos', onClick:function(){onNavClick(this, navigation.value)}, component: 'Photos', icon: 'EyeOffIcon', current: false },
  { name: 'Videos', onClick:function(){onNavClick(this, navigation.value)}, component: 'Videos', icon: 'EyeOffIcon', current: false },
  { name: 'Audio', onClick:function(){onNavClick(this, navigation.value)}, component: 'Audio', icon: 'EyeOffIcon', current: false },
  { name: 'Documents', onClick:function(){onNavClick(this, navigation.value)}, component: 'Documents', icon: 'EyeOffIcon', current: false },
])

function onNavClick(activeNode, navigation) {
  for(let i=0; i < navigation.length; i++) { navigation[i].current = false }
  activeNode.current = true
}

function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = (cmp.component === name);
  }
}

export default {
  components: { ProfileNavbar, ProfileHeader, General, Index, 
                Diversity, Medical, Physical, Experience, 
                Travel, Professional, Photos, Videos,
                Documents, Audio
  },
  data(){
    return {
      isBtnClicked: false,
      currentButton: ''
    }
  },
  watch:{
    currentComponent(){
      this.isBtnClicked = false
    }
  },
  methods: {
      showEditButton(){
        if( this.currentComponent == 'Index' || this.currentComponent == 'Experience' ){
          if(this.currentComponent == 'Index'){
            this.currentButton = 'Customize'
          }else if(this.currentComponent == 'Experience'){
           this.currentButton = '+ Add Production'
          }
          return true
        }else{
          this.currentButton = ''
          return false
        }
      },
      toggleComponentEditState(){
        this.isBtnClicked = !this.isBtnClicked
      }
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    store.dispatch('fetchBasicProfile')
    store.dispatch('fetchProfile', store.getters.getId)

    let savedTab = localStorage.get('profileEditTab')
    let currentComponent = ref('Index')
    // Load previous component
    if(savedTab !== null) {
      currentComponent.value = savedTab
      changeCurrentByName(savedTab)
    }
    return {
      navigation,
      currentComponent,
      changeComponent(component){
        localStorage.set("profileEditTab", component)
        currentComponent.value = component
      },
      onSave: () => {
        store.dispatch('saveBasicInfo')
        router.go()
      },



      galleryMedia: computed(() => store.getters.media),
      galleryCols: computed(() => 2),
      countries: computed(() => store.getters.getCountries),
      originalEmail: computed( () => store.getters.getEmail)


      // basicInfo: computed(() => store.getters.basicInfo),
      //

      //
      // fileChange: async(name, files) => {
      //   await store.dispatch('savePhoto', files)
      //   router.go()
      // },
      //
      // galleryMedia: computed(() => store.getters.media),
      // galleryCols: computed(() => 2),
      // countries: computed(() => store.getters.getCountries)
    }
  },
  mounted(){
    if(!this.$store.getters.isLoggedIn){
      this.$router.push({name:'Home'})
    }
  },

}
</script>
