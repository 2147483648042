<template>
  <div
    id="bio"
    class="py-5 px-6  shadow rounded-lg mb-4 bg-primary dark:bg-primaryDark dark:text-white"
  >
    <div class="flex justify-between">
      <div class="flex">
        <UserIcon class="w-4 mr-2 text-gray-400 dark:text-white" />
        <p class="text-sm font-medium">Bio</p>
      </div>
      <div>
        <button
          @click="editActive = true"
          class="btn btn-sm  flex edit_button text-gray-600 dark:text-gray-200"
          v-if="editMode"
        >
          <span>
            <PencilIcon class="w-4 mt-1" />
          </span>
          <!-- Edit -->
        </button>
      </div>
    </div>
    <div v-if="lengthToggle">
      <p class="pt-1 text-sm sentence-case whitespace-pre-wrap">{{ shortBio }}{{ renderEllipses(bio) }}</p>
      <button v-if="isBioLong(bio)" class="col-end w-full text-right" @click="lengthToggle = false">
        <p class="w-full text-right items-center mt-2.5  text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm">
          See more
        </p>
      </button>
    </div>
    <div v-if="!lengthToggle">
      <p class="pt-1 text-sm sentence-case whitespace-pre-wrap ">{{ bio }}</p>
      <button v-if="isBioLong(bio)" class="w-full text-right items-center mt-2.5 shadow-sm text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm" @click="lengthToggle = true">
        <p class="text-gray-500 text-sm">
          See less
        </p>
      </button>
    </div>
    <overview-bio-edit
      v-if="editActive"
      @onSubmit="saveUpdatedBio"
      @onClose="editActive = false"
      :bio="bio"
    ></overview-bio-edit>
  </div>
</template>

<script>
import { UserIcon, PencilIcon } from "@heroicons/vue/outline/esm";
import OverviewBioEdit from "./OverviewBioEdit";
export default {
  name: "OverviewBio",
  data() {
    return {
      lengthToggle: this.short,
      editActive: false,
    };
  },
  components: {
    UserIcon,
    PencilIcon,
    OverviewBioEdit,
  },
  props: {
    bio: {
      required: true,
    },
    short: {
      type: Boolean,
      required: true,
    },
    shortBio: {
      required: true,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async saveUpdatedBio(bio) {
      await this.$store.dispatch("editProfile", { bio: bio });
      await this.$store.dispatch("fetchProfile", this.$route.params.id);
      await this.$store.dispatch("fetchBasicProfile")
      this.editActive = false;
    },
    isBioLong(bio) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return bio?.length > 500;
    },
    renderEllipses(bio) {
      //  Shows ellipses if bio is longer than 500 chars
      if (this.isBioLong(bio)) {
        return "..."
      } else {
        return;
      }
    },
  },
};
</script>
