<template>
    <div class="bg-primary w-full xs:w-48 h-12 grid grid-cols-4 border-2 rounded-md dark:bg-primaryDark" :class="containerClass()">
        <div class="col-span-1 flex justify-center items-center">
            <div @click="$emit('update:value', !on)" 
                class="border-2 border-gray-500 w-10 h-10 xs:w-2/3 xs:h-2/3 rounded-md cursor-pointer"
                :class="[on ? 'bg-gray-500' : '']"
                ></div>
        </div> 
        <div class="col-span-3 flex justify-left xs:justify-center items-center"><p class="font-medium">{{label}}</p></div> 
    </div> 
</template>

<script>
export default {
    name: "OnOffSwitch",
    components: {},
    props: {
        label: String,
        on: {
            type: Boolean,
            default: false
        },
        version: {
            required: false,
            default: "1"
        }
    },
    methods: {
        containerClass(){
            if(this.version == "2") return "border-gray/300"
            return "border-black"
        }
    },
    setup(){
    }
}
</script>

