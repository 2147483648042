<template>
  <div class="mb-8">
    <main>
    
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
         </div>
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <ProfileSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9">
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  :icon="['fab', 'accessible-icon']"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Edit diversity information
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <div id="inputList" class="">
                <div
                  id="left-inputs"
                  class="md:flex gap-y-1.5 w-full gap-x-10 mb-1.5 md:mb-4"
                >
                  <div class="w-full md:w-1/2 md:pr-5">
                    <DropdownWithCreate
                      heading="Ethnicity"
                      :value="form.ethnicity.value"
                      @update:value="form.ethnicity.value = $event"
                      :options="ethnicities"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Ethnicity"
                    />
                  </div>
                  <div class="w-full md:w-1/2 md:pr-5 mt-1.5 md:mt-0">
                    <DropdownWithCreate
                      heading="Religion"
                      :value="form.religion.value"
                      @update:value="form.religion.value = $event"
                      :options="religions"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Religion"
                    />
                  </div>
                </div>
                <div
                  id="left-inputs"
                  class="md:flex gap-y-1.5 w-full gap-x-10 mb-1.5 md:mb-4"
                >
                  <div class="w-full md:w-1/2 md:pr-5">
                    <DropdownWithCreate
                      heading="Sex"
                      :value="form.sex.value"
                      @update:value="form.sex.value = $event"
                      :options="sexes"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Sex"
                    />
                  </div>
                  <div class="w-full md:w-1/2 md:pr-5 mt-1.5 md:mt-0">
                    <DropdownWithCreate
                      heading="Gender"
                      :value="form.gender.value"
                      @update:value="form.gender.value = $event"
                      :options="genders"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Gender"
                    />
                  </div>
                </div>
                <div
                  id="left-inputs"
                  class="md:flex gap-y-1.5 w-full gap-x-10 mb-1.5 md:mb-4"
                >
                  <div class="w-full md:w-1/2 md:pr-10">
                    <DropdownWithCreate
                      heading="Sexual orientation"
                      :value="form.sexuality.value"
                      @update:value="form.sexuality.value = $event"
                      :options="sexualities"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Sexual orientation"
                    />
                  </div>
                </div>
                <div
                  id="left-inputs"
                  class="md:flex gap-y-1.5 w-full gap-x-10 mb-1.5 md:mb-4"
                >
                  <div class="w-full md:w-1/2 md:pr-5 md:mt-0">
                    <Multiselect
                      heading="Caring responsibilities"
                      :options="caringResponsibilities"
                      v-model="form.caringResponsibilities.value"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Caring responsibilities"
                    />
                  </div>
                  <div class="w-full md:w-1/2 md:pr-5 md:mt-0">
                    <Multiselect
                      heading="Disabilities"
                      :options="disabilities"
                      v-model="form.disabilities.value"
                      class="w-full capitalize"
                      :tooltip="true"
                      toolText="Add Disabilities"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full flex justify-end mt-6">
                <transition name="fade" mode="out-in">
                  <SaveCancelForm
                    version="2"
                    justifyOrientation="end"
                    @onSave="onSubmit"
                    :display="true"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed,ref } from "vue";
// import {ExclamationCircleIcon} from '@heroi/cons/vue/outline'
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import FormBuilder from "@/utils/FormBuilder";
import DropdownWithCreate from "@/components/inputs/DropdownWithCreate";
import Multiselect from "@/components/inputs/Multiselect";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import { createToaster } from "@meforma/vue-toaster";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";

export default {
  name: "Diversity",
  components: {
    DropdownWithCreate,
    SaveCancelForm,
    Multiselect,
    ExpandableBlockContent,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    store.dispatch("fetchDiversityOptions");
    store.dispatch("fetchUserDiversity");
    const openMobile = ref(false);
    const form = FormBuilder({
      ethnicity: store.getters.getUserEthnicity,
      religion: store.getters.getUserReligion,
      sex: store.getters.getUserSex,
      gender: store.getters.getUserGender,
      sexuality: store.getters.getUserSexuality,
      caringResponsibilities: store.getters.getUserCaringResponsibilities,
      disabilities: store.getters.getUserDisabilities,
    });

    return {
      openMobile,
      form,
      ethnicities: store.getters.getEthnicities,
      genders: store.getters.getGenders,
      religions: store.getters.getReligions,
      sexes: store.getters.getSexes,
      sexualities: store.getters.getSexualities,
      disabilities: store.getters.getDisabilities,
      caringResponsibilities: store.getters.getCaringResponsibilities,
      isMobile: computed(() => width.value < 1024),
      pushValToFormArray(val, field) {
        if (form[field].value.includes(val)) return;
        form[field].value = form[field].value.concat([val]);
      },
      removeValFromArray(idx, field) {
        form[field].value = form[field].value.filter((el, i) => i !== idx);
      },
      async onSubmit() {
        const toast = createToaster({ position: "top-right" });
        await store.dispatch("editUserDiversity", form.getForm());
        form.rebuildForm({
          ethnicity: store.getters.getUserEthnicity,
          religion: store.getters.getUserReligion,
          sex: store.getters.getUserSex,
          gender: store.getters.getUserGender,
          sexuality: store.getters.getUserSexuality,
          caringResponsibilities: store.getters.getUserCaringResponsibilities,
          disabilities: store.getters.getUserDisabilities,
        });

        toast.success(`Diversity info has been updated successfully`);
      },
    };
  },
  computed: {
    pageContent: function () {
      return "Your diversity information will by default not be visible to anyone except yourself, but you can choose to make some of it visible in the 'About' section on your profile page.\n\nThe performing arts have a large impact on society and we acknowledge that representation matters. We collect diversity information to help inform what different people that live performance industry is composed of. With Showdeck, organisations and researchers can access anonymous data and create reports more easily than ever before, supporting the case for equal and diverse working conditions.\n\nWe do this in line with Arts Council England's policy on wanting the arts to reflect society as it actually is, by using the data to e.g. inform their decisions on funding applications, how they support the arts etc. For more information on ACE's pioneering work, please visit their website. Please note that Showdeck is not acting on behalf of, or in an formal collaboration with, ACE - we are simply insipred by them. \n\nThese fields are voluntary and you are invited to fill them in, but please do not give more information that you are comfortable with. You can always come back later to add or change your information - as appropriate. We encourage you to keep your diversity information up to date and help in this mission.";
    },
  },
};
</script>
