<template>
  <div>
  
    <div v-if="!isMobile">
      <p class="whitespace-nowrap text-lg pr-2 font-semibold"  >Comfort information</p>
      <div class="w-full flex items-start">
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
        <div class="w-full grid" :class="outerClasses">
          <SelectInput heading="Fear of flying [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Fear of flying" class="col-span-2 md:col-span-1 my-1"
                     :options="numberVal"
                     />
          <SelectInput heading="Sea sickness [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Sea sickness " class="col-span-2 md:col-span-1 my-1"
                    :options="numberVal"
                     />
          <SelectInput heading="Car sickness [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Car sickness" class="col-span-2 md:col-span-1 my-1"
                     :options="numberVal"
                     />
         
        
        </div>
      </div>
      <div class="w-full grid items-start grid-cols-2 xl:grid-cols-2 md:gap-x-10 gap-1.5 mt-3" >
          <SelectInput heading="Preferred travel mode"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred travel mode" class="col-span-2 md:col-span-1 my-1"
                     :options="travelMode"
                     />
          <SelectInput heading="Preferred travel time"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred travel time" class="col-span-2 md:col-span-1 my-1"
                    :options="travelTime"
                     />
          <SelectInput heading="Seat preference"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Seat preference" class="col-span-2 md:col-span-1 my-1"
                     :options="seatPreference"
                     />
            <SelectInput heading="Dietary requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Dietary requirements" class="col-span-2 md:col-span-1 my-1"
            :options="dietaryRequirements"
            />
            <SelectInput heading="Preferred train carriage"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred train carriage" class="col-span-2 md:col-span-1 my-1"
                     :options="['Full Name','Professional Name']"
                     />
            <SelectInput heading="Accessibility requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Accessibility requirements" class="col-span-2 md:col-span-1 my-1"
            :options="['Full Name','Professional Name']"
            />
        
        </div>
    </div>
    <app-accordion class=""  v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center" v-if="!custom">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold" >Comfort information</p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
      <div class="w-full grid" :class="outerClasses">
        <SelectInput heading="Fear of flying [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Fear of flying" class="col-span-2 md:col-span-1 my-1"
                     :options="numberVal"
                     />
          <SelectInput heading="Sea sickness [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Sea sickness " class="col-span-2 md:col-span-1 my-1"
                    :options="numberVal"
                     />
          <SelectInput heading="Car sickness [0-10]"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Car sickness" class="col-span-2 md:col-span-1 my-1"
                     :options="numberVal"
                     />
          <slot></slot>
      </div>
      <div class="w-full grid items-start grid-cols-2 xl:grid-cols-2 md:gap-x-10 gap-1.5 mt-3" >
          <SelectInput heading="Preferred travel mode"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred travel mode" class="col-span-2 md:col-span-1 my-1"
                     :options="travelMode"
                     />
          <SelectInput heading="Preferred travel time"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred travel time" class="col-span-2 md:col-span-1 my-1"
                    :options="travelTime"
                     />
          <SelectInput heading="Seat preference"  :tooltip="true"  textFormate="capitalize"
                    toolText="Select Seat preference" class="col-span-2 md:col-span-1 my-1"
                     :options="seatPreference"
                     />
            <SelectInput heading="Dietary requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Dietary requirements" class="col-span-2 md:col-span-1 my-1"
            :options="dietaryRequirements"
            />
            <SelectInput heading="Preferred train carriage"  :tooltip="true"  textFormate="capitalize"
                    toolText="Preferred train carriage" class="col-span-2 md:col-span-1 my-1"
                     :options="['Full Name','Professional Name']"
                     />
            <SelectInput heading="Accessibility requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Accessibility requirements" class="col-span-2 md:col-span-1 my-1"
            :options="['Full Name','Professional Name']"
            />
        
        </div>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SelectInput from "@/components/inputs/SelectInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'

import { useStore } from "vuex";
import { computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints"
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

// import { UserIcon } from "@heroicons/vue/outline/esm";
export default {
  name: "TravelComfortInfoForm",
  components: {  AppAccordion, SelectInput  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints()
    const numberVal=[0,1,2,3,4,5,6,7,8,9,10]; 
    const travelMode=['Flight','Train','Bus','Car - driving','Car - not driving','Other'];  
    const travelTime=['Noon','Morning','Afternoon','Evening','Night','Other'];
    const seatPreference=['Aisle','Window','Middle'];
    const dietaryRequirements=['Gluten free and coeliac','Dairy free and lactose free','Vegetarian','Vegan','Paleo','FODMAP','Tree nut and peanut allergies','Fish and shellfish allergies','Other '];
    return {
      store,
      isMobile: computed(() => width.value < 1024),
      numberVal,
      travelMode,
      travelTime,
      seatPreference,
      dietaryRequirements
    };
  },
};
</script>
