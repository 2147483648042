<template>
  <div class="mb-8">
    <main>
      <div
        v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center"
      >
        <ProfileHeader class="mb-8" />
        <EditProfileHeaderButton />
      </div>
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <ProfileSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9">
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  icon="fa-fas fa-trophy"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-6 overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Awards & Nominations
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class=" w-10 my-1" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <div class="justify-between col-span-full mt-5">
                <LoadingSpinner v-if="isLoading" />
                <div class="flex col-span-full itens-center mt-2 mb-2">
                  <h2 class="text-xl flex-1 flex items-center"></h2>
                  <!-- <p class="flex-1 flex col-span-full items-center text-sm"> </p> -->
                  <button
                    type="button"
                    @click="editAwards(null)"
                    class="flex flex-row items-center mr-0 px-3 py-2.5 shadow-sm rounded-md sm:text-xs text-xs border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark mt-1 mb-1 cursor-pointer"
                  >
                    + Add award
                  </button>
                </div>
              </div>

              <AwardTable
                @onSelect="editAwards"
                header="Emergency Contacts"
                :values="awards"
                :fields="['award', 'year', 'category', 'production', 'won']"
              />
              <AwardModal
                v-if="editAwardOn"
                :contact="editAward"
                @onSave="saveAwards"
                @onCancel="cancelEditAwards"
                @onDelete="onDelete"
                @update:production="editAward.production = $event"
                @update:award="editAward.award = $event"
                @update:category="editAward.category = $event"
                @update:date="editAward.date = $event"
                @update:won="editAward.won = $event"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import { createToaster } from "@meforma/vue-toaster";
import AwardTable from "@/components/profile/components/awardTable/AwardTable";
import AwardModal from "@/components/profile/components/awardTable/AwardModal";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import LoadingSpinner from "@/components/widgets/LoadingSpinner";

export default {
  name: "Awards",
  components: {
    ExpandableBlockContent,
    AwardTable,
    AwardModal,
    ProfileHeader,
    EditProfileHeaderButton,
    ProfileSidebar,
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const editAwardOn = ref(false);
    store.dispatch("fetchUserAwards");
    let editAward = reactive({});
    async function cancelEditAwards() {
      delete editAward.production;
        delete editAward.award;
        delete editAward.category;
        delete editAward.date;
        delete editAward.won;
        delete editAward.id;
      editAwardOn.value = false;
      await store.dispatch("fetchUserAwards");
    }
    async function onDelete(id) {
      const toast = createToaster({ position: "top-right" });
      await store.dispatch("editUserAward", { id, delete: true });
      await store.dispatch("fetchUserAwards");
      toast.success(`Awards has been deleted successfully`);
      cancelEditAwards();
    }

    return {
      onDelete,
      editAwardOn,
      editAward,
      cancelEditAwards,
      isMobile: computed(() => width.value < 1024),
      awards: computed(() => store.getters.getUserAwards),
      isLoading: computed(() => store.getters.isLoading),
      onTrashedLink: computed(() => {
        return { func: onDelete, fieldName: "Name" };
      }),
      editAwards(award) {
        if (award === null) {
          Object.assign(editAward, {
            production: "",
            award: "",
            category: "",
            date: "",
            won: "",
          });
        } else {
          console.log(award);
          Object.assign(editAward, {
            production: award.production,
            award: award.award,
            category: award.category,
            date: award.date,
            won: award.won,
            id: award.id,
          });
        }
        console.log(editAward);
        editAwardOn.value = true;
      },
      async saveAwards() {
        const toast = createToaster({ position: "top-right" });
        // If editing pre-existing medical contact
        if (Object.prototype.hasOwnProperty.call(editAward, "id")) {
          console.log(editAward.won);
          await store.dispatch("editUserAward", {
            production: editAward.production,
            award: editAward.award,
            category: editAward.category,
            date: editAward.date,
            won: editAward.won ? true : false,
            id: editAward.id,
            delete: false,
          });
          toast.success(`Awards has been updated successfully`);
        } else {
          console.log(editAward);
          await store.dispatch("createUserAward", {
            production: editAward.production,
            award: editAward.award,
            category: editAward.category,
            date: editAward.date,
            won: editAward.won ? true : false,
          });
          toast.success(`Awards  has been created successfully`);
        }
        cancelEditAwards();
      },
    };
  },
  computed: {
    pageContent: function () {
      return "List all your nominations and if you have won them. A summary of these will be displayed on your profile page, with a more detailed page to be added as future functionality.";
    },
  },
};
</script>
