  <template>
        <div class="relative grid  w-full bg-white shadow rounded-md dark:bg-black">
            <h1 class="text-2xl  font-bold text-black-700 text-center w-full pb-4 pt-2 dark:text-white">Edit Photo</h1>
            <div class="relative flex justify-center px-4 pb-4">
                <img :src="contact.image" class="object-cover h-52 w-full"/>
            </div>
            <div class="grid grid-cols-2 px-4 pb-4">
                <div class="col-span-2 flex gap-4">
                <SmallTextInput :value="contact.title" @update:value="$emit('update:title', $event)"  heading="Title"/>
                <SmallTextInput heading="Photographer" :value="contact.photographer" @update:value="$emit('update:photographer', $event)"/>
                </div>
                <div class="col-span-2 flex gap-4 mt-4">
                <SmallTextInput heading="Location" :value="contact.location" @update:value="$emit('update:location', $event)" class="w-full" />
                <DateInput heading="Date" :value="contact.date" @update:value="$emit('update:date', $event)" class="w-full" />
                </div>
                <LargeTextInput heading="Caption" :value="contact.caption" @update:value="$emit('update:caption', $event)" class="col-span-1 py-4 text-left"/>
                
                <SaveCancelForm 
                    class="col-span-3" 
                    :display="true" 
                    justifyOrientation="end" 
                    @onSave="$emit('onSave')" 
                    @onCancel="$emit('onCancel')" 
                />
            </div>
        </div>        
</template>
<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import LargeTextInput from '@/components/inputs/LargeTextInput'

export default {
  name: "PhotoModal",
  components: { DateInput, SmallTextInput, SaveCancelForm,LargeTextInput },
  props: {
    contact: { type: Object, required: true },
    backdrop: {
      required: false,
      default: true,
    },
   
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("onCancel");
      }
    });
  },
  setup(props) {
    const store = useStore();
    console.log('sss',props.contact)
    const editMode = ref(props.contact.id !== undefined); // If contact has id prop then it can be deleted
    return {
      editMode,
      store,
      years: computed(() => Array.from(new Array(123), (x, i) => i + 1900).reverse()),
      formatted(year) {
        var start = new Date(year);
        let dates = start.getFullYear();
        return dates;
      },
    };
  },
  methods: {
    closeModal() {
      if (this.backdrop) this.$emit("onCancel");
    },
  },
};
</script>
