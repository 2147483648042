<template>
    <div class="">
        <transition-group name="fade">
        <div v-for="language, idx in languages" :key="idx"
             class="flex flex-col md:flex-row mb-2 w-full items-left lg:items-center gap-4 gap-x-10">
           
            <SmallTextInput class="w-full lg:w-6/12 flex-1" @update:value="language.language=$event" :value="language.language" :heading="idx === 0 ? 'Language' : ''" />
            <SelectInput class="w-full gap-1 lg:w-4/12 flex-1" @update:value="language.spokenProficiency=$event" :value="language.spokenProficiency" :options="levels" :heading="idx === 0 ? 'Spoken proficiency' : ''" />
            <SelectInput class="w-full gap-1 lg:w-4/12 flex-1" @update:value="language.writtenProficiency=$event" :value="language.writtenProficiency" :options="levels" :heading="idx === 0 ? 'Written proficiency' : ''" />
            <div class="flex-none w-5">
            <TrashIcon v-if="(idx !==0)" @click="deleteLine(language, idx)" class="w-6 text-left cursor-pointer dark:text-white" />
        </div>
        </div>
        </transition-group>
        <div class="w-full flex justify-start mt-4 text-left text-gray-500">
            <p @click="addNewLine" class="cursor-pointer text-gray-500 grey-slate-text dark:text-white">+ Add new line</p>
        </div>
        <div class="w-full flex justify-end">
             <SaveCancelForm
               :display="true"
               justify-orientation="end"
               version="2"
                @onSave="onSave"
            />
        </div>
    </div> 
        
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { TrashIcon } from '@heroicons/vue/outline/esm'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import SelectInput from '@/components/inputs/SelectInput'
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import { createToaster } from "@meforma/vue-toaster";
export default {
    name: "LanguageWidget",
    components: { SmallTextInput, SelectInput, TrashIcon ,SaveCancelForm},
    setup(){
        const store = useStore()
         return {
            store,
            languages: computed(() => store.getters.getUserLanguages),
            levels: ["Basic", "High standard", "Native / bilingual"],
            addNewLine:() => {
                store.commit("addNewLanguageLine")
                },
            deleteLine(item, idx){
              const result = confirm("Are you sure you want to delete?")
                if(item.id === undefined && result){
                    store.commit("removeLanguageByIdx", idx)
                } else {
                    store.dispatch("deleteLanguage", item.id)
                }
            },
            onSave() {
                const toast = createToaster({position:"top-right"});
                store.dispatch("saveLanguages")
                toast.success(`Languages has been updated successfully`);
              
            }
            
        }
    }
}
</script>

