<template>
    <div class="grid grid-cols-12 gap-3 mt-3 p-4 px-6  lg:px-0 lg:mx-10">
        <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
        <ProfileNavbar
            class=" col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark "
            @change="changeComponent"
            :navigation="navigation"
            :subNav="true"
        />
        </div>
        <!-- bg-white dark:text-white rounded pb-4 dark:bg-primaryDark -->
<!---- col-start-1 col-span-9 md:col-start-3 md:col-span-7 -->
<div class="col-span-12  md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8 ">
            <transition name="fade">
                <component :is="currentComponent" />
            </transition>
        </div>
    </div>
</template>

<script>
import localStorage from 'store2'
import Skills from '@/components/profile/edit/pages/Skills'
import Physical from '@/components/profile/edit/pages/Physical'
import Travel from '@/components/profile/edit/pages/Travel'
import Diversity from '@/components/profile/edit/pages/Diversity'
import Medical from '@/components/profile/edit/pages/Medical'
import Programme from '@/components/profile/edit/pages/Programme'
import Awards from '@/components/profile/edit/pages/Awards'
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileSidebar";
import {ref} from "vue";
import General from '@/components/profile/edit/pages/General'
import registerEvents from '../../../../utils/DisableSearchBoxInInputs'
const navigation = ref([
     {
        name: "Contact",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "General",
        icon:['far', 'address-book'],
        current: true,
        
    },
    {
        name: "Skills",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Skills",
        icon:['fas', 'wrench'],
        current: false,
    },
    {
        name: "Physical",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Physical",
        icon:['fas', 'person'],
        current: false,
    },
    {
        name: "Diversity",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Diversity",
        icon:['fab','accessible-icon'],
        current: false,
    },
    {
        name: "Medical",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Medical",
        icon:['fas', 'folder-plus'],
        current: false,
    },
    {
        name: "Travel",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Travel",
        icon:['fas', 'plane-departure'],
        current: false,
    },
    {
        name: "Programme",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Programme",
        icon:['fas', 'book-open'],
        current: false,
    },
    {
        name: "Awards & nominations",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Awards",
        icon:['fas', 'trophy'],
        current: false,
    },
]);

function onNavClick(activeNode, navigation) {
    for (let i = 0; i < navigation.length; i++) {
        navigation[i].current = false;
    }
    activeNode.current = true;
    setTimeout(registerEvents, 500);
}

function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = (cmp.component === name);
  }
}

export default {
    name: "Professional",
    components: { Skills, ProfileNavbar, Awards, Physical, Travel, Diversity, Medical, Programme, General },
    setup() {
        let savedTab = localStorage.get('profileProfessionalEditTabs')
        let currentComponent = ref("General");
        if(savedTab !== null) {
          currentComponent.value = savedTab
          changeCurrentByName(savedTab)
        }

        return {
            navigation,
           currentComponent,
            changeComponent(component) {
                localStorage.set("profileProfessionalEditTabss", component)
                currentComponent.value = component;
            },
        }
    },
    beforeUnmount() {
        // Reset navbar indicator
        for (let i = 0; i < this.navigation.length; i++) {
            this.navigation[i].current = false;
        }
        this.navigation[0].current = true;
    },
}
</script>

