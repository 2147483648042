<template>
  <side-bar-wrapper 
    @onClick="editActive = true" 
    :editMode="editMode" 
    title="General" 
    :showTitleIcon="false" 
    titleIcon="IdentificationIcon"
    >

    <div v-if="about" class="about_info dark:text-white flex flex-col gap-6 mt-4">
      <div v-if="about.placeOfBirth && about.placeOfBirth.length > 0" class="flex gap-2 items-center">
        <svg class="w-5 stroke-current text-gray-400 dark:text-white" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.58203 19.2503V4.58362C5.43879 3.74383 6.59066 3.27344 7.79036 3.27344C8.99006 3.27344 10.1419 3.74383 10.9987 4.58362C11.8555 5.42341 13.0073 5.8938 14.207 5.8938C15.4067 5.8938 16.5586 5.42341 17.4154 4.58362V12.8336C16.5586 13.6734 15.4067 14.1438 14.207 14.1438C13.0073 14.1438 11.8555 13.6734 10.9987 12.8336C10.1419 11.9938 8.99006 11.5234 7.79036 11.5234C6.59066 11.5234 5.43879 11.9938 4.58203 12.8336"  stroke-width="2"  /></svg>
        <p>
            {{ about.placeOfBirth }}
          </p>
      </div>

      <div v-if="about.gender && about.gender.length > 0" class="flex gap-2 items-center">
        <svg class="w-5 fill-current text-gray-400 dark:text-white" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.9496 2.75033H13.7513V0.916992H21.0846V8.25033H19.2513V4.04283L14.8238 8.47032C15.2381 9.09588 15.4925 9.81358 15.5646 10.5604C15.6367 11.3072 15.5244 12.0603 15.2375 12.7536C14.9506 13.4469 14.4979 14.0592 13.9191 14.5366C13.3403 15.0141 12.6531 15.3421 11.918 15.492V17.417H13.7513V19.2503H11.918V21.0837H10.0846V19.2503H8.2513V17.417H10.0846V15.492C9.35152 15.3421 8.66626 15.015 8.08869 14.5393C7.51112 14.0635 7.05881 13.4536 6.77127 12.7628C6.48373 12.072 6.3697 11.3213 6.43914 10.5762C6.50858 9.83116 6.75938 9.11444 7.16964 8.48866L6.08797 7.39782L4.80464 8.67199L3.51214 7.37033L4.79547 6.09616L2.7513 4.05199V7.33366H0.917969V0.916992H7.33464V2.75033H4.0438L6.08797 4.80366L7.40797 3.49283L8.70964 4.79449L7.38964 6.10533L8.46213 7.18699C9.16797 6.70116 10.0846 6.41699 11.0013 6.41699C11.918 6.41699 12.798 6.69199 13.5221 7.17783L17.9496 2.75033ZM11.0013 8.25033C10.272 8.25033 9.57248 8.54006 9.05676 9.05578C8.54103 9.57151 8.2513 10.271 8.2513 11.0003C8.2513 11.7297 8.54103 12.4291 9.05676 12.9449C9.57248 13.4606 10.272 13.7503 11.0013 13.7503C11.7306 13.7503 12.4301 13.4606 12.9458 12.9449C13.4616 12.4291 13.7513 11.7297 13.7513 11.0003C13.7513 10.271 13.4616 9.57151 12.9458 9.05578C12.4301 8.54006 11.7306 8.25033 11.0013 8.25033Z"/>
        </svg>
       <p>
            {{ about.gender }}
          </p>
      </div>

      <div v-if="about.religion && about.religion.length > 0" class="flex gap-2 items-center">
       <svg class="w-5 fill-current text-gray-400 dark:text-white" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 6.74714C7.95 6.74714 7.5 7.25339 7.5 7.87214V10.6846C7.5 10.9954 7.27625 11.2471 7 11.2471C6.72375 11.2471 6.5 10.9954 6.5 10.6846V7.99342C6.5 7.38206 6.6475 6.78159 6.92781 6.25706L9.3575 1.70116C9.64156 1.16819 9.48844 0.47737 9.01469 0.157449C8.56313 -0.147356 7.99219 0.00627668 7.69281 0.481238C7.68656 0.489324 7.67344 0.48862 7.66813 0.498113L4.00375 6.6821C3.67375 7.23897 3.5 7.8862 3.5 8.55346V11.374L0.68375 12.4301C0.484615 12.5047 0.311397 12.6479 0.188667 12.8394C0.0659374 13.0309 -7.29019e-05 13.261 6.04197e-08 13.4971V16.8721C6.04197e-08 17.2525 0.26625 17.9971 1 17.9971C1.08406 17.9971 1.16906 17.9852 1.25188 17.9609L6.85156 16.3219C8.41125 15.8203 9.5 14.1964 9.5 12.3721V7.87214C9.5 7.25339 9.05 6.74714 8.5 6.74714ZM19.3163 12.433L16.5 11.3769V8.55628C16.5 7.88901 16.3263 7.24178 15.9963 6.68491L12.3319 0.501277C12.3263 0.491784 12.3134 0.492839 12.3072 0.484402C12.0078 0.00944076 11.4369 -0.144192 10.9853 0.160613C10.5116 0.480183 10.3584 1.17135 10.6425 1.70432L13.0722 6.26022C13.3525 6.78475 13.5 7.38522 13.5 7.99659V10.6878C13.5 10.9986 13.2763 11.2503 13 11.2503C12.7238 11.2503 12.5 10.9986 12.5 10.6878V7.8753C12.5 7.25655 12.05 6.7503 11.5 6.7503C10.95 6.7503 10.5 7.25655 10.5 7.8753V12.3753C10.5 14.1996 11.5888 15.8234 13.1484 16.3248L18.7481 17.9637C18.8313 17.988 18.9163 17.9999 19 17.9999C19.7338 17.9999 20 17.2553 20 16.8749V13.4999C20 13.0158 19.7247 12.5862 19.3163 12.433Z"/>
      </svg> 
       <p>
            {{ about.religion }}
          </p>
      </div>

      <div v-if="about.ethnicity && about.ethnicity.length > 0" class="flex gap-2 items-center">
        <svg class="w-5 stroke-current text-gray-400 dark:text-white" style="enable-background:new 0 0 22 22;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <path d="M14.6654 6.41667C14.6654 8.44171 13.0237 10.0833 10.9987 10.0833C8.97365 10.0833 7.33203 8.44171 7.33203 6.41667C7.33203 4.39162 8.97365 2.75 10.9987 2.75C13.0237 2.75 14.6654 4.39162 14.6654 6.41667Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10.9987 12.8333C7.45487 12.8333 4.58203 15.7062 4.58203 19.25H17.4154C17.4154 15.7062 14.5425 12.8333 10.9987 12.8333Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> 
      <p>
            {{ about.ethnicity }}
          </p>
      </div>

      <div v-if="about.dateOfBirth && about.dateOfBirth.length > 0" class="flex gap-2 items-center">
        <svg class="w-5 fill-current text-gray-400 dark:text-white" style="enable-background:new 0 0 22 22;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
         <path d="M5.4987 13.7499C4.73786 13.7499 4.05036 14.0616 3.55536 14.5566C2.4737 15.6383 1.83203 20.1666 1.83203 20.1666C1.83203 20.1666 6.36036 19.5249 7.44203 18.4433C7.82793 18.0594 8.09105 17.5694 8.19798 17.0357C8.3049 16.502 8.25081 15.9485 8.04257 15.4456C7.83432 14.9427 7.48133 14.513 7.0284 14.2111C6.57546 13.9091 6.04303 13.7486 5.4987 13.7499ZM6.14953 17.1508C5.89286 17.4074 4.16036 17.8474 4.16036 17.8474C4.16036 17.8474 4.5912 16.1241 4.85703 15.8583C5.01286 15.6841 5.24203 15.5833 5.4987 15.5833C5.68018 15.5835 5.85753 15.6375 6.0084 15.7384C6.15927 15.8392 6.27691 15.9825 6.3465 16.1501C6.4161 16.3177 6.43453 16.5022 6.39948 16.6802C6.36442 16.8583 6.27746 17.022 6.14953 17.1508V17.1508ZM15.967 12.5124C21.797 6.68242 19.8537 2.14492 19.8537 2.14492C19.8537 2.14492 15.3162 0.201584 9.4862 6.03158L7.2037 5.57325C6.90699 5.51628 6.60087 5.53241 6.3118 5.62026C6.02272 5.7081 5.75938 5.865 5.54453 6.07742L1.83203 9.79908L6.41536 11.7608L10.2379 15.5833L12.1995 20.1666L15.912 16.4541C16.3429 16.0233 16.5354 15.3999 16.4162 14.7949L15.967 12.5124V12.5124ZM6.7912 9.92742L5.04036 9.17575L6.8462 7.36992L8.1662 7.63575C7.6437 8.39658 7.1762 9.19408 6.7912 9.92742V9.92742ZM12.8229 16.9583L12.0712 15.2074C12.8045 14.8224 13.602 14.3549 14.3537 13.8324L14.6195 15.1524L12.8229 16.9583V16.9583ZM14.6654 11.2199C13.4554 12.4299 11.567 13.4199 10.962 13.7224L8.2762 11.0366C8.56953 10.4408 9.55953 8.55242 10.7787 7.33325C15.0687 3.04325 18.3229 3.67575 18.3229 3.67575C18.3229 3.67575 18.9554 6.92992 14.6654 11.2199ZM13.7487 10.0833C14.757 10.0833 15.582 9.25825 15.582 8.24992C15.582 7.24158 14.757 6.41658 13.7487 6.41658C12.7404 6.41658 11.9154 7.24158 11.9154 8.24992C11.9154 9.25825 12.7404 10.0833 13.7487 10.0833Z" />
         </svg>
        <p>
            {{ about.dateOfBirth }}
          </p>
      </div>


      <div v-if="about.disabilities && about.disabilities.length > 0" class="flex gap-2 items-start">
          <div ><svg  class="w-5 fill-current text-gray-400 dark:text-white" viewBox="0 0 22 22"  xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5895 10.9914L19.0352 17.7504C18.8934 19.4992 16.2895 19.2586 16.4312 17.5398L16.8609 12.2762L15.0949 12.375C15.5289 13.2645 15.7738 14.2613 15.7738 15.3184C15.7738 17.0887 15.082 18.7 13.9562 19.8945L12.2676 18.2059C14.7555 15.4688 12.8305 11.0215 9.08789 11.0215C7.975 11.0215 6.96094 11.4469 6.20039 12.1387L4.51172 10.45C5.45703 9.56055 6.66445 8.9418 8.00937 8.72266L11.2449 5.04023L9.41445 3.97461L7.19727 5.95117C5.9082 7.10273 4.16367 5.15625 5.45703 4.00039L8.37891 1.39219C8.8 1.01406 9.41445 0.953906 9.9043 1.2375C9.9043 1.2375 15.8898 4.71367 15.8984 4.72227C16.5945 5.15625 16.7879 6.26914 16.1605 6.98242L13.6512 9.83984L18.2102 9.58633C19.0051 9.53906 19.6539 10.2051 19.5895 10.9914ZM16.8008 4.37422C18.0082 4.37422 18.9879 3.39453 18.9879 2.18711C18.9879 0.979688 18.0082 0 16.8008 0C15.5934 0 14.6137 0.979688 14.6137 2.18711C14.6137 3.39453 15.5934 4.37422 16.8008 4.37422V4.37422ZM9.09219 19.6152C5.62891 19.6152 3.61797 15.7223 5.53867 12.9078L3.83281 11.202C2.93906 12.332 2.40625 13.7629 2.40625 15.3141C2.40625 20.9301 8.88164 23.968 13.2086 20.5777L11.5027 18.8719C10.8152 19.3402 9.98594 19.6152 9.09219 19.6152Z" />
            </svg>
          </div>
          <div class="flex gap-2 lg:flex-row flex-col">
          <p v-for="(disability, idx) in about.disabilities" :key="idx">
            {{ disability }}
          </p>
        </div>
      </div>
    </div>
  </side-bar-wrapper>
  <overview-about-edit v-if="editActive" :about="about" @onClose="editActive = false" :userDiversity="userDiversity">
  </overview-about-edit>
</template>

<script>
import SideBarWrapper from "../../common/SideBarWrapper";
import OverviewAboutEdit from "./OverviewAboutEdit";
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: "OverviewAbout",
  data() {
    return {
      editActive: false,
    };
  },
  components: {
    SideBarWrapper,
    OverviewAboutEdit,
  },
  props: {
    editMode: {
      required: true,
      type: Boolean,
    },
    about: {
      required: true,
    },
    userDiversity: {
      required: true,
    },
  },
  setup() {
    const store = useStore()
    return {
        darkModeOn: computed(() => store.getters.getDarkMode),
        iconUrl: "https://s3.eu-west-1.amazonaws.com/showdeck.io/static/assets/img/diversity_icons/"
    };
  },
  async beforeMount() {
    await this.$store.dispatch("fetchDiversityOptions");
    await this.$store.dispatch("fetchUserDiversity");
  },
  methods: {
 
    isBioLong(bio) {
      //  This prevents rendering the 'See More/Less' buttons
      //  when they are not needed.
      return bio?.length > 500;
    },
    renderEllipses(bio) {
      //  Shows ellipses if bio is longer than 500 chars
      if (this.isBioLong(bio)) {
        return "..."
      } else {
        return;
      }
    },
  },
};
</script>
<style scoped>
.about_info p {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 30px; */
  /* color: #111827; */
}

.about_info strong {
  font-size: 14px;
  line-height: 30px;
}
</style>
