<template>
  <div>
  
    <div v-if="!isMobile">
      <div class="flex items-center justify-between items-center pt-6 pb-4" v-if="!custom">
        <p class="whitespace-nowrap pr-2 font-semibold text-lg ">Basic information</p>
        <div class="flex justify-center sm:justify-end" :class="isMobile?'hidden':''">
          <div class="p-2 rounded-full w-max lg:gap-3 flex border border-gray-500 items-center">
            <p class="flex items-center text-gray-600 text-sm dark:text-white">Show vCard in header <BaseToolTip  text="vCard in header"> </BaseToolTip></p>
            <ToggleSwitch :model-value="enableVcard" @update:modelValue="onVcardClick" />
          </div>
        </div>
        <!-- <div class="h-px w-full bg-gray-400"></div> -->
      </div>
      <div class="w-full flex items-start">
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
        <div class="w-full grid" :class="outerClasses">
          <SmallTextInput
            :tooltip="true"
            toolText="Add First Name"
            class="col-span-2 my-1 md:col-span-1"
            heading="First Name"
            placeholder="First Name"
            :value="basicInfo.firstName"
            @update:value="basicInfo.firstName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Middle Name"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Middle Name"
            placeholder="Middle Name"
            :value="basicInfo.middleName"
            @update:value="basicInfo.middleName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Last Name"
            class="col-span-2 md:col-span-1 my-1"
            heading="Last Name"
            placeholder="Last Name"
            :value="basicInfo.surname"
            @update:value="basicInfo.surname = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Prefix"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Prefix"
            placeholder="Prefix"
            :value="basicInfo.prefix"
            @update:value="basicInfo.prefix = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Suffix"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Suffix"
            placeholder="Suffix"
            :value="basicInfo.suffix"
            @update:value="basicInfo.suffix = $event"
          />
          <!-- <div v-if="!custom" class="col-span-1 hidden xl:block " /> -->
          <SmallTextInput
            :tooltip="true"
            toolText="Add Professional Name"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Professional Name"
            placeholder="Professional Name"
            :value="basicInfo.professionalName"
            @update:value="basicInfo.professionalName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Nickname"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Nickname"
            placeholder="Nickname"
            :value="basicInfo.nickname"
            @update:value="basicInfo.nickname = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Pronouns"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Pronouns"
            placeholder="Pronouns"
            :value="basicInfo.pronouns"
            @update:value="basicInfo.pronouns = $event"
          />
          <!-- <SmallTextInput
            :tooltip="true"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Name to display on profile"
            placeholder="Name to display on profile"
            :value="basicInfo.pronouns"
            @update:value="basicInfo.pronouns = $event"
          /> -->
          <SelectInput heading="Name to display on profile"  :tooltip="true"  textFormate="capitalize"
                    toolText="Name to display on profile" class="col-span-2 md:col-span-1 my-1"
                    @update:value="basicInfo.nameToDisplayOnProfile = $event" :value="basicInfo.nameToDisplayOnProfile" :options="['Full Name','Professional Name']"
                     />

          <DateInput
            v-if="!custom"
            toolText="Add Date of Birth" 
            class="col-span-2 md:col-span-1 my-1"
            heading="Date of Birth"
            placeholder="Date of Birth"
            :value="basicInfo.dateOfBirth"
            @update:value="basicInfo.dateOfBirth = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Place of Birth"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Place of birth"
            placeholder="Place of birth"
            :value="basicInfo.placeOfBirth"
            @update:value="basicInfo.placeOfBirth = $event"
          />
          <slot></slot>
        </div>
      </div>
    </div>
    <app-accordion class=""  v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center" v-if="!custom">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold"  :class="paraClasses">Basic information</p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
      <div class="w-full grid" :class="outerClasses">
        <SmallTextInput
            :tooltip="true"
            toolText="Add First Name"
            class="col-span-2 my-1 md:col-span-1"
            heading="First Name"
            placeholder="First Name"
            :value="basicInfo.firstName"
            @update:value="basicInfo.firstName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Middle Name"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Middle Name"
            placeholder="Middle Name"
            :value="basicInfo.middleName"
            @update:value="basicInfo.middleName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Last Name"
            class="col-span-2 md:col-span-1 my-1"
            heading="Last Name"
            placeholder="Last Name"
            :value="basicInfo.surname"
            @update:value="basicInfo.surname = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Prefix"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Prefix"
            placeholder="Prefix"
            :value="basicInfo.prefix"
            @update:value="basicInfo.prefix = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Suffix"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Suffix"
            placeholder="Suffix"
            :value="basicInfo.suffix"
            @update:value="basicInfo.suffix = $event"
          />
          <!-- <div v-if="!custom" class="col-span-1 hidden xl:block " /> -->
          <SmallTextInput
            :tooltip="true"
            toolText="Add Professional Name"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Professional Name"
            placeholder="Professional Name"
            :value="basicInfo.professionalName"
            @update:value="basicInfo.professionalName = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Nickname"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Nickname"
            placeholder="Nickname"
            :value="basicInfo.nickname"
            @update:value="basicInfo.nickname = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Pronouns"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Pronouns"
            placeholder="Pronouns"
            :value="basicInfo.pronouns"
            @update:value="basicInfo.pronouns = $event"
          />
          <SelectInput heading="Name to display on profile"  toolText="Add Name to display on profile" :tooltip="true"  textFormate="capitalize"
                  class="col-span-2 md:col-span-1 my-1 gap-1"
                    @update:value="basicInfo.nameToDisplayOnProfile = $event" :value="basicInfo.nameToDisplayOnProfile" :options="['Full Name','Professional Name']"
                     />
          <DateInput
            v-if="!custom"
            toolText="Add Date of Birth"
            class="col-span-2 md:col-span-1 my-1"
            heading="Date of Birth"
            placeholder="Date of Birth"
            :value="basicInfo.dateOfBirth"
            @update:value="basicInfo.dateOfBirth = $event"
          />
          <SmallTextInput
            :tooltip="true"
            toolText="Add Place of Birth"
            v-if="!custom"
            class="col-span-2 md:col-span-1 my-1"
            heading="Place of birth"
            placeholder="Place of birth"
            :value="basicInfo.placeOfBirth"
            @update:value="basicInfo.placeOfBirth = $event"
          />
          <slot></slot>
      </div>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SmallTextInput from "@/components/inputs/SmallTextInput";
import SelectInput from "@/components/inputs/SelectInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'
import DateInput from "@/components/inputs/DateInput";
import { useStore } from "vuex";
import ToggleSwitch from "@/components/inputs/ToggleSwitch";
import { computed,ref } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints"
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import BaseToolTip from "@/components/widgets/BaseToolTip.vue";
// import { UserIcon } from "@heroicons/vue/outline/esm";
export default {
  name: "UserInfoForm",
  components: { DateInput, SmallTextInput,AppAccordion, ToggleSwitch ,SelectInput,BaseToolTip  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints()
    const enableVcard = ref(false)
    enableVcard.value = store.getters.getEnableVcard
    return {
      enableVcard,
      basicInfo: computed(() => store.getters.basicInfo),
      isMobile: computed(() => width.value < 1024),
      onVcardClick() {
        enableVcard.value = !enableVcard.value
        store.dispatch("editProfileOverviewSettings", { enableVcard: enableVcard.value })
        store.commit("setEnableVcard", enableVcard.value)
      },

    };
  },
};
</script>
