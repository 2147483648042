<template>
  <modal
    :modalActive="editActive"
    :duration="400"
    version="3"
    @close="$emit('onClose', false)"
  >
    <div class="mb-9 p-4 bg-white rounded-lg dark:bg-primaryDark dark:text-white max-h-100 overflow-hidden overflow-y-scroll sm:overflow-y-hidden">
      <p class="text-2xl text-left font-medium mb-4">Edit About</p>
      <div class="text-md text-left mb-4">
        <p class="font-medium">Choose what to display on your profile</p>
        <p>
          <span class="font-medium">Note:</span> You can also edit your about
          info by clicking on the pen next to each container below
        </p>
      </div>
      <div class="grid grid-cols-1 xs:grid-cols-2 sm:grid sm:grid-cols-3 gap-1 ">
        <edit-about-tab
          title="Pronouns"
          inputType="text"
          :active="getUserOverviewSettings.showPronouns"
          @onSwitch="switchOverviewSetting('showPronouns', $event)"
          :value="getUserPronouns"
          @onUpdate="saveInput('about', 'pronouns', $event)"
        ></edit-about-tab>

        <edit-about-tab
          title="Place of birth"
          inputType="text"
          :active="getUserOverviewSettings.showPlaceOfBirth"
          @onSwitch="switchOverviewSetting('showPlaceOfBirth', $event)"
          :value="getUserPlaceOfBirth"
          @onUpdate="saveInput('about', 'placeOfBirth', $event)"
        ></edit-about-tab>

        <!-- <edit-about-tab
          title="Nationality"
          inputType="text"
          :active="getUserOverviewSettings.showNationality"
          @onSwitch="switchOverviewSetting('showNationality', $event)"
          :value="localAbout.nationality"
          @onUpdate="saveInput('about' , 'nationality', $event)"
        ></edit-about-tab> -->

        <edit-about-tab
          title="Date of Birth"
          inputType="date"
          :active="getUserOverviewSettings.showDateOfBirth"
          @onSwitch="switchOverviewSetting('showDateOfBirth', $event)"
          :value="getUserDateOfBirth"
          @onUpdate="saveInput('about', 'dateOfBirth', $event)"
        ></edit-about-tab>

        <edit-about-tab
          title="Ethnicity"
          inputType="dropdown"
          :active="getUserOverviewSettings.showEthnicity"
          @onSwitch="switchOverviewSetting('showEthnicity', $event)"
          :value="localDiversity.ethnicity"
          @onUpdate="saveInput('diversity', 'ethnicity', $event)"
          :options="getEthnicities"
        ></edit-about-tab>

        <edit-about-tab
          title="Religion"
          inputType="dropdown"
          :active="getUserOverviewSettings.showReligion"
          @onSwitch="switchOverviewSetting('showReligion', $event)"
          :value="localDiversity.religion"
          @onUpdate="saveInput('diversity', 'religion', $event)"
          :options="getReligions"
        ></edit-about-tab>

        <edit-about-tab
          title="Gender"
          inputType="dropdown"
          :active="getUserOverviewSettings.showGender"
          @onSwitch="switchOverviewSetting('showGender', $event)"
          :value="localDiversity.gender"
          @onUpdate="saveInput('diversity', 'gender', $event)"
          :options="getGenders"
        ></edit-about-tab>

        <edit-about-tab
          title="Disabilities"
          inputType="dropdownMulti"
          :active="getUserOverviewSettings.showDisabilities"
          @onSwitch="switchOverviewSetting('showDisabilities', $event)"
          :value="localDiversity.disabilities"
          @onUpdate="pushNew('diversity', 'disabilities', $event)"
          @onDelete="removeOld('diversity', 'disabilities', $event)"
          :options="getDisabilities"
        ></edit-about-tab>
      </div>
      <SaveCancelForm
        class="col-span-2 pt-2"
        @onSave="submitAbout"
        @onCancel="$emit('onClose', false)"
        justifyOrientation="end"
        :display="true"
      />
    </div>
    <!-- {{ getUserDateOfBirth }} -->
  </modal>
</template>

<script>
import Modal from "@/components/widgets/Modal";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import { mapGetters } from "vuex";
import EditAboutTab from "./EditAboutTab";

export default {
  name: "OverviewAboutEdit",
  props: {
    about: {
      required: true,
    },
    userDiversity: {
      required: true,
    },
  },
  components: {
    Modal,
    SaveCancelForm,
    EditAboutTab,
  },
  data() {
    return {
      title: false,
      editActive: true,
      updateAbout: false,
      updateDiversity: false,
      updateSettings: false,
      localDiversity: JSON.parse(JSON.stringify(this.userDiversity)), // deep copy of the prop userDiversity
      localAbout: {},
      overviewSettings: {},
      activeInput: "",
    };
  },
  computed: {
    ...mapGetters([
      "getUserOverviewSettings",
      "getUserDateOfBirth",
      "getUserPlaceOfBirth",
      "getUserPronouns",
      "getEthnicities",
      "getDisabilities",
      "getReligions",
      "getGenders",
    ]),
  },
  methods: {
    switchOverviewSetting(name, value) {
      this.updateSettings = true;
      this.overviewSettings[name] = value;
    },
    saveInput(type, name, value) {
      if (type == "about") {
        this.updateAbout = true;
        this.localAbout[name] = value;
      } else if (type == "diversity") {
        this.updateDiversity = true;
        this.localDiversity[name] = value;
      }
    },
    pushNew(type, name, value) {
      if (type == "about") {
        this.updateAbout = true;
        this.localAbout[name].push(value);
      } else if (type == "diversity") {
        this.updateDiversity = true;
        this.localDiversity[name].push(value);
      }
    },
    removeOld(type, name, index) {
      if (type == "about") {
        this.updateAbout = true;
        this.localAbout[name].splice(index, 1);
      } else if (type == "diversity") {
        this.updateDiversity = true;
        this.localDiversity[name].splice(index, 1);
      }
    },
    async submitAbout() {
      if (this.updateSettings || this.updateDiversity || this.updateAbout) {
        if (this.updateSettings) {
          await this.submitOverview();
        }
        if (this.updateDiversity) {
          await this.$store.dispatch("editUserDiversity", this.localDiversity);
        }
        if (this.updateAbout) {
          await this.$store.dispatch("editProfile", {
            ...this.localAbout,
            bio: this.$store.getters.getProfileBio,
          });
        }
        await this.$store.dispatch("fetchProfile", this.$route.params.id);
        await this.$store.dispatch("fetchBasicProfile");
      }
      this.$emit("onClose", false);
    },

    async submitOverview() {
      await this.$store.dispatch(
        "editProfileOverviewSettings",
        this.overviewSettings
      );
    },
  },
};
</script>
