<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
      </div> 
      
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
      <ProfileSidebar
        class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
        :subNav="true"
      />
    </div>
    <div
      class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
    >
  <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9 opacity-60">
    <div class="flex items-center gap-2">
      <font-awesome-icon :icon="['fas', 'plane-departure']"  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black  flex  items-center py-1 px-0  h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded   transition duration-300 ease-in-out']" aria-hidden="true" />
      <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
        Travel Information
      </h1>
    </div>
    <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6 ">
      <!-- <ExclamationCircleIcon class="w-4" /> -->
     
      <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
    </div>
    <TravelUserInfoForm class="bg-white dark:bg-primaryDark" />
    <TravelComfortInfoForm class="bg-white dark:bg-primaryDark mt-5" />
    <TravelAccommodationPreferenceForm class="bg-white dark:bg-primaryDark mt-5"/>
    <TravelDocumentations class="bg-white dark:bg-primaryDark mt-5"/>
    <TravelHubs  class="bg-white dark:bg-primaryDark mt-5" />
    <div class="flex justify-end w-full px-4">
        <SaveCancelForm
               version="1"
               :display="true"
               justify-orientation="end"
                @onCancel="onCancel"
                @onSave="onSave"
            />
      </div>
  </div>
  </div>
  </div>
  </div></main></div>
</template>

<script>
// import { computed } from 'vue'
import {useStore} from "vuex";
import {computed,ref} from "vue";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";
import TravelUserInfoForm from "@/components/forms/TravelUserInfoForm";
import TravelComfortInfoForm from "@/components/forms/TravelComfortInfoForm";
import TravelAccommodationPreferenceForm from "@/components/forms/TravelAccommodationPreferenceForm";
import TravelDocumentations from "@/components/forms/TravelDocumentations";
import TravelHubs from "@/components/forms/TravelHubs";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
export default {
  name: "Travel",
  components: {ExpandableBlockContent,
    ProfileSidebar,ProfileHeader ,  
    EditProfileHeaderButton,
    TravelUserInfoForm,
    TravelComfortInfoForm,
    TravelAccommodationPreferenceForm,
    TravelDocumentations,
    TravelHubs,
    SaveCancelForm
     },
  setup(){
    const store = useStore()
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      store,
      isMobile: computed(() => width.value < 1024),
      onSave(){

      },
      onCancel(){
        
      }
    }
  },
  computed: {
      pageContent: function () {
        return 'Registering your travel information allows for travel arrangements to be made with ease. It will only be made available to your supervisors or managers, e.g. tour manager, company manager, stage manager etc. Future functionality will allow you to see how your information is accessed and control it more thoroughly.'
      },
    },
}
</script>

<style scoped>

</style>
