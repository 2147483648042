<template>
    <div>
        <label class="block text-sm font-medium text-gray-700 dark:text-white ">{{ heading }}</label>
        <input :disabled="disabled" :value="year" min="1000" max="9999" @input="onChange($event.target.value)" type="number" class="w-48 h-12 block border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-primaryDark dark:text-white"/>
    </div>
</template>

<script setup>
import { defineProps, defineEmit, computed, ref } from "vue";
import DateInput from "./DateInput.vue";

const props = defineProps({
    heading: String,
    value: String,
    disabled: {
        required: false,
        default: false
    }
})
const emit = defineEmit(["update:value"])

const year = computed(() => props.value ? props.value.split("-")[0] : "")
const lastValidYear = ref("")

const onChange = value => {
    if (value != "") {
        lastValidYear.value = value
        emit('update:value', value + "-01-01")
    }
    // Make sure we don't get errors from the server
    else emit('update:value', lastValidYear.value + "-01-01")
}

// TODO: Remove these when ESLint is updated to support script setup syntax properly
DateInput, onChange, year
</script>
