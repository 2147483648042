<template>
  <tr
    class="text-gray-900 bg-white border-t dark:bg-gray-900 dark:border-gray-700 dark:text-white"
  >
    <td
      class="py-4 px-6"
      v-for="(field, idx) in fields"
      :key="idx"
      :class="field == 'won' ? 'flex  items-end justify-end  text-right' : 'min-w-40'"
    >
      <p v-show="field != 'won'">
        {{ field == "year" ? formatted(item.date) : item[field] }}
      </p>
      <font-awesome-icon  v-show="field == 'won' && item[field]" :icon="['fas', 'trophy']"  class="h-4 text-gray-900 text-md dark:text-white dark-hover:text-white" aria-hidden="true" />
        
      <div class=" pl-4 flex pr-0" v-show="field == 'won'">
        <button @click="$emit('onSelect', item)">
          <PencilIcon class="h-5 pt-1 w-5 dark:text-white" />
        </button>
        <button @click="$emit('onSelect', item)">
          <TrashIcon class="h-5 pt-1 w-5 dark:text-white" />
        </button>
      </div>
    </td>
  </tr>
</template>
<script>
import { PencilIcon, TrashIcon } from "@heroicons/vue/outline";
export default {
  name: "AwardTableUnit",
  components: { PencilIcon, TrashIcon },
  props: {
    fields: Array,
    item: Object,
    onDelete: Object,
  },
  setup() {
    return {
      formatted(year) {
        var start = new Date(year);
        console.log(year);
        let dates = start.getFullYear();
        console.log("as", dates);
        return dates;
      },
    };
  },
};
</script>
