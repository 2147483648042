/* eslint-disable no-unused-vars */
import UnderscoreToSpace from './UnderscoreToSpace'
import Capitalize from "./Capitalize"


export const AlbumTitlesDisplayFormatter = (constantTitles, userTitles, firstValues)  => {
    /* Format album name options for display */
    // constantTitles come from constants file
    // userTitles are album titles associated with the user
    // firstValues are titles that should be ordered first in the result
    let arr;
    arr = constantTitles.concat(userTitles) // put in one array
    arr.sort()

    if (firstValues) {
        for (let i = firstValues.length-1; i >= 0; i--){
            arr.unshift(firstValues[i])
        }
    }


    arr = arr.map(el => UnderscoreToSpace(el)) // format titles
             .map(el => Capitalize(el))

    arr = [...new Set(arr)] // Only unique values
    
    return arr
}

export const AlbumTitleSaveFormatter = title => {
    /* Convert formatter title back into uppercase / underscore format */ 
    title = title.toUpperCase()
    title = title.replace(" ", '_')
    return title
}


