<template>
  
  <div
    v-if="editAlbums"
    class="bg-white shadow mb-4 m-6 p-10 rounded-lg dark:bg-primaryDark dark:text-white"
  >
    <div class="flex justify-between align-middle">
      <div class="flex flex-row dark:text-white">
        <h2 class="text-2xl font-bold">{{ albumTitle }}</h2>
      </div>
      <div class="50 relative flex justify-between align-middle">
        <div class="text-gray-900 bg-white-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 0H5.94591V5.219H0.5V0ZM10.2983 0H15.7442V5.219H10.2983V0ZM19.2805 0H24.7264V5.219H19.2805V0ZM0.5 9.39H5.94591V14.609H0.5V9.39ZM10.2983 9.39H15.7442V14.609H10.2983V9.39ZM19.2805 9.39H24.7264V14.609H19.2805V9.39ZM0.5 18.781H5.94591V24H0.5V18.781ZM10.2983 18.781H15.7442V24H10.2983V18.781ZM19.2805 18.781H24.7264V24H19.2805V18.781Z"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="text-gray-900 bg-white-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm  px-2 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500">
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.5 2H21.5M1.5 8.66667H21.5M1.5 15.3333H21.5M1.5 22H21.5"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <button
          @click="isToggled = !isToggled"
          class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          type="button"
        >
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 4 15"
          >
            <path
              d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            ></path>
          </svg></button
        ><!-- Dropdown menu -->
        <div
          v-if="isToggled"
          class="absolute right-4 top-12 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600"
        >
          <ul class="py-2 text-sm text-gray-700 dark:text-gray-200">
            <li>
              <a
                href="#"
                @click="albumList(albumID)"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Edit Album</a
              >
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Delete Album</a
              >
            </li>
            <li>
              <a
                href="#"
                class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                >Download Album</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div
    class="py-4 px-5 bg-white dark:bg-primaryDark rounded-lg flex justify-center md:justify-start"
  >
    <div
      v-if="!editAlbums"
      class="grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div
        class="w-52 object-cover h-52 bg-gray-200 rounded text-center py-20 cursor-pointer"
        @click="albumPage"
      >
        Create album +
      </div>
      <div
        v-for="album in albums"
        :key="album.id"
        class="relative w-52 object-cover h-52 bg-gray-200 rounded"
      >
        <div
          class="main_block h-32 2xl:h-72 lg:h-52 contents cursor-pointer flex"
          @click="albumData(album.id)"
        >
          <div
            v-if="albums.length > 0"
            class="hidden action_button absolute object-cover top-2 flex justify-end h-full w-full pr-2"
          >
            <div class="h-40 w-full flex justify-end pb-0.5 z-50">
              <PencilIcon
                @click="albumList(album.id)"
                class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"
              />

              <TrashIcon
              
                class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"
              />
            </div>
          </div>
          <img
            :src="album.photos[0].url"
            :alt="album.photos[0].title"
            class="w-full h-full object-cover rounded-md"
          />
          <div
            class="action_button object-cover bottom-2 text-center pb-0.5 absolute text-gray-50 text-2xl p-2"
          >
            {{ album.title }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="editAlbums"
      class="grid gap-2 sm:gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <div
        class="w-52 object-cover h-52 bg-gray-200 rounded text-center py-20 cursor-pointer"
        @click="albumList(albumID)"
      >
        + Add to Album
      </div>
      <div
        v-for="album in editAlbumsList"
        :key="album.id"
        class="relative w-52 object-cover h-52 bg-gray-200 rounded"
      >
        <div class="main_block h-32 2xl:h-72 lg:h-52 contents cursor-pointer flex">
          <div
            v-if="editAlbumsList.length > 0"
            class="hidden action_button absolute object-cover top-2 flex justify-end h-full w-full pr-2"
          >
            <div class="h-40 w-full flex justify-end pb-0.5 z-50">
              <TrashIcon
              
                class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"
              />
            </div>
          </div>
          <img
            :src="album.url"
            :alt="album.title"
            class="w-full h-full object-cover rounded-md"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon, PencilIcon } from "@heroicons/vue/outline/esm";
import { ref } from "vue";
// import { useRoute } from "vue-router";
export default {
  name: "Albums",
  data() {
    return {
      isToggled: false,
    };
  },
  components: { TrashIcon, PencilIcon },
  setup() {
    const editAlbums = ref(false);
    const editAlbumsList = ref([]);
    const albumTitle = ref("");
    const albumID = ref(false);
    const albums = [
      {
        id: 1,
        title: "Forest",
        photos: [
          {
            id: 1,
            title: "Beach",
            url:
              "https://images.freeimages.com/images/large-previews/9c0/forest-1400475.jpg",
          },
          {
            id: 2,
            title: "Mountain",
            url:
              "https://images.freeimages.com/images/large-previews/8a2/white-trillium-on-forest-floor-1641920.jpg",
          },{
            id:3,
            title:'Forest 123',
            url:'https://images.freeimages.com/images/large-previews/edb/forest-1258904.jpg'
          }
        ],
      },
      {
        id: 2,
        title: "Animal",
        photos: [
          {
            id: 1,
            title: "Gathering",
            url:
              "https://images.freeimages.com/images/large-previews/6a6/deer-in-wild-1641752.jpg",
          },
          {
            id: 2,
            title: "Celebration",
            url:
              "https://images.freeimages.com/images/large-previews/83d/two-young-doe-in-a-field-affectionately-interacting-1640197.jpg",
          },
        ],
      },
    ];
    return {
      albums,
      editAlbums,
      editAlbumsList,
      albumTitle,
      albumID,
      albumData(id) {
        this.editAlbums = true;

        // Find the album with the given ID
        const albumToEdit = albums.find((item) => item.id === id);

        // Assuming editAlbumsList is an array that stores albums available for editing
        editAlbumsList.value = albumToEdit.photos;
        albumID.value = id;
        albumTitle.value = albumToEdit.title;
        console.log("value", editAlbumsList);
      },
    };
  },
  methods: {
    albumPage() {
      this.$router.push({ name: "createAlbums" });
    },
    albumList(id) {
      // console.log('sasdasdas',id)
      this.$router.push({ name: "editAlbums", params: { id: id } });
    },
  },
};
</script>
