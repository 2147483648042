<template>
    <div class="bg-white  dark:bg-primaryDark rounded-md">
        <ProfileNavbar
            class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6"
            @change="changeComponent"
            :navigation="navigation"
            :subNav="false"
        />
        <ProfileSliderMenu
    :editMode="editProfileState"
            class="lg:mt-0 dark:text-white col-start-0 relative col-span-10 md:col-start-0 md:col-span-12 lg:col-start-3 lg:col-span-7 items-center flex items-center lg:justify-between whitespace-pre"
            @change="changeComponent"
            :navigation="navigation"
             version="2"
      />
        <div class="col-start-1 col-span-9 md:col-start-3 md:col-span-7 ">
            <transition name="fade">
                <component :is="currentComponent" />
            </transition>
        </div>
    </div>
</template>
<script>
import localStorage from 'store2'
import { useStore } from "vuex";
import Photos from '@/components/profile/edit/pages/photos/Photos'
import ProfilePicture from '@/components/profile/edit/pages/photos/ProfilePicture'
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import {ref,computed} from "vue";
import HeadShots from '@/components/profile/edit/pages/photos/HeadShots'
import Albums from '@/components/profile/edit/pages/photos/Albums'
import registerEvents from '../../../../utils/DisableSearchBoxInInputs'
import ProfileSliderMenu from "@/components/widgets/profileNavbar/ProfileSliderMenu";
const navigation = ref([
     {
        name: "Photos",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Photos",
        current: true,
    },
    {
        name: "Profile PIctures",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "ProfilePicture",
        current: false,
    },
    {
        name: "Headshots",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "HeadShots",
        current: false,
    },
    {
        name: "Albums",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "Albums",
        current: false,
    }
]);
function onNavClick(activeNode, navigation) {
    for (let i = 0; i < navigation.length; i++) {
        navigation[i].current = false;
    }
    activeNode.current = true;
    setTimeout(registerEvents, 500);
}

function changeCurrentByName(name) {
  for (let i = 0; i < navigation.value.length; i++) {
    let cmp = navigation.value[i];
    cmp.current = (cmp.component === name);
  }
}

export default {
    name: "Photo",
    components: { Photos, ProfileNavbar,  HeadShots,ProfilePicture,ProfileSliderMenu,Albums },
    setup() {
        const store = useStore();
        let savedTab = localStorage.get('PhotosEditTab')
        let currentComponent = ref("Photos");
        if(savedTab !== null) {
          currentComponent.value = savedTab
          changeCurrentByName(savedTab)
        }
        return {
            store,
            navigation,
           currentComponent,
            changeComponent(component) {
                localStorage.set("PhotosEditTab", component)
                currentComponent.value = component;
            },
            editProfileState: computed(() => store.getters.getProfileEditState),
        }
    },
    beforeUnmount() {
        // Reset navbar indicator
        for (let i = 0; i < this.navigation.length; i++) {
            this.navigation[i].current = false;
        }
        this.navigation[0].current = true;
    },
}
</script>

