<template>
<div class="flex flex-col items-end">
    <!-- <button class="text-gray-700 px-1 py-1 border-2 w-36 font-medium
        rounded border-gray-500"
        :class="[customizeActive ? 'bg-gray-100' : 'bg-gray-300']"
        @click="customizeActive=!customizeActive">Customise</button> -->
    <!-- <div class="py-2"></div> -->
    <slide-up-down v-model="customizeActive" :duration="400">
        <CustomizeProfile @onCancel="customizeActive=false"/>
    </slide-up-down>
    <OverviewPage :editMode="true" />
</div>
</template>

<script>
import { ref, watch } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
import CustomizeProfile from '@/components/profile/components/customizeProfile/CustomizeProfile'
import OverviewPage from '@/components/profile/components/overviewPage/OverviewPage'
export default {
    name: "Index",
    components: { CustomizeProfile, SlideUpDown, OverviewPage },
    props: {
        editState: {
            required: false,
            default: false,
            type: Boolean
        }
    },  
    setup(props){
       let customizeActive = ref(false) 

        watch(props,() => {
            customizeActive.value = props.editState
        })
        return {
            customizeActive
        }
    }
}
</script>

