<template>
  <div>

    <div class="flex md:gap-x-4  flex-wrap md:flex-nowrap items-start my-2 text-gray-500 text-left" v-for="(workExperience, idx) in workExperiences" :key="workExperience.id">
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="idx" :value="workExperience.role"
                      @update:value="workExperience.role=$event" :heading="(idx < 1 || workExperiences.length <= 1) ? 'Role' : ''"   />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="workExperience.department"
                      @update:value="workExperience.department=$event" :heading="(idx < 1 || workExperiences.length < 1) ? 'Department' : ''" />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="workExperience.company"
                      @update:value="workExperience.company=$event" :heading="(idx < 1 || workExperiences.length < 1) ? 'Company' : ''" />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="workExperience.venue"
                      @update:value="workExperience.venue=$event" :heading="(idx < 1 || workExperiences.length < 1) ? 'Venue' : ''" />
      <DateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="workExperience.startDate"
                      @update:value="workExperience.startDate=$event" :heading="(idx < 1 || workExperiences.length < 1) ? 'Start Date' : ''" />
      <CurrentDateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="workExperience.endDate"
                      @update:value="workExperience.endDate=$event" :heading="(idx < 1 || workExperiences.length < 1) ? 'End Date' : ''" />
      <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="(idx===0)?'place-self-center':'place-self-top mt-2'" >
        <TrashIcon @click="$emit('onDelete', workExperience.id)" class="w-6 cursor-pointer items-center"/>
      </div>
    </div>
    <div class="flex  flex-wrap md:flex-nowrap md:gap-x-4 items-start my-2 text-gray-500 text-left" v-for="(workExperience, idx) in newWorkExperiences" :key="idx" >
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="idx" :value="workExperience.role"
                      @update:value="workExperience.role=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'Role' : ''"   />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="workExperience.department"
                      @update:value="workExperience.department=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'Department' : ''" />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="workExperience.company"
                      @update:value="workExperience.company=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'Company' : ''"  />
      <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="workExperience.venue"
                      @update:value="workExperience.venue=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'Venue' : ''"  />
      <DateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="workExperience.startDate"
                      @update:value="workExperience.startDate=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'Start Date' : ''"/>
      <CurrentDateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="workExperience.endDate"
                      @update:value="workExperience.endDate=$event" :heading="(idx < 1 && workExperiences.length < 1) ? 'End Date' : ''"/>
       <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="(idx < 1 && workExperiences.length < 1)?'place-self-center':'place-self-top mt-2'">
        <TrashIcon @click="removeNewWorkExperience(idx)" class="w-6 inline-block align-middle items-center "/>
      </div>
     
    </div>
    <button @click="addNewWorkExperiences" class="w-full float-left text-left mt-2 text-gray-500 mb-5">+ Add Work Experience</button>
  </div>
</template>

<script>
import { watch, ref } from 'vue'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import { TrashIcon } from '@heroicons/vue/outline/esm'
import DateInput from "../inputs/DateInput";
import CurrentDateInput from "../inputs/CurrentDateInput";
export default {
  name: "WorkExperienceForm",
  components: {DateInput, 
  CurrentDateInput, 
  SmallTextInput, TrashIcon },
  props: {
    workExperiences: Array,
    submit: Boolean // component will submit forms if this turns true
  },

  setup(props, { emit }) {
    const newWorkExperiences = ref([])

    watch(() => props.submit, (submit) => {
      if(submit){
        emit("onSubmit", newWorkExperiences.value)
        newWorkExperiences.value = []
      }
    })

    return {
      newWorkExperiences,
      addNewWorkExperiences(){
        newWorkExperiences.value.push({
          role:"",
          department:"",
          company:"",
          venue:"",
          startDate: "",
          endDate: "current",
        })
      },
      removeNewWorkExperience(idx){
        const result = confirm("Are you sure you want to delete?")
        if (result) {
          newWorkExperiences.value.splice(idx, 1)
        }}
    }
  }
}
</script>
