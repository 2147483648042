<template>
  <div class="w-full  md:p-0 md:grid grid-cols-6 gap-4">
    <div id="main-content" class="col-span-4">
      <overview-bio
        v-if="toggleCompState('bio')"
        :short="short"
        :shortBio="shortBio"
        :bio="bio"
        :editMode="editProfileState"
      ></overview-bio>
      <div id="images" v-if="toggleCompState('images')" class="mb-4">
        <ImageWidget :editMode="editProfileState" :images="images" />
        <!-- {{ images }} -->
      </div>
      <div id="work experience" v-if="toggleCompState('experience')" class="mb-4">
        <WorkExperience :editMode="editProfileState" />
      </div>
      <div id="education" v-if="toggleCompState('educations')" class="mb-4">
        <Education :editMode="editProfileState" />
      </div>
    </div>
    <div id="side-content" class="col-span-2 flex flex-col gap-4">
      <overview-about
        v-if="toggleCompState('about')"
        :editMode="editProfileState"
        :about="about"
        :userDiversity="userDiversity"
      ></overview-about>
      <Affiliation  :editMode="editProfileState" />
      <overview-skills
        v-if="toggleCompState('skills')  && skills.length >0 "
        :editMode="editProfileState"
        :skills="skills"
      ></overview-skills>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import WorkExperience from "@/components/profile/components/workExperience/WorkExperience";
import ImageWidget from "@/components/profile/components/imageWidget/ImageWidget";
import Education from "../education/education";
import OverviewAbout from "./about/OverviewAbout";
import Affiliation from "./Affiliations/Affiliation";
import OverviewSkills from "./skills/OverviewSkills";
import OverviewBio from "./bio/OverviewBio";

export default {
  name: "OverviewPage",
  components: {
    Education,
    WorkExperience,
    ImageWidget,
    OverviewAbout,
    OverviewSkills,
    Affiliation,
    OverviewBio,
  },
  props: {
    editMode: Boolean,
  },
  methods: {
    toggleCompState(comp) {
      if ((this[comp] && this[comp].length > 0) || this.editProfileState) {
        // if( comp == 'images'){
        //  return this.images.some( i => i.primary ) ? true : true;
        // }
        return true;
      } else if( !Array.isArray(this[comp]) && (this[comp] != null ) ){
        return Object.keys(this[comp]).some( (key) => this[comp][key] != null );
      }
      else {
        return false;
      }
    },
  },
  setup() {
    const store = useStore();
    const short = ref(true);

    return {
      short,
      editProfileState: computed(() => store.getters.getProfileEditState),
      bio: computed(() => store.getters.getProfileBio),
      shortBio: computed(() =>
        store.getters.getProfileBio
          ? store.getters.getProfileBio.substring(0, 500)
          : null
      ),
      experience: computed(() => store.getters.getProfileWorkExperience),
      educations: computed(() => store.getters.getProfileEducation),
      websites: computed(() => store.getters.getProfileWebsites),
      skills: computed(() => store.getters.getProfileSkills),
      images: computed(() => store.getters.getProfileImages),
      about: computed(() => store.getters.getProfileAbout),
      userDiversity: computed(() => store.getters.getUserDiversity),
      openLink(href) {
        if (href.includes("https") || href.includes("http")) {
          window.open(href);
        } else {
          window.open("//" + href);
        }
      },
    };
  },
};
</script>
