<template>
  <side-bar-wrapper @onClick="editActive = true" :showTitleIcon="true" titleIcon="WrenchIcon"
      :editMode="editMode" title="Skills">
    <div class="flex flex-wrap gap-2 mt-4">
      <p
        v-for="(skill, idx) in skills"
        :key="idx"
        class="bg-gray-200 py-1 px-2 text-sm text-gray-800 rounded-lg dark:bg-gray-300"
        >
            {{ skill }}
      </p>
    </div>
  </side-bar-wrapper>
  <overview-skills-edit v-if="editActive" @onClose="editActive = false" :skills="skills"></overview-skills-edit>

</template>

<script>
import SideBarWrapper from "../../common/SideBarWrapper";
import OverviewSkillsEdit from "./OverviewSkillsEdit";
export default {
  name: "OverviewAbout",
  components: {
    SideBarWrapper,
    OverviewSkillsEdit
  },
  data(){
    return {
      editActive: false
    }
  },
  props: {
    skills: {
      required: true,
      type: Array,
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
