<template>
    <div>
      <div class="flex justify-end px-2 text-ml py-2 bg-gray-200 dark:bg-backgroundDark">
        <button type="button" class="bg-white px-2 rounded-l-md" v-if="page !== 1" @click="page--"> <ArrowLeftIcon class="h-3 w-3"/> </button>
        <div class="flex flex-row" v-if="pages.length > 1">
          <div class="border border-black bg-white px-2">{{page}}</div>
          <button type="button" class="bg-white px-2 rounded-r-md" v-if="page < pages.length" @click="page = page+1"> <ArrowRightIcon class="h-3 w-3"/> </button>
        </div>
      </div>
      <div class="overflow-y-scroll no-scroll-bar lg:overflow-hidden border-gray-200 shadow rounded-lg">
        <table class=" bg-primary dark:bg-primaryDark min-w-full divide-y divide-gray-200 dark:divide-gray-800 w-1/2 table-auto">
            <thead class="bg-gray-50 dark:bg-primaryDark ">
                <th @click="onSort('name')" class="px-4 items-center flex flex-row py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark-hover:text-white dark:text-white whitespace-nowrap">⇅ Name / Type</th>
                <th @click="onSort('role')" class="px-4 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark-hover:text-white dark:text-white whitespace-nowrap">⇅ Role</th>
                <th @click="onSort('director')" class="px-4 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark-hover:text-white dark:text-white whitespace-nowrap">⇅ Director</th>
                <th @click="onSort('company')" v-if="screenWidth > 920 || screenWidth < 770" class="px-4 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 dark-hover:text-white uppercase tracking-wider hover:text-black dark:text-white whitespace-nowrap">⇅ Company</th>
                <th @click="onSort('venue')" v-if="screenWidth > 1130" class="px-4 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark-hover:text-white hover:text-black dark:text-white whitespace-nowrap">⇅ Venue / Space</th>
                <th @click="onSort('startDate')" v-if="screenWidth > 1270" class="px-4 py-3 cursor-pointer text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark-hover:text-white hover:text-black dark:text-white whitespace-nowrap">⇅ Time </th>
                <th v-if="editMode" class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark-hover:text-white dark:text-white"></th>
            </thead>
            <tbody>
                <tr v-for="(prod, idx) in displayedProductions" :key="prod.id" :class="idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-100 dark:bg-backgroundDark'" class="py-4 whitespace-normal text-sm font-medium text-gray-900 text-left ">
                  <td class="capitalize  px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white text-left "><p><span  class="font-bold">{{prod.name}}</span><br />{{prod.productionType}}</p> </td>
                    <td class="px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white">{{prod.role}}</td>
                    <td class="px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white ">{{prod.director}}</td>
                    <td v-if="screenWidth > 920 || screenWidth < 770" class="px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white ">
                        <p class="font-bold">{{prod.company}}</p></td>
                  <td v-if="screenWidth > 1130" class="px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white "><p><span class=font-bold>{{prod.venue}}</span><br />{{prod.space}}</p></td>
                    <td v-if="screenWidth > 1270" class="px-4 py-4 whitespace-normal text-sm text-gray-500 dark:text-white">
                        <div class="flex flex-wrap">
                            <p v-if="prod.startDate">{{ formatDate(prod.startDate) }}</p>
                            <p v-if="prod.endDate"> &nbsp; - &nbsp;{{ formatDate(prod.endDate) }}</p>
                        </div>
                    </td>
                    <td v-if="editMode" class="px-4 py-6 flex whitespace-normal cursor-pointer dark:text-gray-200">
                        <PencilIcon class="w-5 mr-3 " @click="$emit('onEdit', prod)"/>
                        <TrashIcon class="w-5 " @click="onDelete(prod.id)" />
                    </td>
                </tr>
            </tbody>
        </table>
      </div>

    </div>
</template>
<script>
import { useStore } from 'vuex'
import {ref, onMounted, onUnmounted,} from 'vue'
import { PencilIcon, TrashIcon, ArrowLeftIcon, ArrowRightIcon} from '@heroicons/vue/outline/esm'
export default {
  async created() {
    this.onSort("startDate")
  },
    name: 'ProductionTable',
    components: { PencilIcon, TrashIcon, ArrowRightIcon, ArrowLeftIcon},
    computed: {
      displayedProductions () {
        return this.paginate(this.profileProductions);
     },
      profileProductions () {
        return this.$store.getters.getProfileProfileProductions
      }
   },
    watch: {
      profileProductions () {
        this.setPages();
    }
  },
    props: {
        editMode: {
            type: Boolean,
            default: true
        }
    },
    data(){
      return{
        page: 1,
        perPage: 10,
        pages: [],
      }
    },
    methods: {
    async onDelete(id){
        const result = confirm("Are you sure you want to delete?")
        if (result) {
          await this.$store.dispatch("deleteProfileProduction", id)
          this.$store.dispatch("fetchBasicProfile");
          this.$store.dispatch("fetchProfile", this.$route.params.id);
        }
      },
      onSort(val){
        this.$store.commit("sortProfileProductions", val)
        this.setPages()
      },
      setPages () {
        let numberOfPages = Math.ceil(this.profileProductions.length / this.perPage);
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate (profileProductions) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return  profileProductions.slice(from, to);
      },
    },
    setup() {
        const store = useStore()
        const screenWidth = ref(window.innerWidth)
        const onWidthChange = () => screenWidth.value = window.innerWidth
        onMounted(() => window.addEventListener("resize", onWidthChange))
        onUnmounted(() => window.removeEventListener("resize", onWidthChange))
        return {
            store,
            screenWidth,
            formatDate(dString){
                const d = new Date(dString)
                const options = {  year: "numeric", month:"short"}
                return d.toLocaleDateString("en-US", options)
            }
        }
    },
}
</script>
