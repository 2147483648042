<template>
    <div class="w-full h-full flex justify-center ">
    <div  class="w-full rounded-lg h-auto p-7  no-scroll-bar overflow-y-scroll overflow-hidden  max-h-100 bg-white  dark:text-white "
          :class="[ form.images.length < 1 || loading ? 'bg-white dark:bg-primaryDark' : '' ]">
 
        <div class="flex flex-col items-end w-full  gap-2">
            <MediaImageDragAndDropField 
                v-if="form.images.length < 1"
                :album="albumType"
                @onChange="form.images=$event"
                @onCancel="$emit('close')"
            />
                     
            <div v-else class="h-full w-full  bg-white ">
                <h1 class="text-2xl font-bold text-black-700 text-center w-full pb-4 pt-2 dark:text-white">
                   Add {{this.$props.albumType}}
                </h1>
                <div class="text-left" v-show="form.images.length > 1">
                <input type="checkbox" class="cursor-pointer" @click="copyTitles"  ref="theCheckbox" >
                <label for="vehicle1"> Image description same as all image description ?</label>
                 </div>
                <MediaImageSlot v-for="img, idx in form.images" :key="img.preview" :idx="idx" :image="img" 
                                @update="updateValue" @delete='deleteSlot(idx)' @onError="img['error']=$event"
                                @fileChange="editImage(idx, $event)"
                                />
                <SaveCancelForm @onSave="submitImages" @onCancel="$emit('close')"  
                                :display="true" justifyOrientation="end" key="saveCancelForm" 
                                class=" dark:bg-gray-800" />
            </div>
        </div> 
    </div>
    </div>
</template>

<script>

import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import MediaImageSlot from '@/components/widgets/mediaImageWidget/MediaImageSlot'
import MediaImageDragAndDropField from '@/components/widgets/mediaImageWidget/MediaImageDragAndDropField'
import SaveCancelForm from '@/components/forms/SaveCancelForm'
import { createToaster } from "@meforma/vue-toaster";

export default {
    name: "MediaImageAddPhoto",
      props: {
        albumType: {
            type: String,
            required: true
        }
    },
    components: { MediaImageSlot, SaveCancelForm, MediaImageDragAndDropField },
    methods: {
        copyTitles() {
        const checkboxChecked = this.$refs.theCheckbox.checked;

            if (checkboxChecked) {
                const firstImage = this.form.images[0];

                for (let i = 1; i < this.form.images.length; i++) {
                const image = this.form.images[i];

                image.title = firstImage.title;
                image.date = firstImage.date;
                image.location = firstImage.location;
                image.photographer = firstImage.photographer;
                image.caption = firstImage.caption;
                }

                console.log(this.form.images);
            } else {
                // Code to clear image titles, dates, locations, photographers, and captions
                for (let i = 0; i < this.form.images.length; i++) {
                const image = this.form.images[i];

                image.title = '';
                image.date = null;
                image.location = '';
                image.photographer = '';
                image.caption = '';
                }

                console.log(this.form.images);
            }
        }

    },
    setup(props, { emit }) {
        const store = useStore()
        const fileHovering = ref(false)
        const loading = ref(false)
        const form = reactive({
            images: []
        })
          
        return {
            form,
            fileHovering,
            loading,
            updateValue(idx, field, value) {
                form.images[idx][field] = value;
            },

            deleteSlot(idx) {
                const toast = createToaster({position:"top-right"});
                form.images.splice(idx, 1);
                toast.success(`Photos has been removed successfully`);
            },

            editImage(idx, file) {
                form.images[idx].file = file;
                form.images[idx].preview = URL.createObjectURL(file)

            },

            async submitImages() {
                const toast = createToaster({position:"top-right"});
                console.log('test image',form.images)
                for( let i = 0; i < form.images.length; i++ ) {
                    let image = form.images[i];
                    if (image.meta.hasError) {
                        continue
                    }                   
                    await store.dispatch('createUserMediaImage', {...image, profileId: store.getters.getId})
                  
                }
              
                for( let i = 0; i < form.images.length; i++ ) {
                    let image = form.images[i];
                    delete image['meta']; 
                    delete image['preview'];
                }
                toast.success(`Photos has been created successfully`);
                emit('close')
            },
          

        }
    }
}
</script>

