<template>
    <div>
        <div v-if="isLoading"        
                class="col-span-3 w-full flex justify-center items-center ">
                <LoadingSpinner  />
            </div>
           
        <Lightgallery  v-else 
        :settings="{ speed: 500, plugins: plugins }"
        :onInit="onInit"
        :onBeforeSlide="onBeforeSlide"
                    ref="gallery"
                    class="lightgallery grid  grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
                    >
                  <a v-for="img in imagesToDisplay" :key="img.id"
                    data-lg-size="400-400"
                    :data-src="img.image"
                    :data-sub-html="getImageMeta(img)"
                    >  
                    <div class="relative main_block flex h-32 2xl:h-72 lg:h-52">
                        <img :src="img.image+'&w=206&h=206'" class="w-full h-full object-cover rounded-md" />
                        <div v-if="this.$props.editMode" class="hidden action_button absolute object-cover top-2 flex justify-end h-full w-full pr-2">
                            <div class="h-5 w-full flex justify-end pb-0.5 ">
                                <PencilIcon @click="onModalButton();edidLink.func(img)" class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5  rounded" />
                                <TrashIcon  @click="link.func(img.uuid,img.album)" class="h-6 cursor-pointer w-6 text-white bg-gray-500 p-1 ml-0.5 rounded"/>
                            </div>
                        </div>
                    </div>    
                    </a>  

                </Lightgallery>

        <div class="w-full flex justify-end mt-4">
            <p  v-if="images.length > DEFAULT_IMG_COUNT" @click="expanded=!expanded" 
               class='text-gray-600 underline cursor-pointer dark:text-gray-400'>
                {{ expanded ? 'Collapse' : 'See more'}}
            </p>
      </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgAutoplay from 'lightgallery/plugins/autoplay';
import lgtThumbnail from 'lightgallery/plugins/thumbnail';
import { TrashIcon, PencilIcon } from '@heroicons/vue/outline/esm'
// import lightGallery from 'lightgallery';

let lightGallerys = null;


export default {
    name: "MediaImageDisplayImages",
    components: {   LoadingSpinner,TrashIcon, PencilIcon,Lightgallery},
    data() {
        return {
            plugins: [lgZoom, lgVideo,lgFullscreen,lgAutoplay,lgtThumbnail], 
        
           }
    },
   
     props: {
        images: {
            type: Array,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        },
        album: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true,
        },
        edidLink: {
            type: Object,
            required: true,
        },
    },
    methods: {
    onInit: (detail) => {
      console.log('lightGallery has been initialized', detail.instance);
     
      lightGallerys = detail.instance;
      lightGallerys.refresh();
    },
    onBeforeSlide: (detail) => {
        const { index, prevIndex } = detail;
            console.log(index, prevIndex);
    },
    onModalButton() {
        this.$nextTick(() => {
        const gallery = this.$refs.gallery;
        console.log('AA',gallery.LG)
        if (gallery) {
            console.log('galleryItems', gallery);
            if (gallery.LG.galleryItems.length > 0) {
                gallery.LG.destroy();
            }
        }
        });
     },
  },
    setup(props) {
        const store = useStore()
        const expanded = ref(false) 
        const imgIdx = ref(0)
        const modalActive = ref(false)
        const DEFAULT_IMG_COUNT = 10

        return {
            expanded,
            modalActive,
            imgIdx,
            DEFAULT_IMG_COUNT,
            isLoading: computed(() => store.getters.isLoading),
            onModalActivate(idx) {
                imgIdx.value = idx 
                modalActive.value = true;
            }, 
            imagesToDisplay: computed(() => expanded.value
                    ? props.images 
                    : props.images.slice(0, DEFAULT_IMG_COUNT)),
            croppedOverRegular: computed(() => {
                // Try cropped first
                return props.image.croppedImage || props.image.image
            }),
               getImageMeta(img){
                return "<h4>Title "+img.title+"</h4><p> Caption "+img.caption+"</p><p> Photographer "+img.photographer+"</p><p> Location "+img.location+"</p>";
            }
        
        }
    }
}
</script>

<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-thumbnail.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-autoplay.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-rotate.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-fullscreen.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-pager.css');
body {
  margin: 0;
}
.gallery-item {
  margin: 5px;
}
</style>