<template>
    <div>
      <div class="bg-white shadow  mb-4 py-5 px-6  rounded-lg dark:bg-primaryDark dark:text-white">
        <div class="flex justify-between mb-2">
            <div class="flex">
                <CameraIcon class="w-4 mr-2 text-gray-400 dark:text-white" />
                <p class="text-sm font-medium">Photos</p>
            </div>
            <div class="flex" v-if="editMode">
                <button  @click="photoPickerOn=!photoPickerOn" class=" flex text-gray-600 dark:text-gray-200 edit_button">
                <span> <PencilIcon class="w-4 mt-1" /> </span>
                <!-- Customize photos  -->
                </button>
            </div>
        </div>
        <div class="grid grid-cols-4 gap-4  justify-evenly">
            <div class=" relative " v-for="img in firstFourPictures" :key="img.id">
                 <img 
                   
                    :src="img.image" 
                    class="object-cover aspect-square h-full w-full relative"/>
            </div>
        </div>
        <SlideUpDown v-model="notFirstFour" >
        <div class="grid grid-cols-4 gap-4  justify-evenly">
            <img 
                v-for="img in notFirstFourPictures" 
                :key="img.id" 
              
                class=" w-auto h-full object-cover col-span-1 relative" 
                :src="img.image" />
        </div>
        </SlideUpDown>
      </div>
        <Modal :modalActive="photoPickerOn" @close="photoPickerOn=false">
            <PhotoPicker @submit="submitMainImages" @close="photoPickerOn=false" v-if="photoPickerOn" 
                :images="userPhotos" :max="4" header="Select 4 images to display on your profile" />
        </Modal>
        <MediaImageModalWindow
                :modalActive="modalActive"
                :curIdx="idx"
                :images="images"
                @close="modalActive=false"
        />
    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import SlideUpDown from 'vue3-slide-up-down'
import { CameraIcon, PencilIcon } from "@heroicons/vue/outline/esm"
import PhotoPicker from '@/components/widgets/photoPicker/PhotoPicker'
import MediaImageModalWindow from '@/components/widgets/mediaImageWidget/MediaImageModalWindow'
import Modal from '@/components/widgets/Modal'
export default {
    name: "ImageWidget",
    components: {CameraIcon, PencilIcon, SlideUpDown, Modal, PhotoPicker, MediaImageModalWindow },
    props: {
        images: Array,
        editMode: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore()
        const firstFour = ref(true) 
        const photoPickerOn = ref(false)
        const modalActive = ref(false)
        const idx = ref(0)
        return {
            firstFour,
            modalActive,
            idx,
            firstFourPictures: computed(() => props.images.filter(i => i.primary) ),
            notFirstFour: computed(() => !firstFour.value),
            notFirstFourPictures: computed(() => props.images.filter(i => !i.primary)),
            photoPickerOn,
            userPhotos: store.getters.getProfileImages,
            async submitMainImages(img){
                const imageUuids = img.map(i => i.uuid)
                await store.dispatch("setPrimaryMediaImages", {uuids:imageUuids, profileId:store.getters.getId})
                await store.dispatch("fetchProfile", store.getters.getId);
            },
            activateModal(i) {
              idx.value = i
              modalActive.value = true
            }
        }
    }
}
</script>