<template>
<div class="bg-white shadow-lg p-8 mb-4 rounded-lg dark:bg-primaryDark dark:text-white">
    <div id="bio" class="mb-10">
        <div class="flex">
            <UserIcon class="w-4 mr-2"/>
            <p class="font-medium text-sm">Profile Bio</p>
        </div>
        <LargeTextInput :counting="true" :value="form.bio" @update:value="form.bio=$event" />
    </div>
    <div id="toggles" class="mb-10">
        <p class="font-medium text-sm">Show in overview</p>
        <div class="flex flex-wrap gap-3">
            <OnOffSwitch @update:value="form.overviewSettings.showPronouns=$event" :on="form.overviewSettings.showPronouns" label="Pronouns"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showNationality=$event" :on="form.overviewSettings.showNationality" label="Nationality"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showEthnicity=$event" :on="form.overviewSettings.showEthnicity" label="Ethnicity"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showReligion=$event" :on="form.overviewSettings.showReligion" label="Religion"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showDateOfBirth=$event" :on="form.overviewSettings.showDateOfBirth" label="Date of birth"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showPlaceOfBirth=$event" :on="form.overviewSettings.showPlaceOfBirth" label="Place of birth"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showGender=$event" :on="form.overviewSettings.showGender" label="Gender"/> 
            <OnOffSwitch @update:value="form.overviewSettings.showDisabilities=$event" :on="form.overviewSettings.showDisabilities" label="Disabilities"/> 
        </div>
    </div>
  <div id="workExperience" class="mb-10" >
    <div class="flex mb-4">
      <UsersIcon class="w-4 mr-2" />
      <p class="font-medium text-sm">Work Experience</p>
    </div>
    <WorkExperienceForm @onSubmit="pushNewWorkExperiences" @onDelete="deleteWorkExperience" :work-experiences="form.workExperiences" :submit="submit"/>
  </div>
    <div id="education" class="mb-10" >
        <div class="flex mb-4">
            <AcademicCapIcon class="w-4 mr-2" />
            <p class="font-medium text-sm">Education</p>
        </div>
        <EducationForm @onSubmit="pushNewEducations" @onDelete="deleteEducation" :educations="form.educations" :submit="submit"/>
    </div>
    <SaveCancelForm @onCancel="$emit('onCancel')" @onSave="submitForm"  :display="true" justifyOrientation="end"/>
</div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive, ref } from 'vue'
import { UserIcon,AcademicCapIcon,UsersIcon } from '@heroicons/vue/outline/esm'
import OnOffSwitch from '@/components/inputs/OnOffSwitch'
import SaveCancelForm from '@/components/forms/SaveCancelForm'
import LargeTextInput from '@/components/inputs/LargeTextInput'
import EducationForm from '@/components/forms/EducationForm'
import WorkExperienceForm from "../../../forms/WorkExperienceForm";

export default {
    name: "CustomizeProfile",
    components: {
      WorkExperienceForm, LargeTextInput, OnOffSwitch, UserIcon, UsersIcon,
    SaveCancelForm, EducationForm, AcademicCapIcon,
    },
    setup(){
        const store = useStore()
        const submit = ref(false)
        const currExp = ref(null)
        // const overviewSettings = store.getters.getUserOverviewSettings
            
        const form = reactive({
            bio: store.getters.getUserBio,
            websites: store.getters.getUserWebsites,
            educations: store.getters.getUserEducations,
            workExperiences: store.getters.getUserWorkExperience,
            overviewSettings: {
                // showPronouns: overviewSettings.showPronouns,
                // showNationality: overviewSettings.showNationality,
                // showEthnicity: overviewSettings.showEthnicity,
                // showReligion: overviewSettings.showReligion,
                // showDateOfBirth: overviewSettings.showDateOfBirth,
                // showPlaceOfBirth: overviewSettings.showPlaceOfBirth,
                // showGender: overviewSettings.showGender,
                // showDisabilities: overviewSettings.showDisabilities
            }
        })
        
        return {
            form,
            store,
            submit,
            currExp,
            pushNewEducations(payload){
                for(let idx in payload){
                    form.educations.push(payload[idx])
                }
            },
            pushNewWorkExperiences(payload){
              for(let idx in payload){
                form.workExperiences.push(payload[idx])
              }
            },
            async deleteWebsite(id){
                await store.dispatch("deleteWebsite", id)
                form.websites = form.websites.filter(e => e.id !== id)
            },
            async deleteEducation(id){
              const result = confirm("Are you sure you want to delete?")
              if (result) {
                await store.dispatch("deleteEducation", id)
                form.educations = form.educations.filter(e => e.id !== id)
              }
            },
            async deleteWorkExperience(id){
              const result = confirm("Are you sure you want to delete?")
              if (result) {
                await store.dispatch("deleteWorkExperience", id)
                form.workExperiences = form.workExperiences.filter(e => e.id !== id)
              }
            },
        }

    },
    methods: {
        async submitForm(){
            this.submit = true
            await this.store.dispatch("editProfile", {bio:this.form.bio})
            for (let i in this.form.educations) {
                let education = this.form.educations[i]
                if (education.id === undefined || education.id === null){
                    await this.store.dispatch("createEducation", {...education, profileId:this.store.getters.getId})
                    this.form.educations.splice(i, 1)
                } else {
                    await this.store.dispatch("editEducation", education)
                }
            }
            for (let i in this.form.workExperiences) {
              let workExperience = this.form.workExperiences[i]
              if (workExperience.id === undefined || workExperience.id === null){
                await this.store.dispatch("createWorkExperience", {...workExperience, profileId:this.store.getters.getId})
                this.form.workExperiences.splice(i, 1)
              } else {
                await this.store.dispatch("editWorkExperience", workExperience)
              }
          }
            await this.store.dispatch('editProfileOverviewSettings', this.form.overviewSettings)
            await this.store.dispatch("fetchProfile", this.store.getters.getId)
            this.$emit("onCancel")
        }
    }
}
</script>
