<template>
  <div
    class="w-100  py-5 px-6  bg-primary rounded-lg shadow
      dark:bg-primaryDark dark:text-white  overflow-x-auto"
  >
    <div class="flex justify-between col-span-4 mb-2 ">
      <div class="flex">
        <UsersIcon class="w-4 mr-2 text-gray-400 dark:text-white" />
        <p class="text-sm font-medium ">Work Experience</p>
      </div>
      <div class="edit_button text-gray-600 dark:text-gray-200">
        <button class="flex" v-if="editMode" @click="editActive = true">
          <span> <PencilIcon class="w-4 mt-1" /> </span>
          <!-- Edit -->
        </button>
      </div>
    </div>
    <Modal
      :modalActive="editActive"
      :duration="400"
      version="4"
      @close="editActive = false"
    >
      <div id="workExperience" class="no-scroll-bar rounded-lg bg-primary dark:bg-primaryDark text-gray-600 dark:text-gray-200 p-5 md:p-12 overflow-y-scroll overflow-hidden h-auto max-h-100 lg:max-h-auto">
        <div class="flex mb-4 items-center">
          <UsersIcon class="w-4 mr-2" />
          <p class="text-2xl text-left ml-2 font-medium">Edit Work Experience</p>
        </div>
        <WorkExperienceForm
          @onSubmit="pushNewWorkExperiences"
          @onDelete="onDelete"
          :work-experiences="experience"
          :submit="submit"
        />
        <SaveCancelForm
          @onSave="submitWorkExperience"
          class="col-span-2 "
          @onCancel="editActive = false"
          justifyOrientation="end"
          :display="true"
        />
      </div>
    </Modal>
    <div class="overflow-x-auto w-100 overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg dark-border">
    <table
      class="col-span-4 font-medium bg-primary dark:bg-primaryDark min-w-full divide-y divide-gray-200 dark:divide-gray-800 w-1/2 table-auto overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg "
    >
      <thead class="bg-gray-50 dark:bg-primaryDark">
        <tr
          class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-white"
        >
          <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">Role
            <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
          </th>
          <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">Department
            <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
          </th>
          <th  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">Company / Venue
            <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg></th>
          <th  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">Year
            <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg></th>
          <th v-if="editMode && fela"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(exp, idx) in experience"
          :key="exp.id"
          :class="
            idx % 2 === 0
              ? 'bg-white dark:bg-primaryDark'
              : 'bg-gray-100 dark:bg-backgroundDark'
          "
          class="whitespace-normal text-sm text-gray-500 dark-hover:bg-gray-700 dark:text-white"
        >
          <td class="px-4 py-3 text-sm ">{{ exp.role }}</td>
          <td class="px-4 py-3 text-sm ">{{ exp.department }}</td>
          <td class="flex flex-wrap px-4 py-3">
            <p>{{ exp.company }}</p>
            <p v-if="exp.venue" class="text-gray-400">
              &nbsp; / &nbsp;{{ exp.venue }}
            </p>
          </td>
          <!--v-if="screenWidth > 1280"-->
          <td class="px-4 py-3 text-sm" >
            {{ getYearFromString(exp.startDate) }}
          </td>
          <td
            v-if="editMode && fela"
            class="flex px-4 p-4 flex whitespace-normal text-gray-800 dark:text-gray-200"
          >
            <PencilIcon
              class="w-5 mr-3 cursor-pointer"
              @click="editWorkExperience(exp)"
            />
            <TrashIcon class="w-5 cursor-pointer" @click="onDelete(exp.id)" />
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>
<script>
import { UsersIcon, PencilIcon, TrashIcon } from "@heroicons/vue/outline/esm";
import { ref, computed, onMounted, onUnmounted } from "vue";
import Modal from "@/components/widgets/Modal";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import WorkExperienceForm from "../../../forms/WorkExperienceForm";
export default {
  async created() {
    this.experience = computed(() =>
      this.$store.getters.getProfileWorkExperience.sort(function(a, b) {
        return a.startDate > b.startDate
          ? -1
          : b.startDate > a.startDate
          ? 1
          : 0;
      })
    );
  },
  name: "WorkExperience",
  components: {
    UsersIcon,
    WorkExperienceForm,
    PencilIcon,
    TrashIcon,
    Modal,
    SaveCancelForm,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      experience: [],
      submit: false,
    };
  },
  methods: {
    async submitWorkExperience() {
      this.submit = true;
      this.experience.map(async (exp) => {
        if (exp.id === undefined || exp.id === null) {
          await this.$store.dispatch("createWorkExperience", {
            ...exp,
            profileId: this.$store.getters.getId,
          });
        } else {
          await this.$store.dispatch("editWorkExperience", exp);
        }
      });
      await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
      this.editActive = false;
      this.submit = false;
    },
    async pushNewWorkExperiences(payload) {
      // this.submit = true;
      payload.map((exp) => {
        this.experience.push(exp);
      });
      await this.submitWorkExperience();
    },
    async onDelete(id) {
      //const result = confirm("Are you sure you want to delete?");
        let result = true;
      if (result) {
        await this.$store.dispatch("editWorkExperience", { id, delete: true });
        await this.$store.dispatch("fetchProfile", this.$store.getters.getId);
      //this.editActive = false;
      }
    },
  },
  setup() {
    const editActive = ref(false);
    const currExp = ref(null);
    const fela = ref(false);
    const screenWidth = ref(window.innerWidth);
    const onWidthChange = () => (screenWidth.value = window.innerWidth);
    onMounted(() => window.addEventListener("resize", onWidthChange));
    onUnmounted(() => window.removeEventListener("resize", onWidthChange));
    return {
      screenWidth,
      editActive,
      fela,
      currExp,
      getYearFromString(s) {
        if (s === null) return " - ";
        return new Date(s).getFullYear();
      },
      editWorkExperience(exp) {
        currExp.value = exp;
        editActive.value = true;
      },
    };
  },
};
</script>