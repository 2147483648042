<template>
  <div>
    <div class="flex m-1" v-if="editable">
      <SmallTextInput
        v-if="inputType == 'text'"
        class="col-span-2 md:col-span-1"
        :placeholder="title"
        :value="value"
        version="3"
        @update:value="updatedValue = $event"
      />
      <DateInput
        v-if="inputType == 'date'"
        class="col-span-2 md:col-span-1"
        :placeholder="title"
        :value="value"
        version="2"
        @update:value="updatedValue = $event"
      />
      <DropdownWithCreate
        v-if="inputType == 'dropdown'"
        :showTitle="false"
        :value="value"
        version="2"
        @update:value="updatedValue = $event"
        :options="options"
        class=""
      />
      <MultiWithCreate
        v-if="inputType == 'dropdownMulti'"
        :showTitle="false"
        :options="options"
        :value="value"
        :isLeft="true"
        version="2"
        dropdownStyle="bg-white overflow-auto min-w-46 border-2 w-full max-h-24 z-10 dark:bg-primaryDark dark:text-white"
        @update:value="updatedValue = $event"
        @onDelete="$emit('onDelete', $event)"
        class=""
      />      
      <RefreshIcon @click="hideEditable" class="w-6 mt-1 h-12 ml-1 mr-1 cursor-pointer" />

    </div>
    <div v-else class="flex m-1">
      <OnOffSwitch
        @update:value="isActive = $event"
        :on="isActive"
        :label="title"
        version="2"
      />
      <PencilIcon @click="makeEditable" class="w-6 mt-1 mr-1 ml-1 cursor-pointer" />
    </div>
  </div>
</template>

<script>
import OnOffSwitch from "@/components/inputs/OnOffSwitch";
import { PencilIcon , RefreshIcon } from "@heroicons/vue/outline";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import DropdownWithCreate from "@/components/inputs/DropdownWithCreate";
import MultiWithCreate from "@/components/inputs/MultiWithCreate";
export default {
  name: "EditAboutTab",
  components: {
    OnOffSwitch,
    PencilIcon,
    SmallTextInput,
    DateInput,
    DropdownWithCreate,
    MultiWithCreate,
    RefreshIcon
  },
  data() {
    return {
      editable: false,
      updatedValue: "",
      isActive: this.active,
    };
  },
  watch: {
    updatedValue() {
      this.$emit("onUpdate", this.updatedValue);
    },
    isActive() {
      this.$emit("onSwitch", this.isActive);
    },
  },
  props: {
    title: {
      required: true,
    },
    value: {
      required: true,
    },
    active: {
      required: true,
    },
    inputType: {
      required: true,
    },
    options: {
      required: false,
    },
  },
  methods: {
    makeEditable() {
      this.editable = true;
    },
    hideEditable() {
      this.editable = false;
    },
  },
};
</script>
