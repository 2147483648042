<template>
  <ContentWrapper @onClick="editActive = true" :contentClass="true" :editMode="editMode" :showTitleIcon="false"  v-if="getCompanies.length >0" title="Affiliations"
    titleIcon="UserIcon">
    <div class="about_info dark:text-white">
      <ul class="max-w-md flex flex-col gap-4 mt-4">
          <li class="" v-for="member in firstFourPictures " :key="member.id">
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
                  <img class="w-12 h-12 rounded-full"  :src="member.logo" :alt="member.name">
              </div>
              <div class="flex-1 min-w-0">
                  <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                    <router-link :to="{ name: 'Company', params: { id: member.id } }"> {{member.name}}</router-link>
                  </p>
                  <!-- <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    test@gmail.com  
                  </p> -->
              </div>
            </div>
        </li>
     
        <SlideUpDown v-model="notFirstFour" class="flex flex-col gap-4" v-if="getCompanies.length >4">
          <li class="" v-for="member in notFirstFourPictures" :key="member.id">
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
                  <img class="w-12 h-12 rounded-full" :src="member.logo" alt="{{member.name}}">
              </div>
              <div class="flex-1 min-w-0">
                  <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                    <router-link :to="{ name: 'Company', params: { id: member.id } }"> {{member.name}}</router-link>
                  </p>
                  <!-- <p class="text-sm text-gray-500 truncate dark:text-gray-400">
                    test@gmail.com  
                  </p> -->
              </div>
            </div>
        </li>
            </SlideUpDown>
      </ul>
      <div class="w-full " v-if="getCompanies.length > 4">
                <button @click="firstFour = !firstFour" type="button"
                    class="w-full text-right items-center  text-sm font-medium rounded underline bg-white focus:outline-none dark:bg-primaryDark dark:text-white grey-slate text-sm">
                    {{ seeMore }}
                </button>
            </div>
    </div>
  </ContentWrapper>

</template>

<script>
import ContentWrapper from "@/components/widgets/ContentWrapper";
import { computed,ref } from "vue";
import { useStore } from 'vuex'
import SlideUpDown from 'vue3-slide-up-down'
export default {
  name: "Affiliation",
  components: {
    ContentWrapper,
    SlideUpDown
  },
  data() {
    return {
      editActive: false,
    
    }

  },
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const firstFour = ref(true)
     store.dispatch('fetchCompanies', {first:100, after:"", before:"", searchFilter:"", sortBy:'', name:'', level:'', type:''})
    return{
      store,
      firstFour,
      notFirstFour: computed(() => !firstFour.value),
      getCompanies: computed(() => store.getters.getAffiliateCompanies),
    }
  },
  computed: {
    seeMore() {
            if (this.firstFour) return "See more"
            return "See less"
        },
        firstFourPictures() {
            let newImage= this.getCompanies;
            let newArr = []
     
              for(let i = 0; i < newImage.length; i++){
                if(newArr.length <4){
                  newArr.push(newImage[i])
                }
              }
       
              return newArr
        },
        notFirstFourPictures() {
          let newImage= this.getCompanies;
           
              return newImage.slice(4)
        }
  }
};
</script>
