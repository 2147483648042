<template>
  <div class=" py-5 px-6  bg-primary rounded-lg shadow
      dark:bg-primaryDark dark:text-white">
    <div class="flex justify-between col-span-4 mb-2">
        <div class="flex">
          <AcademicCapIcon class="w-4 mr-2 text-gray-400 dark:text-white" />
          <p class="text-sm font-medium"> Education</p>
        </div>
        <div class="edit_button text-gray-600 dark:text-gray-200" >
          <button @click="editActive=true" v-if="editMode" class="flex"> <span>
            <PencilIcon class="w-4 mt-1" /> </span
            >
            <!-- Edit -->
            </button>
        </div>
    </div>
    <education-edit v-if="editActive" @onClose="editActive = false" :educations="education"></education-edit>
<div class="overflow-hidden lg:overflow-hidden overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg lg:over-x-hidden no-scroll-bar dark-border">
    <table class="col-span-4 font-medium bg-primary dark:bg-primaryDark min-w-full divide-y divide-gray-200 dark:divide-gray-800 w-1/2 table-auto overflow-hidden  ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <thead class="bg-gray-50 dark:bg-primaryDark">
      <tr class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-white">
        <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">
          Degree <svg width="8"
            class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg></th>
        <th class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap">Institution
          <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
        </th>
        <th  class="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white whitespace-nowrap" >Year
          <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                            </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(edu,idx) in education" :key="edu.id" :class="idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-100 dark:bg-backgroundDark'" class="whitespace-normal dark-hover:bg-gray-700 text-sm text-gray-500 dark:text-white">
        <td class="px-4 py-3 text-sm capitalize">{{edu.degree}}</td>
        <td class="px-4 py-3 text-sm capitalize">{{edu.institution}}</td>
        <td  class="flex flex-wrap px-4 py-3 text-sm">
          <p>{{getYearFromString(edu.yearStarted)}}</p>
          <p v-if="edu.yearFinished" class="text-gray-400">&nbsp; - &nbsp;{{getYearFromString(edu.yearFinished)}}</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>
<script>
import { AcademicCapIcon , PencilIcon } from '@heroicons/vue/outline/esm'
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import EducationEdit from './EducationEdit'
export default {
  async created(){
    this.education = computed(() => this.$store.getters.getProfileEducation.sort(function(a,b) {return (a.yearFinished > b.yearFinished) ? -1 : ((b.yearFinished > a.yearFinished) ? 1 : 0);} ))
  },
  name: "education",
  components: { AcademicCapIcon , PencilIcon , EducationEdit },
  props: {
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      education: [],
      editActive: false
    }
  },
  setup() {
    const currEd = ref(null)
    const store = useStore()
    const screenWidth = ref(window.innerWidth)
    const onWidthChange = () => screenWidth.value = window.innerWidth
    onMounted(() => window.addEventListener("resize", onWidthChange))
    onUnmounted(() => window.removeEventListener("resize", onWidthChange))
    return {
      screenWidth,
      currEd,
      async submitEducation(edu){
        if(edu.id === null){
          await store.dispatch('createEducation', edu)
        } else {
          await store.dispatch('editEducation', edu)
        }
        await store.dispatch("fetchProfileEducation", store.getters.getId)
        currEd.value = null
      },
      async onDelete(id){
        const result = confirm("Are you sure you want to delete?")
        if (result) {
          await store.dispatch("editEducation", {id, delete: true})
          await store.dispatch("fetchProfileEducation", store.getters.getId)
        }
      },
      getYearFromString(s){
        if(s === null) return ' - '
        return (new Date(s).getFullYear())
      },
      editEducation(edu){
        currEd.value = edu
      }
    }
  }
}
</script>