<template>
  <div>
  
    <div v-if="!isMobile">
      <p class="whitespace-nowrap text-lg pr-2 font-semibold" >Travel hubs</p>
       <div class="w-full flex items-start mt-5">
          <div class="flex md:gap-x-4  flex-wrap md:flex-nowrap items-start my-2 text-gray-500 text-left" >
              <SelectInput heading="Type"  :tooltip="true"  textFormate="capitalize"
                    toolText="Type" class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                    :options="HubType"
                     />
                     <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1" 
                              heading="Name" toolText="Name" :tooltip="true" />
           
              <SelectInput heading="Country"  :tooltip="true"  textFormate="capitalize"
                toolText="Country" class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
                :options="countries"
              />
              <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1" 
                              heading="City" toolText="City" :tooltip="true" />
           
      
              <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 place-self-top mt-6 flex-none" >
                <TrashIcon @click="$emit('onDelete', 1)" class="w-6 cursor-pointer place-self-center"/>
              </div>

              
            </div>
           
         </div>
         <button  class="w-full float-left text-left mt-2 text-gray-500 mb-5">+ Add new line</button>
     </div>
    <app-accordion class=""  v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center" v-if="!custom">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold" >Travel hubs</p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
     <div class="w-full grid" :class="outerClasses">
       
      <SelectInput heading="Type"  :tooltip="true"  textFormate="capitalize"
                    toolText="Type" class="col-span-2 md:col-span-1 my-1"
                    :options="HubType"
                     />
                     <SmallTextInput class="col-span-2 md:col-span-1 my-1" 
                              heading="Name" toolText="Name" :tooltip="true" />
           
              <SelectInput heading="Country"  :tooltip="true"  textFormate="capitalize"
                toolText="Country" class="col-span-2 md:col-span-1 my-1"
                :options="countries"
              />
              <SmallTextInput class="col-span-2 md:col-span-1 my-1" 
                              heading="City" toolText="City" :tooltip="true" />
      
              <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 place-self-top mt-5" >
                <TrashIcon @click="$emit('onDelete', 1)" class="w-6 cursor-pointer place-self-center"/>
              </div>
            
      </div>
      <button  class="w-full float-left text-left mt-2 text-gray-500 mb-5">+ Add new line</button>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SelectInput from "@/components/inputs/SelectInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'

import { useStore } from "vuex";
import { computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints"
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import SmallTextInput from '@/components/inputs/SmallTextInput'
import { TrashIcon } from '@heroicons/vue/outline/esm'
// import DateInput from "../inputs/DateInput";
// import { UserIcon } from "@heroicons/vue/outline/esm";

export default {
  name: "TravelHubs",
  components: {  AppAccordion, SelectInput,SmallTextInput,TrashIcon  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints()
    const HubType=['Airport','Train station','Bus terminal','Lake port','Sea port'];
    return {
      store,
      isMobile: computed(() => width.value < 1024),
      countries: computed(() => store.getters.getCountries),
      HubType,
    
    };
  },
};
</script>
