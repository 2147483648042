<template>
<div>
    <LoadingSpinner v-if="isLoading" />
    <div v-else class="">
        <button
            class="bg-gray-500 px-4 py-2 text-white mb-4 rounded font-sm"
            @click="$refs.file.click()"
        >Add photo</button>
        <div class="w-full grid-cols-2 grid sm:grid-cols-3 md:grid-cols-5 gap-10">
            <div
                v-for="img of album"
                :key="img.id"
                class="col-span-1 relative object-cover h-full"
            >
                <img
                    class="relative object-cover w-full h-auto aspect-square object-cover"
                    :src="img.image"
                />
                <div class="absolute object-cover top-0 flex justify-end w-full" >
                    <div class="text-white text-2xl flex justify-end">
                        <p
                            @click="onDelete(img.uuid)"
                            class="mr-2 cursor-pointer">X</p>
                    </div>
                </div>
            </div>
        </div>
        <input @change="fileChange($event.target.name, $event.target.files)"
               name="file-input" ref="file" type="file" class="hidden" multiple/>
    </div>
</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import LoadingSpinner from '@/components/widgets/LoadingSpinner'
import { PhotoAlbumNames } from '@/constants'
export default {
    name: "ProgrammeHeadshots",
    components:{LoadingSpinner},
    setup() {
        const store = useStore()
        store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)

        return {
            isLoading: computed(() => store.getters.isLoading),
            album: computed(() => store.getters.getUserAlbum(PhotoAlbumNames.Headshots)),
            async fileChange(_, files) {
                await store.dispatch("createMediaImage",
                    {file: files[0],
                             profileId: store.getters.getId,
                             album: "Headshots"} )

                await store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)
            },
            async onDelete(uuid) {
                await store.dispatch("deleteMediaImage", uuid)
                await store.dispatch("fetchUserAlbum", PhotoAlbumNames.Headshots)
            }
        }
    }
}
</script>
