<template>
  <div>
    <div v-if="version == '1'">
      <modal :modalActive="widgetOn" :duration="400" version="2">
        <ProductionWidget
          :add="add"
          :widgetOn="widgetOn"
          :currentProduction="currentProduction"
          @onCancel="
            widgetOn = false;
            currentProduction = null;
          "
     
          @onSubmit="submitNewProduction"
        />
        </modal>
    </div>
    <div v-else>
      <modal :modalActive="widgetOn" :duration="400" version="2">
        <div class="rounded-xl">
          <ProductionWidget
            :add="add"
            :widgetOn="widgetOn"
            :currentProduction="currentProduction"
            @onCancel="
              widgetOn = false;
              currentProduction = null;
              $emit('onClose');
            "
            @onSubmit="submitNewProduction"
          />
        </div>
      </modal>
    </div>
    <ProductionTable @onEdit="editProduction"  :editMode="isUserAuthorizedToEdit"/>
  </div>
</template>

<script>
import { ref, watch } from "vue";
// import { useStore } from "vuex";
// import SlideUpDown from "vue3-slide-up-down";
import ProductionWidget from "@/components/profile/components/productionWidget/ProductionWidget";
import ProductionTable from "@/components/profile/components/productionTable/ProductionTable";
import Modal from "../../../widgets/Modal.vue";
import { mapGetters } from "vuex";
export default {
  name: "Experience",
  components: {
    ProductionWidget,
    // SlideUpDown,
    ProductionTable,
    Modal,
  },
  computed: {
    ...mapGetters(['isUserAuthorizedToEdit'])
  },
  props: {
    editState: {
      required: false,
      default: false,
      type: Boolean,
    },
    version: {
      required: false,
      default: "1",
    },
  },
  methods: {
    async submitNewProduction(prod, closeWidget = true) {
      console.log(closeWidget);

      if (prod.id !== undefined && prod.id !== null) {
        await this.$store.dispatch("editProfileProduction", prod);
      } else {
        await this.$store.dispatch("createProfileProduction", prod);
      }
      if (closeWidget) {
        this.widgetOn = false;
        this.$emit("onClose");
      }
      this.currentProduction = null;
      this.$store.dispatch("fetchProfile", this.$store.getters.getId);
    },
  },
  setup(props) {
    const widgetOn = ref(false);
    const add = ref(false);
    const currentProduction = ref(null);
    // const store = useStore();
    watch(props, () => {
      add.value = props.editState;
      widgetOn.value = props.editState;
    });

    return {
      widgetOn,
      add,
      currentProduction,
      editProduction(prod) {
        currentProduction.value = prod;
        widgetOn.value = true;
        add.value = false;
      },
    };
  },
};
</script>
