<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
         </div>
         
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <ProfileSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div class="bg-white rounded-lg dark:bg-primaryDark px-5 sm:px-12 py-9">
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  :icon="['fas', 'wrench']"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Skills
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class=" w-10 my-1" /> -->

                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>
              <!-- <div v-if="!isMobile">
                <p
                  class="mb-3.5 mt-7 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                >
                  Jobs / Roles
                </p>

                <div class="mt-2">
                  <MultiFieldInput
                    :suggestions="jobSuggestion"
                    :elements="secondaryJobs"
                    @onChange="searchJobs"
                    @addElement="addSecondaryJob"
                    @removeElement="deleteSecondaryJob"
                  />
                </div>
              </div> -->
              <!-- <app-accordion class="mt-0" v-if="isMobile">
                <template class="mt-0" v-slot:title>
                  <div class="flex items-center mt-0">
                    <p class="whitespace-nowrap pr-2 mt-0 font-semibold text-lg">
                      Jobs / Roles
                    </p>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mt-2">
                    <MultiFieldInput
                      :suggestions="jobSuggestion"
                      :elements="secondaryJobs"
                      @onChange="searchJobs"
                      @addElement="addSecondaryJob"
                      @removeElement="deleteSecondaryJob"
                    />
                  </div>
                </template>
              </app-accordion> -->
              <div v-if="!isMobile">
                <p
                  class="mb-3.5 mt-7 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                >
                  Other Skills
                </p>
                <div class="mt-2">
                  <MultiFieldInput
                    :suggestions="skillSuggestion"
                    :elements="skills"
                    @onChange="searchSkills"
                    @addElement="addSkill"
                    @removeElement="removeSkill"
                  />
                </div>
              </div>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <div class="flex items-center">
                    <p class="whitespace-nowrap pr-2 font-medium text-lg">Other Skills</p>
                    <!-- <div class="h-px w-full bg-gray-400"></div> -->
                  </div>
                </template>
                <template v-slot:content>
                  <div class="mt-2">
                    <MultiFieldInput
                      :suggestions="skillSuggestion"
                      :elements="skills"
                      @onChange="searchSkills"
                      @addElement="addSkill"
                      @removeElement="removeSkill"
                    />
                  </div>
                </template>
              </app-accordion>
              <div v-if="!isMobile">
                <div class="pt-0">
                  <p
                    class="mb-3.5 mt-7 whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                  >
                    Languages
                  </p>
                  <div class="">
                    <LanguageWidget />
                  </div>
                </div>
              </div>
              <app-accordion class="mt-8" v-if="isMobile">
                <template v-slot:title>
                  <div class="flex items-center">
                    <p
                      class="whitespace-nowrap pr-2 font-medium text-lg dark:text-white"
                    >
                      Languages
                    </p>
                    <!-- <div class="h-px w-full bg-gray-400"></div> -->
                  </div>
                </template>
                <template v-slot:content>
                  <LanguageWidget />
                </template>
              </app-accordion>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed,ref } from "vue";
import MultiFieldInput from "@/components/inputs/MultiFieldInput";
import LanguageWidget from "@/components/profile/components/languageWidget/LanguageWidget";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";

export default {
  name: "Skills",
  components: {
    MultiFieldInput,
    LanguageWidget,
    AppAccordion,
    ExpandableBlockContent,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
    
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const openMobile = ref(false);
    return {
      openMobile,
      isMobile: computed(() => width.value < 1024),
      // ==========JOB SUGGESTIONS==========
      searchJobs(val) {
        store.dispatch("fetchProfileJobs", val);
      },
      jobSuggestion: computed(() => store.getters.getProfileJobs.slice(0, 5)),
      // ==========SKILL SUGGESTIONS==========
      searchSkills(val) {
        store.dispatch("fetchProfileSkills", val);
      },
      skillSuggestion: computed(() => store.getters.getProfileSkillOptions.slice(0, 5)),
      // ==========SKILLS==========
      skills: computed(() => store.getters.getUserSkills),
      addSkill(title) {
        store.dispatch("addUserSkill", title);
      },
      removeSkill(title) {
        store.dispatch("removeUserSkill", title);
      },
      // ==========SECONDARY JOBS==========
      secondaryJobs: computed(() =>
        store.getters.getUserSecondaryJobs.map((job) => job.title)
      ),
      addSecondaryJob(title) {
        store.dispatch("createSecondaryJob", title);
      },
      deleteSecondaryJob(title) {
        store.dispatch("deleteSecondaryJob", title);
      },
    };
  },
  computed: {
    pageContent: function () {
      return "List all your skills as appropriate and choose which you would like to display on your profile page. Basic and Pro users can also be found based on listed skills, using filtered search.";
    },
  },
};
</script>
