<template>
  <div class="py-4 px-5 bg-white dark:bg-primaryDark rounded-lg">
 
    <MediaImageDisplayImages
      :images="images"
      :editMode="editMode" 
      :link="link"
      :edidLink="edidLink"
      :album="album"
    />
    <Modal :modalActive="addPhotoOn" @close="addPhotoOn = false">
      <MediaImageAddPhoto
        @close="addPhotoOn = false"
        :album="album"
      />
    </Modal>
  </div>
</template>

<script>
import { ref, computed } from "vue";

import { PhotoAlbumNames } from '@/constants'
import MediaImageAddPhoto from "@/components/widgets/mediaImageWidget/MediaImageAddPhoto";
import MediaImageDisplayImages from "@/components/widgets/mediaImageWidget/MediaImageDisplayImages";
import Modal from "@/components/widgets/Modal";

export default {
  name: "MediaImageWidget",
  props: {
    images: {
      type: Array,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    album: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      required: true,
    },
    edidLink: {
      type: Object,
      required: true,
    },
  },
  components: { MediaImageAddPhoto, MediaImageDisplayImages, Modal },
  computed: {
    buttonClasses() {
      return "ml-40 cursor-pointer m-2 px-4 py-2 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark";
    },
  },
  setup(props) {
    const addPhotoOn = ref(false);
    return {
      addPhotoOn,
      allowedToAdd: computed(() => props.editMode &&
          props.album.toLowerCase() !== PhotoAlbumNames.ProfilePhotos.toLowerCase())
    };
  },
};
</script>
