<template>
  <div>
  <teleport to="#root">
    <div class="fixed z-10 inset-0 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-center justify-center m-0 p-4 items-center text-center h-screen">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-30 transition-opacity py-6" aria-hidden="true" @click="closeModal"></div>
        <!-- This element is to trick the browser into centering the modal contents. -->
     
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
        overflow-hidden  shadow-xl transform transition-all  sm:align-middle
       max-w-xl md:max-w-xl lg:max-w-xl  w-full sm:p-6 dark:bg-primaryDark dark:text-white overflow-y-scroll no-scroll-bar h-auto max-h-100 lg:max-h-auto ">
          <div class="flex col-span-4 text-gray-600 dark:text-gray-200 items-center pb-4  ">
      <font-awesome-icon icon="fa-solid fa-masks-theater" size="lg" />
      <p
        v-if="add"
        class="ml-2 text-2xl text-left font-medium col-span-4 dark:text-gray-200"
      >
        Add Production
      </p>
      <p
        v-if="!add"
        class="ml-2 text-2xl text-left font-medium col-span-4 dark:text-gray-200"
      >
        Edit Production
      </p>
    </div>
    <div class="bg-primary p-1 dark:bg-primaryDark rounded-lg grid grid-cols-4 sm:gap-x-5 gap-y-2 ">
    <SmallTextInput
      :focus="widgetOn"
      class="col-span-12 sm:col-span-2 text-left "
      heading="Name"
      :value="form.name"
      @update:value="form.name = $event"
      :errors="v$.name.$errors"
    />
    <SmallTextInput
      class="col-span-12 sm:col-span-2 text-left "
      heading="Role"
      :value="form.role"
      @update:value="form.role = $event"
      :errors="v$.role.$errors"
    />
    <SelectInput
      version="2"
      class="col-span-12 sm:col-span-2 text-left "
      heading="Type"
      :options="eventTypes"
      :value="form.productionType"
      @update:value="form.productionType = $event"
      :errors="v$.productionType.$errors"
    />
    <SmallTextInput
      class="col-span-12 sm:col-span-2 "
      heading="Director"
      :value="form.director"
      @update:value="form.director = $event"
    />
    <SmallTextInput
      class="col-span-12 sm:col-span-2 "
      heading="Company"
      :value="form.company"
      @update:value="form.company = $event"
    />
    <SelectInput
      version="2"
      class="col-span-12 sm:col-span-2 text-left "
      heading="Company
                                    type"
      :options="companyTypes"
      :value="form.companyType"
      @update:value="form.companyType = $event"
    />
    <SmallTextInput
      class="col-span-12 sm:col-span-2 "
      heading="Venue"
      :value="form.venue"
      @update:value="form.venue = $event"
    />
    <SmallTextInput
      class="col-span-12 sm:col-span-2 "
      heading="Space"
      :value="form.space"
      @update:value="form.space = $event"
    />
    <DateInput
      class="col-span-12 sm:col-span-2 "
      heading="Start
                                                date"
      :value="form.startDate"
      @update:value="form.startDate = $event"
    />
    <DateInput
      class="col-span-12 sm:col-span-2 "
      heading="End
                                                    date"
      :value="form.endDate"
      @update:value="form.endDate = $event"
    />
    <div class="flex-wrap flex col-span-4 justify-end ">
      <div>
        <SaveCancelForm
        
          class="col-span-4 mt-2"
          @onCancel="closeModal"
          @onSave="submit()"
          justifyOrientation="end"
          :display="true"
        />
        </div>
      </div>
      <!-- <button @click="submit(false)" :class="buttonClasses">Save and Add New</button> -->
    </div>
  </div>
  </div></div>
  </teleport>
</div>
</template>
<script>
import { useStore } from "vuex";
import { reactive, watch, computed } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DateInput from "@/components/inputs/DateInput";
import SelectInput from "@/components/inputs/SelectInput";
import SaveCancelForm from "@/components/forms/SaveCancelForm";

export default {
  name: "ProductionWidget",
  components: { SmallTextInput, DateInput, SelectInput, SaveCancelForm },
  props: {
    currentProduction: [Object, null],
    widgetOn: Boolean,
    add: Boolean,
    backdrop: {
      required: false,
      default: true,
    },
  },
  computed: {
    buttonClasses() {
      return "cursor-pointer my-2 ml-2 sm:ml-4 px-1 sm:px-4 py-2 text-sm font-medium relative inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark";
    },
  },
  mounted() {
    document.addEventListener("keydown", (e) => {
      if (e.keyCode == 27) {
        this.$emit("onCancel");
      }
    });
  },
  setup(props) {
    const store = useStore();
    const form = reactive({
      id: props.currentProduction ? props.currentProduction.id : null,
      name: props.currentProduction ? props.currentProduction.name : "",
      role: props.currentProduction ? props.currentProduction.role : "",
      productionType: props.currentProduction
        ? props.currentProduction.productionType
        : "",
      director: props.currentProduction ? props.currentProduction.director : "",
      company: props.currentProduction ? props.currentProduction.company : "",
      companyType: props.currentProduction ? props.currentProduction.companyType : "",
      venue: props.currentProduction ? props.currentProduction.venue : "",
      space: props.currentProduction ? props.currentProduction.space : "",
      startDate: props.currentProduction ? props.currentProduction.startDate : "",
      endDate: props.currentProduction ? props.currentProduction.endDate : "",
    });
    const rules = {
      name: { required },
      role: { required },
      productionType: { required },
    };
    const v$ = useVuelidate(rules, form, { $lazy: true });
    function resetForm() {
      form.id = null;
      form.name = "";
      form.role = "";
      form.productionType = "";
      form.director = "";
      form.company = "";
      form.companyType = "";
      form.venue = "";
      form.space = "";
      form.startDate = "";
      form.endDate = "";
    }

    watch(
      () => props.currentProduction,
      (prod) => {
        if (prod !== null) {
          form.id = prod.id;
          form.name = prod.name;
          form.role = prod.role;
          form.productionType = prod.productionType;
          form.director = prod.director;
          form.company = prod.company;
          form.companyType = prod.companyType;
          form.venue = prod.venue;
          form.space = prod.space;
          form.startDate = prod.startDate;
          form.endDate = prod.endDate;
        } else {
          resetForm();
        }
      }
    );

    store.dispatch("fetchWorkTypes");
    return {
      v$,
      store,
      resetForm,
      form,
      eventTypes: computed(() => store.getters.getWorkTypes),
      companyTypes: ["Amateur", "Professional", "Drama school", "School", "College"],
    };
  },
  methods: {
    submit(closeWidget = true) {
      this.v$.$touch();
      if (this.v$.$error) return;
      this.$emit("onSubmit", this.form, closeWidget);
      this.resetForm();
      this.v$.$reset();
    },
    closeModal() {
      if (this.backdrop) this.$emit("onCancel");
    },
  },
};
</script>
