<template>
  <div>
  
    <div v-if="!isMobile">
      <p class="whitespace-nowrap text-lg pr-2 font-semibold" >Accommodation preferences</p>
      <div class="w-full flex items-start">
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
        <div class="w-full grid mt-3 grid-cols-2 xl:grid-cols-2 md:gap-x-10 gap-1.5 mt-3">
    
          <SelectInput heading="Smoking"  :tooltip="true"  textFormate="capitalize"
                    toolText="Smoking" class="col-span-2 md:col-span-1 my-1"
                     :options="['Yes','No']"
                     />
          <SelectInput heading="High/low floor"  :tooltip="true"  textFormate="capitalize"
                    toolText="High/low floor" class="col-span-2 md:col-span-1 my-1"
                    :options="['Full Name','Professional Name']"
                     />
          <SelectInput heading="Elevator proximity"  :tooltip="true"  textFormate="capitalize"
                    toolText="Elevator proximity" class="col-span-2 md:col-span-1 my-1"
                     :options="['Full Name','Professional Name']"
                     />
            <SelectInput heading="Special requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Special requirements" class="col-span-2 md:col-span-1 my-1"
            :options="['Full Name','Professional Name']"
            />
           
        
        </div>
    </div>
  </div>
    <app-accordion class=""  v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center" v-if="!custom">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold"  >Accommodation preferences</p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
        <!-- <UserIcon v-if="!custom" class="w-20 pr-4 pl-10 my-1 text-gray-500 dark:text-white"/> -->
      <div class="w-full grid" >
        <SelectInput heading="Smoking"  :tooltip="true"  textFormate="capitalize"
                    toolText="Smoking" class="col-span-2 md:col-span-1 my-1"
                     :options="['Yes','No']"
                     />
          <SelectInput heading="High/low floor"  :tooltip="true"  textFormate="capitalize"
                    toolText="High/low floor" class="col-span-2 md:col-span-1 my-1"
                    :options="['Full Name','Professional Name']"
                     />
          <SelectInput heading="Elevator proximity"  :tooltip="true"  textFormate="capitalize"
                    toolText="Elevator proximity" class="col-span-2 md:col-span-1 my-1"
                     :options="['Full Name','Professional Name']"
                     />
            <SelectInput heading="Special requirements"  :tooltip="true"  textFormate="capitalize"
          toolText="Special requirements" class="col-span-2 md:col-span-1 my-1"
            :options="['Full Name','Professional Name']"
            />
          <slot></slot>
      </div>
     
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SelectInput from "@/components/inputs/SelectInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'

import { useStore } from "vuex";
import { computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints"
import AppAccordion from "@/components/widgets/accordion/AppAccordion";

// import { UserIcon } from "@heroicons/vue/outline/esm";
export default {
  name: "TravelAccommodationPreferenceForm",
  components: {  AppAccordion, SelectInput  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints()
  
    return {
      store,
      isMobile: computed(() => width.value < 1024),
    
    };
  },
};
</script>
