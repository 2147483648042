<template>
  <div>
    <div v-if="!isMobile">
      <p class="whitespace-nowrap text-lg pr-2 font-semibold">Travel documentations</p>
      <div class="w-full flex items-start mt-5">
        <div
          class="flex md:gap-x-4 flex-wrap md:flex-nowrap items-start my-2 text-gray-500 text-left"
        >
          <SelectInput
            heading="Document type"
            :tooltip="true"
            textFormate="capitalize"
            toolText="Document type"
            class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
            :options="documentType"
          />
          <SelectInput
            heading="Country"
            :tooltip="true"
            textFormate="capitalize"
            toolText="Country"
            class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
            :options="countries"
          />
          <SmallTextInput
            class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
            heading="Document number"
            toolText="Document number"
            :tooltip="true"
          />
          <DateInput
            class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 flex-1"
            heading="Expiration date"
            toolText="Expiration date"
            :tooltip="true"
          />
          <div class="flex flex-col items-center justify-center h-full flex-none">
            <label class="block text-sm font-medium text-gray-700 dark:text-white opacity-0" for="">.</label>
            <div class="w-full sm:w-3/6 md:w-auto place-self-top py-2 flex">

              <PaperClipIcon class="w-6 cursor-pointer place-self-center" />
              <TrashIcon
                @click="$emit('onDelete', 1)"
                class="w-6 cursor-pointer place-self-center"
              />
            </div>
          </div>
        </div>
      </div>
      <button class="w-full text-left mt-2 text-gray-500 mb-5">+ Add new line</button>
    </div>
    <app-accordion class="" v-if="isMobile">
      <template v-slot:title>
        <div class="flex items-center" v-if="!custom">
          <p class="whitespace-nowrap text-lg pr-2 font-semibold">
            Travel documentations
          </p>
          <div class="h-px w-full bg-gray-400"></div>
        </div>
      </template>
      <template v-slot:content>
        <div class="w-full grid" :class="outerClasses">
          <SelectInput
            heading="Document type"
            :tooltip="true"
            textFormate="capitalize"
            toolText="Document type"
            class="col-span-2 md:col-span-1 my-1"
            :options="documentType"
          />
          <SelectInput
            heading="Country"
            :tooltip="true"
            textFormate="capitalize"
            toolText="Country"
            class="col-span-2 md:col-span-1 my-1"
            :options="countries"
          />
          <SmallTextInput
            class="col-span-2 md:col-span-1 my-1"
            heading="Document number"
            toolText="Document number"
            :tooltip="true"
          />
          <DateInput
            class="col-span-2 md:col-span-1 my-1"
            heading="Expiration date"
            toolText="Expiration date"
            :tooltip="true"
          />

          <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0 place-self-top mt-4">
            <PaperClipIcon class="w-6 cursor-pointer place-self-center" />
            <TrashIcon
              @click="$emit('onDelete', 1)"
              class="w-6 cursor-pointer place-self-center"
            />
          </div>
        </div>
        <button class="w-full float-left text-left mt-2 text-gray-500 mb-5">
          + Add new line
        </button>
      </template>
    </app-accordion>
  </div>
</template>

<script>
import SelectInput from "@/components/inputs/SelectInput";
// import { QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'

import { useStore } from "vuex";
import { computed } from "vue";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import AppAccordion from "@/components/widgets/accordion/AppAccordion";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import { TrashIcon, PaperClipIcon } from "@heroicons/vue/outline/esm";
import DateInput from "../inputs/DateInput";

export default {
  name: "TravelDocumentations",
  components: {
    AppAccordion,
    SelectInput,
    SmallTextInput,
    TrashIcon,
    DateInput,
    PaperClipIcon,
  },
  props: {
    custom: {
      required: false,
      default: false,
    },
  },
  computed: {
    // paraClasses(){
    //   if(this.custom) return ""
    //   return "pl-10"
    // },
    iconClasses() {
      if (this.custom) return "w-8 pr-1";
      return "w-20 pl-10 pr-4";
    },
    outerClasses() {
      if (this.custom) return "grid-cols-2 xl:grid-cols-2 gap-x-2";
      return "grid-cols-2 xl:grid-cols-3 md:gap-x-10 gap-1.5";
    },
  },
  setup() {
    const store = useStore();
    const { width } = useBreakpoints();
    const documentType=['Drivers licence','Military ID card','Passport','Passport Card','Refugee travel document','Visa','Emergency passport','Other'];

    return {
      store,
      isMobile: computed(() => width.value < 1024),
      countries: computed(() => store.getters.getCountries),
      documentType
    };
  },
};
</script>
