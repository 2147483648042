<template>
    <div @mouseleave="close()" class="block relative" :class="ContainerClases()">
      <div class="flex flex-row items-center " v-if="showTitle">
        <h3 class="py-1 text-sm text-gray-800 dark:text-white pr-1">{{heading}}</h3>
        <span class="pt-1" title="Feel free to add a new option!"><QuestionMarkCircleIcon class=" dark:text-white h-5  w-5 block text-gray-700 cursor-pointer" /></span>
      </div>
        <div class="flex shadow  bg-white border-2 border-gray-300 rounded-md">
            <input
                @click.stop="showOptions=true"
                @focus="showOptions=true"
                v-model="currentValue"
                @keyup.enter="close()"
                :class="inputClass()"
                class="cursor-pointer rounded-sm h-9  w-full h-12 p-2 bg-gray-100 text-gray-900 
                dark:bg-primaryDark dark:text-white" />
            <ChevronDownIcon @click.stop="showOptions=!showOptions" class="w-9 pr-2 text-gray-400 bg-gray-100
                dark:bg-primaryDark dark:text-white" />
        </div>
        <div v-show="showOptions" @click.stop
            class="absolute block flex flex-col items-center 
            bg-white overflow-auto min-w-46 border-2 border-gray-500 w-full max-h-56 z-10
            dark:bg-primaryDark dark:text-white">
            <div v-for="option, idx in options" :key="idx"
                :ref="option"
                @click.stop="$emit('update:value', option); close()"
                class="block w-46 text-sm p-2 w-full flex 
                items-center hover:bg-gray-200 "
                :class="[ option === value ? 'bg-indigo-100' : '' , isLeft ? 'text-left' : '']">
                {{ option }}
            </div>
        </div>
        <div v-if="value.length > 0" class="w-full shadow rounded-md " :class="dropdownStyle">
            <div v-for="val, idx in value" :key="idx"
                @mouseenter="addDeleteButton(idx)"
                @mouseleave="removeDeleteButton(idx)"
                class="text-gray-600 flex justify-between  items-center text-sm pl-2 dark:bg-primaryDark dark:text-white"
                :class="[idx % 2 == 0 ? 'bg-gray-100 border-2 border-gray-200 dark:border-gray-700' : '' , isLeft ? 'text-left' : '']">
                <p class="p-2">{{val}}</p>
                <button :ref="`button-${idx}`" @click="$emit('onDelete', idx)"
                    class="transition-all ease-in-out delay-50 duration-300 bg-gray-700 shadow p-1 mr-2 rounded-md opacity-1">
                <XIcon class="w-5 text-white" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { ChevronDownIcon, XIcon, QuestionMarkCircleIcon } from '@heroicons/vue/solid/esm'
export default {
    name: "MultiWithCreate",
    components: { ChevronDownIcon, XIcon, QuestionMarkCircleIcon },
    props: {
        options: Array,
        value: {
            required: false,
        },
        heading: String,
        showTitle: {
            required: false,
            default: true,
            type:Boolean
        },
        isLeft: {
            required: false,
            default: false
        },
        dropdownStyle: {
            required: false,
            default: ''
        },
        version: {
            required: false,
            default: "1"
        }
    },
    setup(){
        const showOptions = ref(false)
        const currentValue = ref('')
        return {
            showOptions,
            currentValue,
        }
    },
    methods: {
        close(){
            this.showOptions = false
            if (this.currentValue !== ''){
                this.$emit("update:value", this.currentValue)
                this.currentValue = ''
            } 
        },
        addDeleteButton(idx){
            this.$refs[`button-${idx}`].classList.remove('opacity-0')
        },
        removeDeleteButton(idx){
            this.$refs[`button-${idx}`].classList.add('opacity-0')
        },
        inputClass(){
            if( this.version == "2" ) return "h-12"
            return ""
        },
        ContainerClases(){
            if( this.version == "2" ) return "w-48"
            return ""
        }
    },
    mounted() {
        document.addEventListener('click', this.close)
    },
    beforeUnmount(){
        document.removeEventListener('click', this.close)
    }

}
</script>
<style scoped>
    .slide-fade-enter-active {
      transition: all .3s ease-out;
    }

    .slide-fade-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateX(20px);
      opacity: 0;
    }
</style>>
