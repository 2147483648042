<template>
  <modal
    :modalActive="editActive"
    :duration="400"
    @close="$emit('onClose', false)"
    version="3"
  >
    <div class="p-4 rounded-lg bg-primary dark:bg-primaryDark dark:text-white">
      <p class="text-2xl text-left ml-2 font-medium mb-4">Edit Bio</p>
      <div class="w-full grid grid-cols-2 gap-5">
        <LargeTextInput
          :counting="true"
          :value="updatedBio"
          @update:value="updatedBio = $event"
          rows="10"
        />

        <SaveCancelForm
          class="col-span-2"
          @onSave="$emit('onSubmit', updatedBio)"
          @onCancel="$emit('onClose', false)"
          justifyOrientation="end"
          :display="true"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/widgets/Modal";
import LargeTextInput from "@/components/inputs/LargeTextInput";
import SaveCancelForm from "@/components/forms/SaveCancelForm";

export default {
  name: "OverviewBioEdit",
  components: {
    Modal,
    LargeTextInput,
    SaveCancelForm,
  },
  data() {
    return {
      updatedBio: this.bio,
      editActive: true,
    };
  },
  props: {
    bio: {
      type: String,
      required: true,
    }
  },
};
</script>
