<template>
  <div class="mb-8">
    <main>
      <div v-if="isMobile"
        class="w-full grid col-span-10 bg-white pt-8 pb-8 lg:pb-0 dark:bg-primaryDark justify-items-center">
        <ProfileHeader  class="mb-8" />
        <EditProfileHeaderButton  />
         </div>
         
      <div
        class="col-start-0 col-span-10 md:col-start-2 mt-3 p-4 md:p-0 mx-2 lg:mx-0 md:mt-5 md:col-span-8 lg:col-start-3 lg:col-span-7"
      >
        <div class="grid grid-cols-12 gap-3 mt-3 lg:px-0 lg:mx-10">
          <div class="xl:col-span-2 sm:col-span-3 col-span-4 hidden lg:block">
            <ProfileSidebar
              class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6 dark:bg-primaryDark"
              :subNav="true"
            />
          </div>
          <div
            class="col-span-12 md:m-0 md:mx-2 lg:mx-0 sm:col-span-12 md:col-span-12 lg:col-span-8"
          >
            <div
              class="bg-white rounded-md dark:bg-primaryDark dark:text-white px-5 sm:px-12 py-9"
            >
              <div class="flex items-center gap-2">
                <font-awesome-icon
                  :icon="['fas', 'person']"
                  class="text-grey-500 text-md dark:text-white dark-hover:text-white font-normal text-black flex items-center py-1 px-0 h-5 md:h-6  overflow-hidden text-ellipsis whitespace-nowrap rounded transition duration-300 ease-in-out']"
                  aria-hidden="true"
                />
                <h1 class="text-lg md:text-3xl font-semibold icon-color dark:text-white">
                  Edit physical information
                </h1>
              </div>
              <div class="flex flex-row my-2.5 items-start dark:text-white md:my-6">
                <!-- <ExclamationCircleIcon class="w-4 "/> -->
                <ExpandableBlockContent :content="pageContent"></ExpandableBlockContent>
              </div>

              <div
                id="inputList"
                class="grid grid-cols-2 md:grid-cols-4 gap-x-8 gap-1.5 pt-4 md:pt-8"
              >
                <div class="col-span-2 flex items-end">
                  <SmallTextInput
                    :value="form.height.value"
                    @update:value="form.height.value = $event"
                    :errors="v$.height.$errors"
                    heading="Height (cm)"
                    class="w-full"
                  />
                </div>

                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.eyeColour.value"
                    @update:value="form.eyeColour.value = $event"
                    :options="eyeColourOptions"
                    heading="Eye colour"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <SmallTextInput
                    :value="form.weight.value"
                    @update:value="form.weight.value = $event"
                    :errors="v$.weight.$errors"
                    heading="Weight (kg)"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.bodyType.value"
                    @update:value="form.bodyType.value = $event"
                    :options="bodyTypeOptions"
                    heading="Body type"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <SmallTextInput
                    :value="form.shoeSize.value"
                    @update:value="form.shoeSize.value = $event"
                    :errors="v$.shoeSize.$errors"
                    heading="Shoe size (Euro)"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.voiceType.value"
                    @update:value="form.voiceType.value = $event"
                    :options="voiceTypeOptions"
                    heading="Voice type"
                    class="w-full"
                  />
                </div>
                <div class="sm:col-span-1 col-span-2 flex items-end">
                  <SmallTextInput
                    :value="form.vocalRangeLowest.value"
                    @update:value="form.vocalRangeLowest.value = $event"
                    heading="Vocal range - lowest"
                    class="w-full font-normal"
                  />
                </div>
                <div class="sm:col-span-1 col-span-2 flex items-end">
                  <SmallTextInput
                    :value="form.vocalRangeHighest.value"
                    @update:value="form.vocalRangeHighest.value = $event"
                    heading="Vocal range - highest"
                    class="w-full font-normal"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.hairType.value"
                    @update:value="form.hairType.value = $event"
                    :options="hairTypeOptions"
                    heading="Hair type"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.hairColour.value"
                    @update:value="form.hairColour.value = $event"
                    :options="hairColourOptions"
                    heading="Hair colour"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.hairLength.value"
                    @update:value="form.hairLength.value = $event"
                    :options="hairLengthOptions"
                    heading="Hair length"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.beardType.value"
                    @update:value="form.beardType.value = $event"
                    :options="beardTypeOptions"
                    heading="Beard type"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.beardColour.value"
                    @update:value="form.beardColour.value = $event"
                    :options="beardColourOptions"
                    heading="Beard color"
                    class="w-full"
                  />
                </div>
                <div class="col-span-2 flex items-end">
                  <DropdownWithCreate
                    :value="form.beardLength.value"
                    @update:value="form.beardLength.value = $event"
                    :options="beardLengthOptions"
                    heading="Beard length"
                    class="w-full"
                  />
                </div>
                <Multiselect
                  :options="appearanceOptions"
                  v-model="form.appearance.value"
                  :create-active="true"
                  class="col-span-2 mt-1"
                  heading="Appearance"
                />
                <Multiselect
                  :options="piercingOptions"
                  v-model="form.piercings.value"
                  :create-active="true"
                  class="col-span-2 mt-1"
                  heading="Piercings"
                />
                <Multiselect
                  :options="tattooOptions"
                  v-model="form.tattoos.value"
                  :create-active="true"
                  class="col-span-2 mt-1"
                  heading="Tattoos"
                />
              </div>
              <div class="w-full flex justify-end mt-6">
                <transition name="fade" mode="out-in">
                  <SaveCancelForm
                    version="2"
                    justifyOrientation="end"
                    :display="true"
                    @onCancel="form.resetForm"
                    @onSave="onSubmit"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed,ref,onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import { decimal } from "@vuelidate/validators";
import SmallTextInput from "@/components/inputs/SmallTextInput";
import DropdownWithCreate from "@/components/inputs/DropdownWithCreate";
import SaveCancelForm from "@/components/forms/SaveCancelForm";
import Multiselect from "@/components/inputs/Multiselect";
import useVuelidate from "@vuelidate/core";
import FormBuilder from "@/utils/FormBuilder";
import ExpandableBlockContent from "@/components/widgets/ExpandableBlockContent/ExpandableBlockContent";
import { createToaster } from "@meforma/vue-toaster";
import ProfileSidebar from "@/components/widgets/profileNavbar/ProfileSidebar";
import ProfileHeader from "@/components/profile/components/profileHeader/ProfileHeader";
import useBreakpoints from "@/utils/reactive/useBreakpoints";
import EditProfileHeaderButton from "@/components/widgets/profileNavbar/EditProfileHeaderButton";

export default {
  name: "Physical",
  components: {
    SaveCancelForm,
    SmallTextInput,
    Multiselect,
    DropdownWithCreate,
    ExpandableBlockContent,
    ProfileSidebar,
    ProfileHeader,
    EditProfileHeaderButton,
  },
  async created() {
    await this.$store.dispatch("fetchPhysicalInfo");
  },
  setup() {
    const openMobile = ref(false);
    const store = useStore();
    const route = useRoute();

    store.dispatch("fetchProfileFilters");
    store.dispatch("fetchPhysicalInfo");
    const { width } = useBreakpoints();

    onBeforeMount(async () => {
      await store.dispatch("fetchProfile", route.params.id);
      await this.$store.dispatch("fetchPhysicalInfo");
    });

    const form = FormBuilder({
      height: store.getters.getUserHeight,
      eyeColour: store.getters.getUserEyeColour,
      weight: store.getters.getUserWeight,
      piercings: store.getters.getUserPiercings,
      bodyType: store.getters.getUserBodyType,
      tattoos: store.getters.getUserTattoos,
      shoeSize: store.getters.getUserShoeSize,
      voiceType: store.getters.getUserVoiceType,
      hairColour: store.getters.getUserHairColour,
      vocalRangeLowest: store.getters.getUserVocalRangeLowest,
      hairLength: store.getters.getUserHairLength,
      vocalRangeHighest: store.getters.getUserVocalRangeHighest,
      hairType: store.getters.getUserHairType,
      appearance: store.getters.getUserAppearance,
      beardLength: store.getters.getUserBeardLength,
      beardType: store.getters.getUserBeardType,
      beardColour: store.getters.getUserBeardColour,
    });

    const rules = {
      height: { value: { decimal } },
      weight: { value: { decimal } },
      shoeSize: { value: { decimal } },
    };
    const v$ = useVuelidate(rules, form, { $lazy: true });

    return {
      openMobile,
      form,
      store,
      v$,
      isMobile: computed(() => width.value < 1024),
      eyeColourOptions: computed(() => store.getters.getFilterOptions("eyeColour")),
      bodyTypeOptions: computed(() => store.getters.getFilterOptions("bodyType")),
      voiceTypeOptions: computed(() => store.getters.getFilterOptions("voiceType")),
      hairColourOptions: computed(() => store.getters.getFilterOptions("hairColour")),
      hairLengthOptions: computed(() => store.getters.getFilterOptions("hairLength")),
      hairTypeOptions: computed(() => store.getters.getFilterOptions("hairType")),
      appearanceOptions: computed(() => store.getters.getFilterOptions("appearance")),
      tattooOptions: computed(() => store.getters.getFilterOptions("tattoos")),
      piercingOptions: computed(() => store.getters.getFilterOptions("piercings")),
      beardTypeOptions: computed(() => store.getters.getFilterOptions("beardType")),
      beardLengthOptions: computed(() => store.getters.getFilterOptions("beardLength")),
      beardColourOptions: computed(() => store.getters.getFilterOptions("beardColour")),
    };
  },
  methods: {
    async onSubmit() {
      const toast = createToaster({ position: "top-right" });
      this.v$.$touch();
      if (this.v$.$error) return;

      const form = this.form.getForm(); // Check if form is unchanged
      if (Object.keys(form).length === 0) {
        return;
      }
      await this.$store.dispatch("editPhysicalInfo", form);

       this.$store.dispatch("fetchPhysicalInfo");
      toast.success(`Physical info has been updated successfully`);
    },
  },
  computed: {
    pageContent: function () {
      return "List all your physical properties as you feel is appropriate and choose which you would like to display on your profile page. Basic and Pro users can also be found based on listed physical properties, using filtered search.";
    },
  },
};
</script>
