<template>
    <div class="flex flex-row justify-between">
        <p v-if="heading"> {{heading}} </p>
        <div @click="$emit('update:modelValue', !modelValue)"
            class="cursor-pointer w-10 h-6 rounded-full flex items-center"
            :class="[modelValue ? 'justify-end bg-gray-600' : 'bg-gray-200']">
            <div
                class="w-6 h-6 bg-gray-100 rounded-full border-2"
                :class="[modelValue ? 'border-gray-100' : 'border-gray-300']"
                ></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ToggleSwitch",
    props: {
        modelValue: Boolean,
        heading: String,
    },
    setup() {

    }
}
</script>

