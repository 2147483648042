<template>
    <div>
    
        <MediaImageWidget 
            :images="curAlbum"  
            :album="curAlbumTitle"
            :editMode="userIsProfileOwner"
            :link="onTrashedLink" 
            :edidLink="onEditPhotoLink"
        />

        <Modal v-if="editPhotoOn" :modalActive="editPhotoOn" @close="editPhotoOn=false">
            <PhotoModal 
                 v-if="editPhotoOn"
                :contact="editHeadshots"
                @onSave="savePhoto"
                @onCancel="cancelEditPhoto"
                @update:title="editHeadshots.title = $event"
                @update:caption="editHeadshots.caption = $event"
                @update:photographer="editHeadshots.photographer = $event"
                @update:date="editHeadshots.date = $event"
                @update:location="editHeadshots.location = $event"
            
            />
        </Modal> 
    </div>
</template>

<script>
import { ref, computed,reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from "vue-router";
import MediaImageWidget from '@/components/widgets/mediaImageWidget/MediaImageWidget'
import { PhotoAlbumNames } from '@/constants.js'
import { AlbumTitlesDisplayFormatter, AlbumTitleSaveFormatter } from '@/utils/AlbumTitlesFormatter'
import { createToaster } from "@meforma/vue-toaster";

import Modal from '@/components/widgets/Modal'
import PhotoModal from '@/components/profile/edit/pages/photos/PhotoModal'


export default {
    name: "HeadShots",
    components: { MediaImageWidget,Modal,PhotoModal},
    setup() {
        const store = useStore()
        const route = useRoute()
        const curAlbumTitle = ref(PhotoAlbumNames.Headshots)
        const userIsProfileOwner = computed(() => route.params.id === store.getters.getId)
        let curAlbum = []
        let editHeadshots = reactive({});
        const editPhotoOn = ref(false);
        async function onTrashed(idx,album) {
            const toast = createToaster({position:"top-right"});
            const result = confirm("Are you sure you want to delete?")
            if (result) {
                try {
                    await store.dispatch('deleteMediaImage', {uuid:idx, albumTitle:album});
                    toast.success(`Headshots has been deleted successfully`);
                    store.dispatch("fetchUserAlbumTitles")
                    store.dispatch('fetchUserAlbum', curAlbumTitle.value); 
                } catch (e) {
                console.error(e)
                }
            }else{
                return false;
            }
        }
        async function onEditPhoto(photos) {
            if (photos === null) {
                    Object.assign(editHeadshots, {
                    image: "",
                    title: "",
                    caption: "",
                    date: "", 
                    photographer: "",
                    location:"",
                    
                  });
                } else {
                    Object.assign(editHeadshots, {
                        image:photos.image,
                        title: photos.title,
                        caption:photos.caption,
                        date:photos.date,
                        photographer:photos.photographer,
                        location:photos.location,
                        uuid:photos.uuid
                    });
                }
                editPhotoOn.value = true;
        }
        async function cancelEditPhoto() {
                delete editHeadshots.title,
                delete editHeadshots.caption,
                delete editHeadshots.photographer,
                delete editHeadshots.date,
                delete editHeadshots.location,
                delete editHeadshots.uuid;
                editPhotoOn.value = false;
                     store.dispatch("fetchUserAlbumTitles")
                    store.dispatch('fetchUserAlbum', curAlbumTitle.value); 
            }

            if (userIsProfileOwner.value) {
                store.dispatch("fetchUserAlbumTitles")
                store.dispatch('fetchUserAlbum', curAlbumTitle.value);
            } else {
                store.dispatch('fetchProfileAlbumTitles', route.params.id)
                store.dispatch("fetchProfileAlbum", {
                            albumTitle: curAlbumTitle.value,
                            profileId: route.params.id
                })
            }

        const albumTitles = computed(() => { // Take user album titles if users profile is being viewed
            const titles = userIsProfileOwner.value 
                    ? store.getters.getUserAlbumTitles 
                    : store.getters.getProfileAlbumTitles

            return AlbumTitlesDisplayFormatter(Object.values(PhotoAlbumNames),
                    titles,
                    [PhotoAlbumNames.Photos, PhotoAlbumNames.ProfilePhotos])})

        function refreshAlbum() {
            curAlbum = computed(() =>
                userIsProfileOwner.value 
                ? store.getters.getUserAlbum(curAlbumTitle.value)
                : store.getters.getProfileAlbum(curAlbumTitle.value)
            )
        }
        refreshAlbum()

        async function updateAlbum() {
            refreshAlbum()
            if (curAlbum.value.length === 0 ) {
                if (userIsProfileOwner.value) {
                    await store.dispatch("fetchUserAlbum", curAlbumTitle.value)
                } else {
                    await store.dispatch("fetchProfileAlbum", {
                        albumTitle: curAlbumTitle.value,
                        profileId: route.params.id
                    })
                }
                refreshAlbum()
            }
        }
        return {
            albumTitles,
            curAlbum,
            curAlbumTitle,
            userIsProfileOwner,
            editHeadshots,
            editPhotoOn,
            cancelEditPhoto,
            async onChangeAlbum(idx) {
                curAlbumTitle.value = AlbumTitleSaveFormatter(albumTitles.value[idx])
                updateAlbum()
            },
            onTrashedLink: computed(() => {
                return { func: onTrashed, fieldName: "Name" }
             }),
             onEditPhotoLink: computed(() => {
                return { func: onEditPhoto, fieldName: "Name" }
             }),
             async savePhoto() {
                const toast = createToaster({ position: "top-right" });
                // If editing pre-existing medical contact
                if (Object.prototype.hasOwnProperty.call(editHeadshots, "uuid")) {
                 await store.dispatch("editUserMediaImage", {
                        uuid:editHeadshots.uuid,
                        profileId:route.params.id,
                        title:editHeadshots.title,
                        caption:editHeadshots.caption,
                        photographer:editHeadshots.photographer,
                        date:editHeadshots.date,
                        location:editHeadshots.location,
                        delete:false,
                        logo:false
                });
                toast.success(`Photo has been updated successfully`);
                } 
                cancelEditPhoto();
            },
           
        }
    }
}
</script>
