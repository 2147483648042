<template>
    <div class="bg-white rounded-lg dark:bg-primaryDark  flex flex-col gap-4">
        <!-- <ProfileNavbar
            class="col-start-1 col-span-10 md:col-start-2 md:col-span-8 lg:col-start-3 lg:col-span-6"
            @change="changeComponent"
            :navigation="navigation"
            :subNav="true"
        /> -->
        <div class="col-start-1 col-span-9 md:col-start-3 md:col-span-7 px-12">
            <transition name="fade">
                <component :is="currentComponent" />
            </transition>
        </div>
    </div>
</template>

<script>
import ProfileNavbar from "@/components/widgets/profileNavbar/ProfileNavbar";
import ProgrammeBio from "@/components/profile/edit/pages/ProgrammeBio";
import ProgrammeHeadshots from "@/components/profile/edit/pages/ProgrammeHeadshots";
import { ref } from "vue";
import { useStore } from 'vuex'

const navigation = ref([
    {
        name: "Bio",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "ProgrammeBio",
        current: true,
    },
    {
        name: "Headshots",
        onClick: function() {
            onNavClick(this, navigation.value);
        },
        component: "ProgrammeHeadshots",
        current: false,
    },
]);
function onNavClick(activeNode, navigation) {
    for (let i = 0; i < navigation.length; i++) {
        navigation[i].current = false;
    }
    activeNode.current = true;
}
export default {
    name: "Programme",
    components: { ProfileNavbar, ProgrammeBio, ProgrammeHeadshots},
    setup() {
        let currentComponent = ref("ProgrammeBio");
        const store = useStore()
        store.dispatch("fetchUserProgrammeBiog")

        return {
            navigation,
            currentComponent,
            changeComponent(component) {
                currentComponent.value = component;
            },
        }
    },
    beforeUnmount() {
        // Reset navbar indicator
        for (let i = 0; i < this.navigation.length; i++) {
            this.navigation[i].current = false;
        }
        this.navigation[0].current = true;
    },

}
</script>
