<template>
    <div>
        <YearInput v-if="yearOnly" :disabled="isCurrent" :heading="heading" :value="value" @update:value="onDateChange($event)" />
        <DateInput v-else :disabled="isCurrent" :heading="heading" :value="value" @update:value="onDateChange($event)" />
        <div>
            <input type="checkbox" id="current-checkbox" @input="onCurrentChange($event.target.checked)" :checked="isCurrent" class="mr-2 rounded border-gray-300">
            <label for="current-checkbox">Ongoing</label>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmit, ref, computed } from "vue"
import ToHtmlDate from "../../utils/ToHtmlDate"
import DateInput from "./DateInput.vue"
import YearInput from "./YearInput.vue"

const props = defineProps({heading: String, value: String, yearOnly: {required: false, default: false}})
const emit = defineEmit(["update:value"])

// Set the last date to the props when this component is created unless it is current
const lastDate = ref(props.value && props.value.toLocaleLowerCase() !== "current" ? props.value : "")
const isCurrent = computed(() => props.value && props.value.toLowerCase() === "current")
const currentValue = computed(() => props.value && isCurrent.value ? lastDate.value : props.value)

const onDateChange = value => {
    lastDate.value = value
    emit("update:value", value)
}

const onCurrentChange = value => {
    if (value) emit("update:value", "current")
    else {
        const nowDate = ToHtmlDate(new Date())
        emit("update:value", lastDate.value ? lastDate.value : nowDate)
    }
}

// TODO: Remove these when ESLint is updated to support script setup syntax properly
isCurrent;currentValue;onDateChange;onCurrentChange;DateInput;YearInput
</script>
