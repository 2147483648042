<template>
    <div>
       <div class="block sm:flex md:gap-x-4 my-2 text-gray-500 text-left " v-for="education, idx in educations" :key="education.id">
            <SmallTextInput  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="education.institution" 
                @update:value="education.institution=$event" 
                :heading="(idx < 1 || educations.length <= 1)? 'Institute' : ''" />
            <DateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="education.yearStarted" 
                @update:value="education.yearStarted=$event" 
                :heading="(idx < 1 || educations.length <= 1) ? 'Year Started' : ''" />
            <CurrentDateInput  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="education.yearFinished" 
                    @update:value="education.yearFinished=$event" 
                    :heading="(idx < 1 || educations.length <= 1) ? 'Year finished' : ''" />
            <SmallTextInput  class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="education.degree"  
                @update:value="education.degree=$event" 
                :heading="(idx < 1 || educations.length <= 1) ? 'Degree' : ''" />
                <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="(idx < 1 || educations.length <= 1)?'place-self-center':'place-self-top mt-2'">
                    <TrashIcon  @click="$emit('onDelete', education.id)" class="w-6 cursor-pointer items-center" />
                </div>
          
       </div>
       <div class="flex md:gap-x-4 flex-wrap md:flex-nowrap items-start my-2 text-left" v-for="education, idx in newEducations" :key="idx">
            <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="education.institution"  
                @update:value="education.institution=$event" :heading="(idx < 1 && educations.length <= 1) ? 'Institute' : ''"   />
            <DateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="idx"  :value="education.yearStarted"  
                @update:value="education.yearStarted=$event" :heading="(idx < 1 && educations.length <= 1) ? 'Year Started' : ''" />
            <CurrentDateInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0"  :value="education.yearFinished" 
                    @update:value="education.yearFinished=$event" 
                    :heading="(idx < 1 && educations.length <= 1) ? 'Year finished' : ''" />

            <SmallTextInput class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :value="education.degree"
                @update:value="education.degree=$event" :heading="(idx < 1 && educations.length <= 1) ? 'Degree' : ''" />
                <div class="w-full sm:w-3/6 md:w-auto p-1 md:p-0" :class="(idx < 1 && educations.length <= 1)?'place-self-center':'place-self-top mt-2'">
                    <TrashIcon @click="removeNewEducation(idx)" class="w-6 mb-2 cursor-pointer"/>
                </div>
            
        </div>
        <button @click="addNewEducation" class="float-left mt-2 text-gray-500">+ Add education</button>
    </div>
</template>

<script>
import { watch, ref } from 'vue'
import SmallTextInput from '@/components/inputs/SmallTextInput'
import DateInput from '../inputs/DateInput.vue'
import CurrentDateInput from '../inputs/CurrentDateInput'
import { TrashIcon } from '@heroicons/vue/outline/esm' 
export default {
    name: "EducationForm",
    components: { SmallTextInput, 
    CurrentDateInput, DateInput, 
    TrashIcon },
    props: {
        educations: Array,
        submit: Boolean
    },
    setup(props, { emit }) {
        const newEducations = ref([]) 

        watch(() => props.submit, (submit) => {
                if(submit){
                    emit("onSubmit", newEducations.value)
                    newEducations.value = []
                } 
            })

        return {
            newEducations,
            addNewEducation(){
                newEducations.value.push({
                    institution: "",
                    yearStarted: "",
                    yearFinished: "current",
                    degree: ""
                 })
            },
            removeNewEducation(idx){
              const result = confirm("Are you sure you want to delete?")
              if (result) {
                newEducations.value.splice(idx, 1)
            }}
        }
    }
}
</script>

